import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { ReactSVG } from "react-svg";
import ReactApexChart from "react-apexcharts";
import { addCommasToNumber } from "../../../utils/helper";
import classes from "./style.module.scss";

// SVG imports
import PlaylistCountIcon from "../../../assets/svg/playlistCountIcon.svg";
import HeadphonesIcon from "../../../assets/svg/headphonesIcon.svg";
import TrackReleaseIcon from "../../../assets/svg/TrackReleaseIcon.svg";
import FanConventionIcon from "../../../assets/svg/FanConventionIcon.svg";
import PopularityIcon from "../../../assets/svg/PopularityIcon.svg";
import GrowthIcon from "../../../assets/svg/GrowthIcon.svg";
import { useSelector } from "react-redux";

const CareerGraph = () => {
  const [playlistCount, setPlaylistCount] = useState(0);
  const [playlistReach, setPlaylistReach] = useState(0);
  const [trackReleaseRate, setTrackReleaseRate] = useState(0);
  const [fanConversionRate, setFanConversionRate] = useState(0);
  const [popularity, setPopularity] = useState(0);
  const [followerRatio, setFollowerRatio] = useState(0);
  const artist = useSelector((state) => state.artist.artist);

  useEffect(() => {
    if (artist) {
      setPlaylistReach(
        artist.chartmetric.cm_statistics.num_sp_playlists +
          artist.chartmetric.cm_statistics.sp_editorial_playlist_total_reach
      );
      setPlaylistCount(
        artist.chartmetric.cm_statistics.num_sp_playlists +
          artist.chartmetric.cm_statistics.num_am_playlists +
          artist.chartmetric.cm_statistics.num_de_playlists +
          artist.chartmetric.cm_statistics.num_yt_playlists +
          artist.chartmetric.cm_statistics.num_az_playlists
      );
      setTrackReleaseRate(0);

      let total_platform_streams =
        artist?.chartmetric?.cm_statistics.sp_monthly_listeners +
        artist?.chartmetric?.cm_statistics?.youtube_monthly_video_views;

      let total_platform_follower =
        artist.chartmetric.cm_statistics.facebook_followers +
        artist.chartmetric.cm_statistics.ins_followers +
        artist.chartmetric.cm_statistics.deezer_fans +
        artist.chartmetric.cm_statistics.tiktok_followers;

      let total_reached =
        artist.chartmetric.cm_statistics.num_sp_playlists +
        artist.chartmetric.cm_statistics.sp_editorial_playlist_total_reach;

      setFanConversionRate(total_platform_streams / total_platform_follower);
      setPopularity(artist.chartmetric.cm_statistics.sp_popularity);
      setFollowerRatio(total_reached / total_platform_follower);
    }
  }, [artist]);

  const statsData = [
    {
      icon: PlaylistCountIcon,
      streams: new Intl.NumberFormat().format(playlistCount),
      subStreams: "Playlist Count",
    },
    {
      icon: HeadphonesIcon,
      streams: new Intl.NumberFormat().format(playlistReach),
      subStreams: "Playlist Reach",
    },
    // {
    //   icon: TrackReleaseIcon,
    //   streams: `${trackReleaseRate}/Year`,
    //   subStreams: "Track Release Rate",
    // },
    {
      icon: FanConventionIcon,
      streams: fanConversionRate.toFixed(1),
      subStreams: "Fan Conversion Rate",
    },
    {
      icon: PopularityIcon,
      streams: `${popularity}/100`,
      subStreams: "Popularity",
    },
    {
      icon: GrowthIcon,
      streams: `${followerRatio.toFixed(1)}X`,
      subStreams: "Reach / Followers Ratio",
    },
  ];

  const chartOptions = {
    series: [{ data: [8.304, 7.146, 8.19, 5.705] }],
    chart: { type: "radar", toolbar: { show: false } },
    title: { text: "", align: "center" },
    stroke: { show: false, width: 2, colors: [], dashArray: 0 },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "diagonal2",
        shadeIntensity: 1,
        opacityFrom: 0.9,
        opacityTo: 1,

        colorStops: [
          {
            offset: 0,
            color: "#88FF00",
            opacity: 1,
          },
          {
            offset: 20,
            color: "#88FF00",
            opacity: 0.9,
          },
          {
            offset: 50,
            color: "#88FF00",
            opacity: 0.9,
          },
          {
            offset: 80,
            color: "#00C2FF",
            opacity: 0.9,
          },
          {
            offset: 100,
            color: "#00C2FF",
            opacity: 0.9,
          },
        ],
      },
    },

    xaxis: {
      categories: statsData.map((item) =>
        item.subStreams > 0 ? Math.log10(item.subStreams) : 0
      ),
      labels: { show: true },
    },
    yaxis: { show: false },
    dataLabels: { enabled: false },
    plotOptions: {
      radar: {
        // size: 60,
        // offsetX: 0,
        // offsetY: 0,
        polygons: {
          strokeColor: "#999999",
          strokeWidth: 1,
          connectorColors: ["#999999", "#999999"],
          fill: { colors: ["#5A7380", "#5A7380"] },
        },
      },
    },
  };

  return (
    <Box component="div" variant="div" className={classes.page}>
      <Typography className={classes.page__topHeading}>Career Stats</Typography>
      <Box
        component="div"
        variant="div"
        className={classes.page__innerContainer}
      >
        <Box
          component="div"
          variant="div"
          className={classes.page__innerContainer__rightContainer}
        >
          {statsData.map((item, index) => (
            <Box
              key={index}
              component="div"
              className={
                classes.page__innerContainer__rightContainer__infoContainer
              }
            >
              <ReactSVG
                src={item.icon}
                className={
                  classes.page__innerContainer__rightContainer__infoContainer__icon
                }
              />
              <Box>
                <Typography
                  className={
                    classes.page__innerContainer__rightContainer__infoContainer__streams
                  }
                >
                  {item.streams}
                </Typography>
                <Typography
                  className={
                    classes.page__innerContainer__rightContainer__infoContainer__streamsSecondary
                  }
                >
                  {item.subStreams}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
        {/* <Box
          component="div"
          variant="div"
          className={classes.page__innerContainer__leftContainer}
        >
          <ReactApexChart
            options={chartOptions}
            series={chartOptions.series}
            type="radar"
            height={250}
          />
        </Box> */}
      </Box>
    </Box>
  );
};

export default CareerGraph;
