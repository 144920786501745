import React from "react";
import classess from "./style.module.scss";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ArtistContractList from "../../components/ArtistContractList/ArtistContractList";
import SearchIcon from "@mui/icons-material/Search";

// Custom MUI Styles
import { viewArtistUseStyles } from "../../custom-mui-style/custom-mui-styles";

const MyContracts = () => {
  const styles = viewArtistUseStyles();
  return (
    <Container maxWidth="xxl" className={styles.root}>
      <Grid
        container
        sm={12}
        md={12}
        lg={12}
        xl={12}
        rowSpacing={2}
        columnSpacing={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}
      >
        <Grid item sm={12} md={12} lg={12} xl={9}>
          <ArtistContractList />
        </Grid>
      </Grid>
    </Container>
  );
};

export default MyContracts;
