import React, { useEffect, useState } from "react";
import { Box, Typography, Avatar } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import classes from "./style.module.scss";
import { addCommasToNumber } from "../../../utils/helper";

import FacebookIcon from "../../../assets/social/FacebookIconNew.png";
import InstaIcon from "../../../assets/social/social-icon9.png";
import SpotifyIcon from "../../../assets/social/social-icon3.png";
import YoutubeIcon from "../../../assets/social/social-icon2.png";
import DeezerIcon from "../../../assets/social/social-icon5.png";
import TiktokIcon from "../../../assets/social/social-icon8.png";

import { useSelector } from "react-redux";

import axios from "axios";
import { config as URLconfig } from "../../../enviorment/enviorment";

const SocialMediaGraph = () => {
  const [facebookData, setFacebookData] = useState(0);
  const [deezerData, setDeezerData] = useState(0);
  const [youtubeData, setYoutubeData] = useState(0);
  const [soundcloudData, setSoundcloudData] = useState(0);
  const [spotifyData, setSpotifyData] = useState(0);
  const [tiktokData, setTiktokData] = useState(0);
  const [instagramData, setInstagramData] = useState(0);

  const artist = useSelector((state) => state.artist.artist);

  useEffect(() => {
    if (artist) {
      axios
        .get(`${URLconfig.BASE_URL}/artist-stats/${artist?.spotify_id}`)
        .then((res) => {
          const data = res?.data?.data;
          data.stats.forEach((stat) => {
            switch (stat.source) {
              case "deezer":
                setDeezerData(stat.data.followers_total || 0);
                break;
              case "youtube":
                setYoutubeData(stat.data.subscribers_total || 0);
                break;
              case "soundcloud":
                setSoundcloudData(stat.data.followers_total || 0);
                break;
              case "spotify":
                setSpotifyData(stat.data.followers_total || 0);
                break;
              case "tiktok":
                setTiktokData(stat.data.followers_total || 0);
                break;
              case "instagram":
                setInstagramData(stat.data.followers_total || 0);
                break;
              default:
                break;
            }
          });
        })
        .catch(console.error);
    }
  }, [artist]);

  useEffect(() => {
    if (artist) {
      setFacebookData(artist.chartmetric.cm_statistics.facebook_followers || 0);
    }
  }, [artist]);

  const filteredPlatforms = [
    { icon: SpotifyIcon, data: spotifyData, label: "Spotify" },
    { icon: FacebookIcon, data: facebookData, label: "Facebook" },
    { icon: TiktokIcon, data: tiktokData, label: "Tiktok" },
    { icon: InstaIcon, data: instagramData, label: "Instagram" },
    { icon: DeezerIcon, data: deezerData, label: "Deezer" },
    { icon: YoutubeIcon, data: youtubeData, label: "Youtube" },
  ].filter((platform) => platform.data > 0);

  const socialData = filteredPlatforms.map((platform) => ({
    icon: platform.icon,
    streams: new Intl.NumberFormat().format(platform.data),
    subStreams: "Followers",
  }));

  const chartOptions = {
    series: [
      {
        name: "Followers",
        data: filteredPlatforms.map((platform) =>
          platform.data > 0 ? Math.log10(platform.data) : 0
        ),
      },
    ],
    chart: { type: "radar", toolbar: { show: false } },
    title: { text: "", align: "center" },
    stroke: { show: false, width: 2, colors: [], dashArray: 0 },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "diagonal2",
        shadeIntensity: 1,
        opacityFrom: 0.9,
        opacityTo: 1,

        colorStops: [
          {
            offset: 0,
            color: "#2900FF",
            opacity: 1,
          },
          {
            offset: 20,
            color: "#2900FF",
            opacity: 0.9,
          },
          {
            offset: 50,
            color: "#FF0059",
            opacity: 0.9,
          },
          {
            offset: 100,
            color: "#FF0059",
            opacity: 0.9,
          },
        ],
      },
    },
    xaxis: {
      labels: { show: true },
      categories: filteredPlatforms.map((platform) => platform.label),
    },
    yaxis: { show: false },
    dataLabels: { enabled: false },
    plotOptions: {
      radar: {
        size: 90,
        offsetX: 0,
        offsetY: -30,
        polygons: {
          strokeColor: "#999999",
          fill: { colors: ["#5A7380", "#5A7380"] },
        },
      },
    },
    tooltip: {
      enabled: true,
      hideEmptySeries: true,
      theme: "dark",
      highlightDataSeries: true,
      style: {
        fontFamily: "DM Sans",
        fontSize: "12px",
      },
      x: {
        show: false,
      },
      y: {
        formatter: (val, { series, seriesIndex, dataPointIndex, w }) => {
          // Only return the label and formatted number of followers
          return `${
            filteredPlatforms[dataPointIndex].label
          }: ${new Intl.NumberFormat().format(
            filteredPlatforms[dataPointIndex].data
          )} Followers`;
        },
        title: {
          formatter: () => {
            return ""; // Return an empty string so that the title does not repeat
          },
        },
      },
      z: {
        formatter: undefined,
        title: "Size: ",
      },
      marker: {
        show: false,
      },
      items: {
        display: "flex",
      },
      fixed: {
        enabled: false,
        position: "BottomRight",
        offsetX: 0,
        offsetY: 0,
      },
      title: {
        formatter: (seriesName, { seriesIndex, dataPointIndex, w }) => {
          return "";
        },
      },
    },
  };

  return (
    <Box component="div" className={classes.page}>
      <Typography className={classes.page__topHeading}>
        Social Media Stats
      </Typography>
      <Box component="div" className={classes.page__innerContainer}>
        <Box
          component="div"
          className={classes.page__innerContainer__rightContainer}
        >
          {socialData.map((item, index) => (
            <Box
              key={index}
              component="div"
              className={
                classes.page__innerContainer__rightContainer__infoContainer
              }
            >
              <Avatar
                src={item.icon}
                alt="Platform Icon"
                sx={{ height: "30px", width: "30px" }}
              />
              <Box>
                <Typography
                  className={
                    classes.page__innerContainer__rightContainer__infoContainer__streams
                  }
                >
                  {item.streams}
                </Typography>
                <Typography
                  className={
                    classes.page__innerContainer__rightContainer__infoContainer__streamsSecondary
                  }
                >
                  {item.subStreams}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>

        <Box
          component="div"
          className={classes.page__innerContainer__leftContainer}
        >
          <ReactApexChart
            options={chartOptions}
            series={chartOptions.series}
            type="radar"
            height={280}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SocialMediaGraph;
