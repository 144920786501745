/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import classess from "./style.module.scss";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTrackByID } from "../../redux/slice/artist";
import Grid from "@mui/material/Grid";
import { IconButton } from "@mui/material";
import BackIcon from "../../assets/BackIcon.png";
import axios from "axios";
import { MotionConfig, motion } from "framer-motion";
import { config as URLconfig } from "../../enviorment/enviorment";
import youtubeIcon from "../..//assets/social/social-icon2.png";
import spotifyIcon from "../../assets/social/social-icon3.png";
import deezerIcon from "../../assets/social/social-icon5.png";
import tiktokIcon from "../../assets/social/social-icon8.png";
import Soundcloud from "../../assets/social/social-icon4.png";
import { addCommasToNumber } from "../../utils/helper";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { CustomDropdown } from "../../custom-mui-style/custom-mui-styles";
import Threedotmenu from "../../assets/threedotmenu.png";
import GrowthArrowUp from "../../assets/growthArrowUp.png";
import NewLoader from "../../components/lottie-loader/Loader.json";
import Lottie from "lottie-react";
import GenreGraph from "../graph/streamGraph/graph";
import TiktokGraph from "./Graph/TiktokGraph";
import SpotifyGraph from "./Graph/SpotifyGraph";
import YoutubeGraph from "./Graph/YoutubeGraph";
import DeezerGraph from "./Graph/DeezerGraph";
import SoundCloudGraph from "./Graph/SoundCloudGraph";

const Track = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const artist = useSelector((state) => state.artist.artist);
  const [included_music, set_included_music] = useState(null);
  const [contract_length, set_contract_length] = useState(null);
  const [catelog_income, set_catelog_income] = useState(null);
  const [new_music_income, set_new_music_income] = useState(null);
  const [track_funding_est, Settrack_funding_est] = useState(0);
  const internationalNumberFormat = new Intl.NumberFormat("en-US");
  const [TotalYearlyInome, setTotalYearlyInome] = useState(0);
  const SingleTrackData = useSelector((state) => state.artist.track);
  const [selectedInterval, setSelectedInterval] = useState("Daily");
  const intervalOptions = ["Daily", "Weekly", "Monthly"];
  const [spotifyopen, setSpotifyOpen] = useState(true);
  const [youtubeOpen, setYoutubeOpen] = useState(true);
  const [tiktokOpen, setTiktokOpen] = useState(true);
  const [deezerOpen, setDeezerOpen] = useState(true);
  const [soundcloudOpen, setSoundcloudOpen] = useState(true);

  const [newminDate, setminDate] = useState(0);
  const [newmaxDate, setmaxDate] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const calculate_tract_estimate = async (data, artist_funding_data) => {
    try {
      const controller = new AbortController();
      let options = {
        signal: controller.signal,
      };
      const new_music_tracks = [];
      const val = {
        included_music: artist_funding_data?.included_music,
        contract_length: artist_funding_data?.contract_length,
        catelog_income: artist_funding_data?.catelog_income,
        new_music_income: artist_funding_data?.new_music_income,
        selected_tracks: [data],
        new_music_tracks,
      };
      if (artist_funding_data) {
        const response = await axios.post(
          `${URLconfig.BASE_URL}/artist-funding/${SingleTrackData?.spotify_artist_id}/customize`,
          val,
          options
        );
        Settrack_funding_est(response.data.data.funding);
      }

      setIsLoading(true);
    } catch (error) {
      console.log("Error Of Customize Funding " + error);
    }
  };

  const calculate_tract_yearly_income = async (track_id) => {
    try {
      // const controller = new AbortController();
      const data = { tracksId: [track_id] };
      const response = await axios.post(
        `${URLconfig.BASE_URL}/artist-funding/${SingleTrackData?.spotify_artist_id}/MonthlyCalculation`,
        data
      );
      const income = response.data.data;
      delete income["2023-10"];
      const totalIncome = Object.values(income).reduce(
        (acc, item) => acc + item.income,
        0
      );
      setTotalYearlyInome(totalIncome);
    } catch (error) {
      console.log("Error Of Customize Funding " + error);
    }
  };

  useEffect(() => {
    dispatch(getTrackByID({ id: id }));
  }, [id]);

  useEffect(() => {
    calculate_tract_yearly_income(id);
    getArtistFundingMetaData(SingleTrackData?.spotify_artist_id);
    if (SingleTrackData?.historic?.spotify) {
      createdataforSpotify(SingleTrackData?.historic?.spotify);

      if (SingleTrackData?.historic?.youtube.length > 0) {
        createdataforyoutube(SingleTrackData?.historic?.youtube);
      }
    }
  }, [SingleTrackData]);
  const createdataforSpotify = (data) => {
    const timestamps = data.map((item) =>
      new Date(item?.timestp || item?.date || new Date()).getTime()
    );
    const minTimestamp = Math.min(...timestamps);
    const maxTimestamp = Math.max(...timestamps);
    const chartData = data.map((item) => {
      const timestamp = item?.timestp || item?.date;
      const isValidTimestamp =
        timestamp && !isNaN(new Date(timestamp).getTime());

      return [
        isValidTimestamp ? new Date(timestamp).getTime() : null,
        Math.round(
          item?.daily_diff !== undefined
            ? item?.daily_diff
            : item?.streams_total || 0
        ),
      ];
    });
    const minDate = new Date(minTimestamp).toISOString().split("T")[0];
    const maxDate = new Date(maxTimestamp).toISOString().split("T")[0];
    setminDate(minDate);
    setmaxDate(maxDate);
    const chartConfig = {
      data: chartData,
      minDate,
      maxDate,
    };
    setSpotifystate(chartConfig);
  };
  const createdataforyoutube = (data) => {
    const timestamps = data.map((item) =>
      new Date(item?.timestp || item?.date || new Date()).getTime()
    );
    const minTimestamp = Math.min(...timestamps);
    const maxTimestamp = Math.max(...timestamps);
    const chartData = data.map((item) => {
      const timestamp = item?.timestp || item?.date;
      const isValidTimestamp =
        timestamp && !isNaN(new Date(timestamp).getTime());

      return [
        isValidTimestamp ? new Date(timestamp).getTime() : null,
        Math.round(
          item?.diff !== undefined ? item?.diff : item?.video_views_total || 0
        ),
      ];
    });
    const minDate = new Date(minTimestamp).toISOString().split("T")[0];
    const maxDate = new Date(maxTimestamp).toISOString().split("T")[0];
    setminDate(minDate);
    setmaxDate(maxDate);
    const chartConfig = {
      data: chartData,
      minDate,
      maxDate,
    };
    setyoutubeState(chartConfig);
  };

  const getArtistFundingMetaData = async (spotify_id) => {
    let endpoint = `${URLconfig.BASE_URL}/artist-funding/${spotify_id}`;
    const artist_funding_response = await axios.get(endpoint);
    const artist_funding_data = artist_funding_response.data["data"];
    set_contract_length(artist_funding_data?.contract_length);
    set_new_music_income(artist_funding_data?.new_music_income);
    set_catelog_income(artist_funding_data?.catelog_income);
    set_included_music(artist_funding_data?.included_music);
    calculate_tract_estimate(SingleTrackData, artist_funding_data);
  };

  useEffect(() => {}, []);

  const handleIntervalChange = (event) => {
    setSelectedInterval(event.target.value);
  };

  const spotifyMenuOpen = () => {
    // console.log(spotifyopen);
    setSpotifyOpen(!spotifyopen);
    setYoutubeOpen(true);
    setTiktokOpen(true);
    setDeezerOpen(true);
    setSoundcloudOpen(true);
  };

  const youtubeMenuOpen = () => {
    // console.log("Youtube");
    setYoutubeOpen(!youtubeOpen);
    setSpotifyOpen(true);
    setTiktokOpen(true);
    setDeezerOpen(true);
    setSoundcloudOpen(true);
  };

  const tiktokMenuOpen = () => {
    // console.log("Tiktok");
    setTiktokOpen(!tiktokOpen);
    setYoutubeOpen(true);
    setSpotifyOpen(true);
    setDeezerOpen(true);
    setSoundcloudOpen(true);
  };

  const deezerMenuOpen = () => {
    // console.log("Deezer");
    setDeezerOpen(!deezerOpen);
    setTiktokOpen(true);
    setYoutubeOpen(true);
    setSpotifyOpen(true);
    setSoundcloudOpen(true);
  };

  const soundcloudMenuOpen = () => {
    // console.log("SoundCloud");
    setSoundcloudOpen(!soundcloudOpen);
    setDeezerOpen(true);
    setTiktokOpen(true);
    setYoutubeOpen(true);
    setSpotifyOpen(true);
  };

  const SporifyData = [
    { country_code: "US", streams: "1345678" },
    { country_code: "GB", streams: "34534534" },
    { country_code: "PK", streams: "567567" },
    { country_code: "IND", streams: "456456" },
  ];

  const YoutubeData = [
    { country_code: "US", streams: "123123" },
    { country_code: "GB", streams: "123123" },
    { country_code: "PK", streams: "123123" },
    { country_code: "IND", streams: "123123" },
  ];

  const TiktokData = [
    { country_code: "US", streams: "678678" },
    { country_code: "GB", streams: "678678" },
    { country_code: "PK", streams: "678678" },
    { country_code: "IND", streams: "678678" },
  ];

  const deezerData = [
    { country_code: "US", streams: "789789" },
    { country_code: "GB", streams: "789789" },
    { country_code: "PK", streams: "789789" },
    { country_code: "IND", streams: "789789" },
  ];

  const SoundCloudData = [
    { country_code: "US", streams: "789789" },
    { country_code: "GB", streams: "789789" },
    { country_code: "PK", streams: "789789" },
    { country_code: "IND", streams: "789789" },
  ];

  const genress = SingleTrackData?.genres;
  const genresArray = genress ? genress.split(",") : [];

  const [Spotifystate, setSpotifystate] = React.useState({});

  const [youtubeState, setyoutubeState] = React.useState({});

  const [tiktokStates] = React.useState({
    series: [
      {
        data: [
          [1640995200000, 20],
          [1641081600000, 40],
          [1641168000000, 10],
          [1641254400000, 68],
          [1641340800000, 93],
          [1641427200000, 45],
          [1641513600000, 32],
          [1641600000000, 56],
          [1641686400000, 89],
          [1641772800000, 76],
          [1641859200000, 78],
          [1641945600000, 34],
          [1642032000000, 78],
          [1642118400000, 45],
          [1642204800000, 23],
          [1642291200000, 67],
          [1642377600000, 34],
          [1642464000000, 76],
          [1642550400000, 34],
          [1642636800000, 78],
          [1642723200000, 23],
          [1642809600000, 78],
          [1642896000000, 34],
          [1642982400000, 78],
          [1643068800000, 34],
          [1643155200000, 56],
          [1643241600000, 78],
          [1643328000000, 45],
          [1643414400000, 67],
          [1643500800000, 43],
        ],
      },
    ],
    options: {
      chart: {
        id: "chart2",
        type: "area",
        height: 350,
        foreColor: "#00f2ea",
        toolbar: {
          autoSelected: "pan",
          show: false,
        },
      },
      colors: ["#00f2ea"],
      stroke: {
        width: 3,
      },
      grid: {
        borderColor: "#555",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        gradient: {
          enabled: true,
          opacityFrom: 0.55,
          opacityTo: 0,
        },
      },
      // markers: {
      //   size: 3,
      //   colors: ["#222c41"],
      //   strokeColor: "#4ffcb7",
      //   strokeWidth: 3,
      // },
      tooltip: {
        theme: "dark",
      },
      xaxis: {
        type: "datetime",
      },
      yaxis: {
        min: 0,
        tickAmount: 4,
      },
    },

    //   Second Graph Starts

    seriesLine: [
      {
        data: [
          [1640995200000, 20],
          [1641081600000, 40],
          [1641168000000, 10],
          [1641254400000, 68],
          [1641340800000, 93],
          [1641427200000, 45],
          [1641513600000, 32],
          [1641600000000, 56],
          [1641686400000, 89],
          [1641772800000, 76],
          [1641859200000, 78],
          [1641945600000, 34],
          [1642032000000, 78],
          [1642118400000, 45],
          [1642204800000, 23],
          [1642291200000, 67],
          [1642377600000, 34],
          [1642464000000, 76],
          [1642550400000, 34],
          [1642636800000, 78],
          [1642723200000, 23],
          [1642809600000, 78],
          [1642896000000, 34],
          [1642982400000, 78],
          [1643068800000, 34],
          [1643155200000, 56],
          [1643241600000, 78],
          [1643328000000, 45],
          [1643414400000, 67],
          [1643500800000, 43],
        ],
      },
    ],

    optionsLine: {
      chart: {
        id: "chart1",
        height: 130,
        type: "bar",
        foreColor: "#ccc",
        brush: {
          target: "chart2",
          enabled: true,
        },
        selection: {
          enabled: true,
          fill: {
            color: "#222c41",
            opacity: 0.4,
          },
          xaxis: {
            min: new Date("1 Jan 2022").getTime(),
            max: new Date("30 Jan 2022").getTime(),
          },
        },
      },
      colors: ["#00f2ea"],
      stroke: {
        width: 2,
      },
      grid: {
        borderColor: "#444",
      },
      markers: {
        size: 0,
      },
      xaxis: {
        type: "datetime",
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        tickAmount: 2,
      },
      //   fill: {
      //     type: "gradient",
      //     gradient: {
      //       opacityFrom: 0.91,
      //       opacityTo: 0.1,
      //     },
      //   },
    },
  });

  const [deezerStates] = React.useState({
    series: [
      {
        data: [
          [1640995200000, 20],
          [1641081600000, 40],
          [1641168000000, 10],
          [1641254400000, 68],
          [1641340800000, 93],
          [1641427200000, 45],
          [1641513600000, 32],
          [1641600000000, 56],
          [1641686400000, 89],
          [1641772800000, 76],
          [1641859200000, 78],
          [1641945600000, 34],
          [1642032000000, 78],
          [1642118400000, 45],
          [1642204800000, 23],
          [1642291200000, 67],
          [1642377600000, 34],
          [1642464000000, 76],
          [1642550400000, 34],
          [1642636800000, 78],
          [1642723200000, 23],
          [1642809600000, 78],
          [1642896000000, 34],
          [1642982400000, 78],
          [1643068800000, 34],
          [1643155200000, 56],
          [1643241600000, 78],
          [1643328000000, 45],
          [1643414400000, 67],
          [1643500800000, 43],
        ],
      },
    ],
    options: {
      chart: {
        id: "chart2",
        type: "area",
        height: 350,
        foreColor: "#c2ff00",
        toolbar: {
          autoSelected: "pan",
          show: false,
        },
      },
      colors: ["#c2ff00"],
      stroke: {
        width: 3,
      },
      grid: {
        borderColor: "#555",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        gradient: {
          enabled: true,
          opacityFrom: 0.55,
          opacityTo: 0,
        },
      },
      // markers: {
      //   size: 3,
      //   colors: ["#222c41"],
      //   strokeColor: "#c2ff00",
      //   strokeWidth: 3,
      // },
      tooltip: {
        theme: "dark",
      },
      xaxis: {
        type: "datetime",
      },
      yaxis: {
        min: 0,
        tickAmount: 4,
      },
    },

    //   Second Graph Starts

    seriesLine: [
      {
        data: [
          [1640995200000, 20],
          [1641081600000, 40],
          [1641168000000, 10],
          [1641254400000, 68],
          [1641340800000, 93],
          [1641427200000, 45],
          [1641513600000, 32],
          [1641600000000, 56],
          [1641686400000, 89],
          [1641772800000, 76],
          [1641859200000, 78],
          [1641945600000, 34],
          [1642032000000, 78],
          [1642118400000, 45],
          [1642204800000, 23],
          [1642291200000, 67],
          [1642377600000, 34],
          [1642464000000, 76],
          [1642550400000, 34],
          [1642636800000, 78],
          [1642723200000, 23],
          [1642809600000, 78],
          [1642896000000, 34],
          [1642982400000, 78],
          [1643068800000, 34],
          [1643155200000, 56],
          [1643241600000, 78],
          [1643328000000, 45],
          [1643414400000, 67],
          [1643500800000, 43],
        ],
      },
    ],

    optionsLine: {
      chart: {
        id: "chart1",
        height: 130,
        type: "bar",
        foreColor: "#ccc",
        brush: {
          target: "chart2",
          enabled: true,
        },
        selection: {
          enabled: true,
          fill: {
            color: "#222c41",
            opacity: 0.4,
          },
          xaxis: {
            min: new Date("1 Jan 2022").getTime(),
            max: new Date("30 Jan 2022").getTime(),
          },
        },
      },
      colors: ["#c2ff00"],
      stroke: {
        width: 2,
      },
      grid: {
        borderColor: "#444",
      },
      markers: {
        size: 0,
      },
      xaxis: {
        type: "datetime",
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        tickAmount: 2,
      },
      //   fill: {
      //     type: "gradient",
      //     gradient: {
      //       opacityFrom: 0.91,
      //       opacityTo: 0.1,
      //     },
      //   },
    },
  });

  const [soundCloudStates] = React.useState({
    series: [
      {
        data: [
          [1640995200000, 20],
          [1641081600000, 40],
          [1641168000000, 10],
          [1641254400000, 68],
          [1641340800000, 93],
          [1641427200000, 45],
          [1641513600000, 32],
          [1641600000000, 56],
          [1641686400000, 89],
          [1641772800000, 76],
          [1641859200000, 78],
          [1641945600000, 34],
          [1642032000000, 78],
          [1642118400000, 45],
          [1642204800000, 23],
          [1642291200000, 67],
          [1642377600000, 34],
          [1642464000000, 76],
          [1642550400000, 34],
          [1642636800000, 78],
          [1642723200000, 23],
          [1642809600000, 78],
          [1642896000000, 34],
          [1642982400000, 78],
          [1643068800000, 34],
          [1643155200000, 56],
          [1643241600000, 78],
          [1643328000000, 45],
          [1643414400000, 67],
          [1643500800000, 43],
        ],
      },
    ],
    options: {
      chart: {
        id: "chart2",
        type: "area",
        height: 350,
        foreColor: "#ff8800",
        toolbar: {
          autoSelected: "pan",
          show: false,
        },
      },
      colors: ["#ff8800"],
      stroke: {
        width: 3,
      },
      grid: {
        borderColor: "#555",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        gradient: {
          enabled: true,
          opacityFrom: 0.55,
          opacityTo: 0,
        },
      },
      // markers: {
      //   size: 3,
      //   colors: ["#222c41"],
      //   strokeColor: "#ff8800",
      //   strokeWidth: 3,
      // },
      tooltip: {
        theme: "dark",
      },
      xaxis: {
        type: "datetime",
      },
      yaxis: {
        min: 0,
        tickAmount: 4,
      },
    },

    //   Second Graph Starts

    seriesLine: [
      {
        data: [
          [1640995200000, 20],
          [1641081600000, 40],
          [1641168000000, 10],
          [1641254400000, 68],
          [1641340800000, 93],
          [1641427200000, 45],
          [1641513600000, 32],
          [1641600000000, 56],
          [1641686400000, 89],
          [1641772800000, 76],
          [1641859200000, 78],
          [1641945600000, 34],
          [1642032000000, 78],
          [1642118400000, 45],
          [1642204800000, 23],
          [1642291200000, 67],
          [1642377600000, 34],
          [1642464000000, 76],
          [1642550400000, 34],
          [1642636800000, 78],
          [1642723200000, 23],
          [1642809600000, 78],
          [1642896000000, 34],
          [1642982400000, 78],
          [1643068800000, 34],
          [1643155200000, 56],
          [1643241600000, 78],
          [1643328000000, 45],
          [1643414400000, 67],
          [1643500800000, 43],
        ],
      },
    ],

    optionsLine: {
      chart: {
        id: "chart1",
        height: 130,
        type: "bar",
        foreColor: "#ccc",
        brush: {
          target: "chart2",
          enabled: true,
        },
        selection: {
          enabled: true,
          fill: {
            color: "#222c41",
            opacity: 0.4,
          },
          xaxis: {
            min: new Date("1 Jan 2022").getTime(),
            max: new Date("30 Jan 2022").getTime(),
          },
        },
      },
      colors: ["#ff8800"],
      stroke: {
        width: 2,
      },
      grid: {
        borderColor: "#444",
      },
      markers: {
        size: 0,
      },
      xaxis: {
        type: "datetime",
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        tickAmount: 2,
      },
      //   fill: {
      //     type: "gradient",
      //     gradient: {
      //       opacityFrom: 0.91,
      //       opacityTo: 0.1,
      //     },
      //   },
    },
  });

  const CustomBux = ({
    icon,
    title,
    streams,
    open,
    onclick,
    metaData,
    graphStates,
    graph,
  }) => {
    return (
      <MotionConfig>
        <motion.div
          className={
            classess.page__details__box__tracks__stream_container__box_container__box
          }
          style={{
            overflow: "hidden",
          }}
          initial={{ height: "136px" }}
          animate={{
            height: open === true ? "136px" : "500px",
          }}
          transition={{ duration: 0.3 }}
          // onClick={onclick}
        >
          <Box
            className={
              classess.page__details__box__tracks__stream_container__box_container__box__header
            }
          >
            <Box
              className={
                classess.page__details__box__tracks__stream_container__box_container__box__header__icon_title_container
              }
            >
              <img
                src={icon}
                alt="Icon"
                className={
                  classess.page__details__box__tracks__stream_container__box_container__box__header__icon_title_container__icon
                }
              />

              <span
                className={
                  classess.page__details__box__tracks__stream_container__box_container__box__header__icon_title_container__title
                }
              >
                {title}
              </span>
            </Box>
            <Box
              className={
                classess.page__details__box__tracks__stream_container__box_container__box__header__icon_container
              }
              onClick={onclick}
              animate={{
                height: open === true ? "136px" : "586px",
              }}
            >
              <img
                src={Threedotmenu}
                alt="Menu"
                className={
                  classess.page__details__box__tracks__stream_container__box_container__box__header__icon_container__menu_icon
                }
              />
            </Box>
          </Box>

          <Box
            className={
              classess.page__details__box__tracks__stream_container__box_container__box__streams_container
            }
          >
            <span
              className={
                classess.page__details__box__tracks__stream_container__box_container__box__streams_container__title
              }
            >
              {addCommasToNumber(streams)}
            </span>
            <img
              src={GrowthArrowUp}
              alt="growth icon"
              style={{ width: "10px", height: "7.92" }}
            />
          </Box>

          <Box
            className={
              classess.page__details__box__tracks__stream_container__box_container__box__buttom_container
            }
          >
            <span
              className={
                classess.page__details__box__tracks__stream_container__box_container__box__buttom_container__heading
              }
            >
              STREAMS
            </span>
          </Box>

          <Box
            className={
              classess.page__details__box__tracks__stream_container__box_container__box__country_data
            }
          >
            <Box
              className={
                classess.page__details__box__tracks__stream_container__box_container__box__country_data__graph
              }
            >
              {graph}
            </Box>
          </Box>
        </motion.div>
      </MotionConfig>
    );
  };

  return (
    <Container maxWidth="xxl">
      <Grid container spacing={2} className={classess.page}>
        <Grid
          item
          sm={12}
          md={12}
          lg={12}
          xl={9}
          className={classess.page__details}
        >
          {SingleTrackData === null ||
          SingleTrackData === "" ||
          isLoading === false ? (
            <Box
              varient="div"
              component="div"
              className={classess.page__details__box}
            >
              <Box
                varient="div"
                component="div"
                className={classess.page__details__box__tracks}
              >
                <Box
                  varient="div"
                  component="div"
                  className={
                    classess.page__details__box__tracks__loader_container
                  }
                >
                  <Box
                    sx={{ width: "42px", height: "42px", alignItems: "center" }}
                  >
                    <Lottie
                      animationData={NewLoader}
                      className={classess.page__loader}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              varient="div"
              component="div"
              className={classess.page__details__box}
            >
              <Box
                varient="div"
                component="div"
                className={classess.page__details__box__tracks}
              >
                <Box
                  varient="div"
                  component="div"
                  className={classess.page__details__box__tracks__header}
                >
                  <IconButton
                    onClick={() => {
                      navigate(-1);
                    }}
                    style={{
                      backgroundColor: "#4ffcb7",
                      height: "21px",
                      width: "21px",
                    }}
                  >
                    <img
                      src={BackIcon}
                      alt="Eye"
                      style={{
                        height: "9.92px",
                        width: "5.86px",
                      }}
                    />
                  </IconButton>
                  <span
                    className={
                      classess.page__details__box__tracks__header__title
                    }
                  >
                    {SingleTrackData?.title}
                  </span>
                </Box>

                <Box className={classess.page__details__box__tracks__lineOne}>
                  <Box
                    variant="div"
                    component="div"
                    className={
                      classess.page__details__box__tracks__lineOne__genre
                    }
                  >
                    <GenreGraph artist={genresArray} />
                  </Box>

                  <Box
                    variant="div"
                    component="div"
                    className={
                      classess.page__details__box__tracks__lineOne__release_date
                    }
                  >
                    <span
                      className={
                        classess.page__details__box__tracks__lineOne__release_date__text
                      }
                    >
                      Release Date:
                    </span>

                    <span
                      className={
                        classess.page__details__box__tracks__lineOne__release_date__text2
                      }
                    >
                      {SingleTrackData.release_date === null
                        ? null
                        : SingleTrackData.release_date}
                    </span>
                  </Box>

                  <Box
                    variant="div"
                    component="div"
                    className={
                      classess.page__details__box__tracks__lineOne__year_ext
                    }
                  >
                    <span
                      className={
                        classess.page__details__box__tracks__lineOne__year_ext__text
                      }
                    >
                      YEARLY ESTIMATE
                    </span>

                    <span
                      className={
                        classess.page__details__box__tracks__lineOne__year_ext__text2
                      }
                    >
                      {"$"} {addCommasToNumber(TotalYearlyInome)}
                    </span>
                  </Box>

                  <Box
                    variant="div"
                    component="div"
                    className={
                      classess.page__details__box__tracks__lineOne__yearly
                    }
                  >
                    <span
                      className={
                        classess.page__details__box__tracks__lineOne__yearly__text
                      }
                    >
                      Funding Estimate
                    </span>
                    <span
                      className={
                        classess.page__details__box__tracks__lineOne__release_date__text2
                      }
                    >
                      {"$"}{" "}
                      {internationalNumberFormat.format(track_funding_est)}
                    </span>
                  </Box>
                </Box>

                <Box className={classess.page__details__box__tracks__lineTwo}>
                  <Box
                    variant="div"
                    component="div"
                    className={
                      classess.page__details__box__tracks__lineTwo__own_perc
                    }
                  >
                    <span
                      className={
                        classess.page__details__box__tracks__lineTwo__own_perc__text
                      }
                    >
                      OWNERSHIP PERCENTAGE
                    </span>
                    <span
                      className={
                        classess.page__details__box__tracks__lineTwo__own_perc__text2
                      }
                    >
                      {SingleTrackData.stream_income_share === null
                        ? null
                        : SingleTrackData.stream_income_share}
                      {"%"}
                    </span>
                  </Box>
                  <Box
                    variant="div"
                    component="div"
                    className={
                      classess.page__details__box__tracks__lineTwo__isrc_code
                    }
                  >
                    <span
                      className={
                        classess.page__details__box__tracks__lineTwo__isrc_code__text
                      }
                    >
                      ISRC CODE:
                    </span>
                    <Box
                      className={
                        classess.page__details__box__tracks__lineTwo__isrc_code__text2
                      }
                    >
                      {SingleTrackData.isrc === null
                        ? null
                        : SingleTrackData.isrc}
                    </Box>
                  </Box>
                </Box>

                <Box
                  className={
                    classess.page__details__box__tracks__stream_container
                  }
                >
                  <Box
                    className={
                      classess.page__details__box__tracks__stream_container__header
                    }
                  >
                    <Box>
                      <span
                        className={
                          classess.page__details__box__tracks__stream_container__header__heading
                        }
                      >
                        Chart History
                      </span>
                    </Box>
                    <FormControl
                      variant="outlined"
                      className={
                        classess.page__details__box__tracks__stream_container__header__interval
                      }
                      sx={{
                        height: "28px",
                        width: "111px",
                        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#5A7380 !important",
                          },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#5A7380 !important",
                          borderWidth: "2px",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "#4FFCB7",
                        },
                        "& .MuiOutlinedInput-input": {
                          color: "#4FFCB7",
                          fontWeight: "bold",
                        },
                      }}
                    >
                      <CustomDropdown
                        value={selectedInterval}
                        onChange={handleIntervalChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{
                          borderRadius: "12px",
                        }}
                      >
                        {intervalOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </CustomDropdown>
                    </FormControl>
                  </Box>

                  <Box
                    className={
                      classess.page__details__box__tracks__stream_container__box_container
                    }
                  >
                    <CustomBux
                      icon={spotifyIcon}
                      title={"Spotify"}
                      streams={SingleTrackData?.spotify_streams_total}
                      open={spotifyopen}
                      onclick={spotifyMenuOpen}
                      metaData={SingleTrackData?.historic?.spotify}
                      graph={<SpotifyGraph Spotifystate={Spotifystate} />}
                    />

                    <CustomBux
                      icon={youtubeIcon}
                      title={"YouTube"}
                      streams={SingleTrackData?.youtube_video_views_total}
                      open={youtubeOpen}
                      onclick={youtubeMenuOpen}
                      metaData={SingleTrackData?.historic?.youtube}
                      graph={<YoutubeGraph youtubeState={youtubeState} />}
                    />

                    <CustomBux
                      icon={tiktokIcon}
                      title={"Tiktok"}
                      streams={SingleTrackData?.tiktok_views_total}
                      open={tiktokOpen}
                      onclick={tiktokMenuOpen}
                      metaData={TiktokData}
                      graph={<TiktokGraph />}
                    />

                    <CustomBux
                      icon={deezerIcon}
                      title={"Deezer"}
                      streams={SingleTrackData?.deezer_reach_total}
                      open={deezerOpen}
                      onclick={deezerMenuOpen}
                      metaData={deezerData}
                      graph={<DeezerGraph />}
                    />
                    <CustomBux
                      icon={Soundcloud}
                      title={"Soundcloud"}
                      streams={"536742"}
                      open={soundcloudOpen}
                      onclick={soundcloudMenuOpen}
                      metaData={SoundCloudData}
                      graph={<SoundCloudGraph />}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Track;
