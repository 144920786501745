import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import {
  Box,
  Avatar,
  Skeleton,
  Tooltip,
  IconButton,
  LinearProgress,
  Divider,
  Button,
} from "@mui/material";
import SyncRoundedIcon from "@mui/icons-material/SyncRounded";
import TrendingUpRoundedIcon from "@mui/icons-material/TrendingUpRounded";
import ReactCountryFlag from "react-country-flag";
import { countries } from "country-data";
import EditIcon from "../../assets/buttonsicons/EditIcon.png";
import { getItemToLocalStorage } from "../../services/storage";
import { config as URLconfig } from "../../enviorment/enviorment";
import { emptySingularArtist } from "../../redux/slice/artist";
import Platform from "../platform/Platform";
import GenreGraph from "../../containers/graph/streamGraph/graph";
import { addCommasToNumber } from "../../utils/helper";
import classes from "./style.module.scss";

const ArtistStatsComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const artist = useSelector((state) => state.artist.artist);
  const totalTracks = useSelector((state) => state.artist.totalTracks);
  const status = useSelector((state) => state.artist.status);
  const user = useSelector((state) => state.auth.user);
  const [isLoadedQueue, setIsLoadedQueue] = useState(false);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [socialLinks, setSocialLinks] = useState([]);
  const [queue, setQueue] = useState(null);
  const [initialQueue, setInitialQueue] = useState(null);
  const storedToken = getItemToLocalStorage("accessToken");

  // Utility Functions
  const getEmail = (email, name) => {
    const newName = name ? name.replace(/\s+/, "") : "Unknown";
    return email || `${newName}@blacklionapp.xyz`;
  };

  // Fetch and Update Functions
  const updateArtistStatAndChartmetrics = () => {
    setLoading(true);
    axios
      .post(
        `${URLconfig.BASE_URL}/artists/artistupdate/artist-stats-update`,
        {
          spotify_id: artist?.spotify_id,
          user,
        },
        {
          headers: { Authorization: `Bearer ${storedToken}` },
        }
      )
      .then(() => {
        toast.success("Artist stats updated", {
          hideProgressBar: true,
          closeButton: true,
          position: "bottom-center",
        });
        setLoading(false);
      })
      .catch(console.error);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        const diff = Math.random() * 10;
        return oldProgress === 100 ? 0 : Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (!artist) return;

    // Fetch artist stats and social links
    axios
      .get(`${URLconfig.BASE_URL}/artist-stats/${artist?.spotify_id}`)
      .then((res) => {
        const links = res?.data?.data?.links.filter((item) =>
          [
            "youtube",
            "deezer",
            "spotify",
            "tidal",
            "amazon",
            "apple_music",
          ].includes(item.source)
        );
        setSocialLinks(links);
      })
      .catch(console.error);

    // Initialize queue if not already set
    if (initialQueue === null) {
      axios
        .get(`${URLconfig.BASE_URL}/songstats/counter/${artist?.spotify_id}`)
        .then((response) => {
          const result = response.data;
          setInitialQueue(result.data?.count);
          setQueue(result.data?.count);
          setIsLoadedQueue(true);
        });
    }

    // Set up interval for queue updates if queue is not 0
    let intervalId = null;
    if (queue !== 0) {
      intervalId = setInterval(() => {
        axios
          .get(`${URLconfig.BASE_URL}/songstats/counter/${artist?.spotify_id}`)
          .then((response) => {
            const result = response.data;
            setQueue(result.data?.count);
            setIsLoadedQueue(true);
          });
      }, 3000);
    }

    // Cleanup on component unmount or artist change
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [artist, initialQueue, queue]);

  return (
    <Box varient="div" component="div" className={classes.page__artist__box}>
      <Box className={classes.page__artist__box__topdetails}>
        <Box className={classes.page__artist__box__topdetails__image}>
          {status === "succeeded" ? (
            <Box>
              <Avatar
                src={artist?.avatar}
                alt={artist?.name}
                className={classes.page__artist__box__topdetails__image}
                sx={{
                  border: "4px solid var(--picture-border-color)",
                }}
              />
              <Box className={classes.onlineInd} />
            </Box>
          ) : (
            <Skeleton
              variant="circular"
              width={89}
              height={89}
              sx={{ bgcolor: "grey.700" }}
              className={classes.skeleton}
            />
          )}
        </Box>

        <Box
          variant="div"
          component="div"
          className={classes.page__artist__box__topdetails__details}
        >
          <Box
            variant="div"
            component="div"
            className={
              classes.page__artist__box__topdetails__details__artistname
            }
          >
            <Box>
              <Tooltip
                title={artist?.name}
                placement="top"
                arrow
                enterDelay={100}
              >
                <span
                  className={
                    classes.page__artist__box__topdetails__details__artistname__name
                  }
                >
                  {artist?.name}
                </span>
              </Tooltip>
            </Box>
            <Tooltip
              title={"Edit Artist"}
              placement="top"
              arrow
              enterDelay={100}
            >
              <IconButton
                className={
                  classes.page__artist__box__topdetails__details__artistname__icon_container
                }
                onClick={() => {
                  dispatch(emptySingularArtist());
                  navigate(`/blig/edit-artist/${artist?._id}`);
                }}
              >
                <img
                  src={EditIcon}
                  alt="pencil icon"
                  style={{ height: "12px", width: "16px" }}
                />
              </IconButton>
            </Tooltip>
          </Box>

          <Tooltip
            title={getEmail(artist?.email, artist?.name)}
            placement="bottom"
            arrow
            enterDelay={100}
          >
            <Box
              variant="div"
              component="div"
              className={classes.page__artist__box__topdetails__details__email}
            >
              {getEmail(artist?.email, artist?.name)}
            </Box>
          </Tooltip>

          <Box
            variant="div"
            component="div"
            className={classes.page__artist__box__topdetails__details__country}
          >
            <span
              className={
                classes.page__artist__box__topdetails__details__country__flag
              }
            >
              <ReactCountryFlag countryCode={artist?.chartmetric?.code2} svg />
            </span>

            <span
              className={
                classes.page__artist__box__topdetails__details__country__name
              }
            >
              {artist?.chartmetric?.code2
                ? countries[artist?.chartmetric?.code2.toUpperCase()]?.name ||
                  "N/A"
                : "N/A"}
            </span>
          </Box>
        </Box>
      </Box>

      {isLoadedQueue ? null : (
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{
            backgroundColor: "#5a7380",
            height: "5px",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "var(--base-loader-color)",
              height: "5px",
            },
          }}
        />
      )}

      <Divider className={classes.page__artist__box__horizontalline} />

      <Box
        className={classes.page__artist__box__listnership}
        variant="div"
        component="div"
      >
        <span className={classes.page__artist__box__listnership__topheading}>
          LISTNERSHIP:
        </span>

        <Box className={classes.page__artist__box__listnership__innerlyer}>
          <Box
            variant="div"
            component="div"
            className={classes.page__artist__box__listnership__innerlyer__text2}
          >
            {addCommasToNumber(
              artist?.chartmetric?.cm_statistics?.sp_monthly_listeners
            )}
          </Box>
        </Box>
      </Box>

      <Divider className={classes.page__artist__box__horizontalline} />

      <Box
        variant="div"
        component="div"
        className={classes.page__artist__box__platforms}
        sx={{ height: "102px" }}
      >
        <span className={classes.page__artist__box__platforms__text}>
          PLATFORMS:
        </span>
        <Box className={classes.page__artist__box__platforms__socials}>
          <Platform socialLink={socialLinks} />
        </Box>
      </Box>

      <Divider className={classes.page__artist__box__horizontalline} />

      <Box className={classes.page__artist__box__genre}>
        <GenreGraph artist={artist?.genres} />
      </Box>

      <Divider className={classes.page__artist__box__horizontalline} />

      <Box className={classes.page__artist__box__tracks_info}>
        <Box className={classes.page__artist__box__tracks_info__left}>
          <Box className={classes.page__artist__box__tracks_info__left__text1}>
            Total Tracks:
          </Box>
          <Box className={classes.page__artist__box__tracks_info__left__text2}>
            {totalTracks}
          </Box>
        </Box>
        <Box className={classes.page__artist__box__tracks_info__right}>
          <Box
            className={
              classes.page__artist__box__tracks_info__right__top_details
            }
          >
            Last updated on {moment(artist?.updatedAt).format("MMM DD YY")}
            {" at "}
            {moment(artist?.updatedAt).format("hh:mm")}
          </Box>

          <Box>
            <Button
              variant="outlined"
              autoFocus
              onClick={updateArtistStatAndChartmetrics}
              className={
                classes.page__artist__box__tracks_info__right__update_botton
              }
              loading={loading}
              disabled={loading}
              startIcon={<SyncRoundedIcon />}
            >
              Update Stats
            </Button>
          </Box>
        </Box>
      </Box>

      <Divider className={classes.page__artist__box__horizontalline} />

      <Box className={classes.page__artist__box__buttons_container}>
        {(isLoadedQueue && queue === 0) || isLoadedQueue === null ? (
          isLoadedQueue === null ? (
            <Button
              className={
                classes.page__artist__box__buttons_container__view_funding_dashbord
              }
              sx={{
                opacity: isLoadedQueue && queue === 0 ? "1" : "0.3",
              }}
              disabled={isLoadedQueue && queue === 0 ? false : true}
              onClick={() =>
                navigate(`/blig/view-funding-dashboard/${artist?._id}`)
              }
              startIcon={
                <TrendingUpRoundedIcon
                  className={
                    classes.page__artist__box__buttons_container__view_funding_dashbord__inner_icon
                  }
                />
              }
            >
              Artist Loading
            </Button>
          ) : (
            <Button
              className={
                classes.page__artist__box__buttons_container__view_funding_dashbord
              }
              sx={{
                opacity: isLoadedQueue && queue === 0 ? "1" : "0.3",
              }}
              disabled={isLoadedQueue && queue === 0 ? false : true}
              onClick={() =>
                navigate(`/blig/view-funding-dashboard/${artist?._id}`)
              }
              startIcon={
                <TrendingUpRoundedIcon
                  className={
                    classes.page__artist__box__buttons_container__view_funding_dashbord__inner_icon
                  }
                />
              }
            >
              View Funding Dashboards
            </Button>
          )
        ) : queue > 0 ? (
          <Button
            className={
              classes.page__artist__box__buttons_container__view_funding_dashbord
            }
            disabled
            sx={{
              opacity: "0.5",
            }}
          >{`Loading Tracks(${queue}/${initialQueue})`}</Button>
        ) : (
          <Button
            className={
              classes.page__artist__box__buttons_container__view_funding_dashbord
            }
            sx={{
              opacity: isLoadedQueue && queue === 0 ? "1" : "0.3",
            }}
            disabled={isLoadedQueue && queue === 0 ? false : true}
            onClick={() =>
              navigate(`/blig/view-funding-dashboard/${artist?._id}`)
            }
            startIcon={
              <TrendingUpRoundedIcon
                className={
                  classes.page__artist__box__buttons_container__view_funding_dashbord__inner_icon
                }
              />
            }
          >
            View Funding Dashboards
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ArtistStatsComponent;
