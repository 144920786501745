import React, { useState, useRef } from "react";
import classess from "./style.module.scss";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  Typography,
} from "@mui/material";
import SignatureCanvas from "react-signature-canvas";
import { RxCross2 } from "react-icons/rx";
import DownloadIcon from "../../assets/downloadIcon.svg";
import { ReactSVG } from "react-svg";
import ClouddownIcon from "../../assets/clouddownIcon.svg";

const ArtistContractSign = ({ closeSign }) => {
  const [sign, setSign] = useState();
  const [selectSign, setSelectSign] = useState(null);
  const signatureRef = useRef(null);

  const handleClear = () => {
    signatureRef.current.clear(); // Clear the signature canvas
    setSign(false); // Update sign state to false as the signature field is now empty
  };
  const handleFileSelect = (e) => {
    setSelectSign(e.target.files[0]);
  };
  const handleButtonClick = () => {
    document.getElementById("fileInput").click();
  };
  const handleDownloadSign = () => {
    if (sign) {
      const dataURL = signatureRef.current.toDataURL(); // Get the data URL of the signature
      const link = document.createElement("a");
      link.href = dataURL;
      link.download = "signature.png"; // Set the file name for download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  return (
    <Box className={classess.page}>
      <Box className={classess.page__topSec} mt={0.5}>
        <Typography className={classess.page__topSec__heading}>
          Sign Contract
        </Typography>
        <Button className={classess.page__topSec__button} onClick={closeSign}>
          close
        </Button>
      </Box>

      <Box className={classess.page__container}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          pb={2}
        >
          <Typography className={classess.page__container__heading}>
            DIGITAL SIGNATURE
          </Typography>
          <Button
            startIcon={<ReactSVG src={ClouddownIcon} />}
            className={classess.page__container__downloadSign}
            onClick={handleDownloadSign}
            disabled={!sign}
            sx={{
              backgroundColor: !sign
                ? "var(--base-deactive-button-background-color) !important"
                : "",
            }}
          >
            Download sign
          </Button>
        </Box>
        <Box className={classess.page__container__signature_wrapper}>
          <SignatureCanvas
            penColor="black"
            canvasProps={{
              className: classess.page__container__signature_wrapper__sign,
            }}
            ref={signatureRef}
            onEnd={() => setSign(true)}
          />
          <Box className={classess.page__container__signature_wrapper__icon}>
            <IconButton
              onClick={handleClear}
              className={
                classess.page__container__signature_wrapper__icon__icon_cross
              }
            >
              <RxCross2 />
            </IconButton>
          </Box>
        </Box>
        <Box mt={2}>
          <Typography className={classess.page__container__heading}>
            OR UPLOAD AN IMAGE
          </Typography>
          <Button
            className={classess.page__container__upload_btn}
            startIcon={<ReactSVG src={DownloadIcon} />}
            onClick={handleButtonClick}
          >
            Upload from storage
            <input
              id="fileInput"
              hidden
              accept=".csv, .tsv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              type="file"
              onChange={handleFileSelect}
            />
          </Button>
        </Box>
        <Box mt={2}>
          <Typography className={classess.page__container__heading}>
            DIGITAL SIGNATURE
          </Typography>
          <Typography
            variant="subtitle2"
            component="p"
            sx={{
              color: "#fff",
              textAlign: "justify",
              wordSpacing: "-0.05rem",
              hyphens: "auto",
            }}
          >
            By signing this contract, [Party Name(s)] acknowledge(s) and
            agree(s) to the terms and conditions outlined herein. It is
            imperative to carefully review and understand the contents of this
            agreement before affixing your signature. If there are any
            uncertainties or queries regarding the contract clauses, seek legal
            counsel or clarification from the respective parties involved before
            signing.
          </Typography>
          <FormControl fullWidth>
            <Button
              className={classess.page__container__sign_btn}
              sx={{
                backgroundColor: !sign
                  ? "var(--base-deactive-button-background-color) !important"
                  : "",
              }}
              disabled={!sign}
            >
              Sign Contract
            </Button>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default ArtistContractSign;
