import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const AreaSplineChart = ({ series, height }) => {
  const [loading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    // First, create a lookup to easily find totals by date for each series
    const totalsByDate = {};
    series.forEach((item) => {
      item.data.forEach(({ total, date }) => {
        if (!totalsByDate[date]) {
          totalsByDate[date] = {};
        }
        totalsByDate[date][item.name] = total;
      });
    });

    // Filter out dates where either series has a value of zero
    const filteredSeriesData = series.map((item) => ({
      ...item,
      data: item.data.filter(({ date }) => {
        // Check both Spotify and YouTube totals for the given date
        const totals = Object.values(totalsByDate[date]);
        // Include this date only if all totals are non-zero
        return totals.every((total) => total !== 0);
      }),
    }));

    setData(filteredSeriesData);
    setIsLoading(false);
    console.log(filteredSeriesData);
  }, [series]);

  const options = {
    option: {
      chart: {
        height: 455,
        type: "area",
        foreColor: "#ccc",
        toolbar: {
          autoSelected: "pan",
          show: true,
        },
        zoom: {
          enabled: true,
        },
        animations: {
          enabled: true,
        },
      },
      forecastDataPoints: {
        count: 1,
      },
      colors: ["#1DB954", "#FF0000"],
      legend: {
        show: true,
        labels: {
          colors: "#FFF",
        },
        position: "top",
        itemMargin: {
          horizontal: 10,
        },
      },
      stroke: {
        width: 3,
        curve: "straight",
      },
      grid: {
        borderColor: "#ffffff20",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        gradient: {
          enabled: true,
          opacityFrom: 0.4,
          opacityTo: 0,
        },
      },
      tooltip: {
        theme: "dark",
      },
      xaxis: {
        type: "Monthly",
        categories: series[0].data.map((item) => item.date),
        tickAmount: 10,
      },

      title: {
        style: {
          fontSize: "16px",
          color: "white",
        },
      },
    },
  };

  // if (loading === true) {
  //   return "please wait";
  // }
  return (
    <Box height={"515px"}>
      {loading === false && (
        <ReactApexChart
          options={options.option}
          series={data.map((s) => ({
            name: s.name,
            data: s.data.map((item) => item.total),
          }))}
          type="area"
          height={height}
        />
      )}
    </Box>
  );
};

export default AreaSplineChart;
