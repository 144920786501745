// React library imports
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// MUI components and styles
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Skeleton from "@mui/material/Skeleton";

// External libraries
import axios from "axios";
import ReactCountryFlag from "react-country-flag";
import { countries } from "country-data";

// Utility functions and constants
import { addCommasToNumber } from "../../../utils/helper";
import { config as URLconfig } from "../../../enviorment/enviorment";

// Assets
import SpotifyIcon from "../../../assets/social/social-icon3.png";
import YoutubeIcon from "../../../assets/social/social-icon2.png";
import TiktokIcon from "../../../assets/social/social-icon8.png";
import DeezeerIcon from "../../../assets/social/social-icon4.png";

// Styles
import classes from "./style.module.scss";

import {
  CustomTableContainer,
  CustomTable,
  CustomTableRow,
  CustomTableBody,
  CustomTableCell,
} from "../../../custom-mui-style/custom-mui-styles";

import { Paper } from "@mui/material";

const TourRecGrapth = () => {
  const [data, setData] = useState(null);
  const [allsourcesdata, setallsourcesdata] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const artist = useSelector((state) => state.artist.artist);

  async function drawChart(artistData) {
    setIsLoading(true);

    if (!artistData) return;

    const country_data_resp = await axios.get(
      `${URLconfig.BASE_URL}/audience/${artist?.spotify_id}`
    );
    const dataset = country_data_resp?.data?.data?.audience;
    if (!country_data_resp || country_data_resp?.data?.data.length == 0) {
      axios
        .post(
          `${URLconfig.BASE_URL}/songstats/stats/audience/${artist?.spotify_id}`
        )
        .then((res) => {
          // Handle the response from the second API call
          // ...
        })
        .catch((error) => {
          console.log("second API error", error);
        });
    }
    const combinedData = {};
    const totalsp_listeners = {};
    const totalyt_views = {};
    const totaltk_followers = {};
    const soundcloud_followers = {};
    if (dataset) {
      for (const entry of dataset) {
        const source = entry.source;

        if (!combinedData.hasOwnProperty(source)) {
          combinedData[source] = [];
        }

        if (source === "spotify") {
          for (const item of entry.data.monthly_listeners) {
            const country = item.country_code;
            const current_listeners = item.current_listeners;

            // If the country code is not in the object, add it
            if (!totalsp_listeners.hasOwnProperty(country)) {
              totalsp_listeners[country] = 0;
            }
            totalsp_listeners[country] += current_listeners;
          }
          for (const country in totalsp_listeners) {
            if (totalsp_listeners.hasOwnProperty(country)) {
              const totalListeners = totalsp_listeners[country];
              combinedData[source].push({
                country_code: country,
                total_listeners: totalListeners,
              });
            }
          }
        } else if (source === "tiktok") {
          for (const item of entry.data.followers) {
            const country = item.country_code;
            const followers_total = item.followers_total;

            // If the country code is not in the object, add it
            if (!totaltk_followers.hasOwnProperty(country)) {
              totaltk_followers[country] = 0;
            }
            totaltk_followers[country] += followers_total;
          }
          for (const country in totaltk_followers) {
            if (totaltk_followers.hasOwnProperty(country)) {
              const followers_total = totaltk_followers[country];
              combinedData[source].push({
                country_code: country,
                followers_total: followers_total,
              });
            }
          }
        } else if (source === "youtube") {
          for (const item of entry.data.views) {
            const country = item.country_code;
            const current_views = item.current_views;

            // If the country code is not in the object, add it
            if (!totalyt_views.hasOwnProperty(country)) {
              totalyt_views[country] = 0;
            }
            totalyt_views[country] += current_views;
          }
          for (const country in totalyt_views) {
            if (totalyt_views.hasOwnProperty(country)) {
              const totalListeners = totalyt_views[country];
              combinedData[source].push({
                country_code: country,
                total_views: totalListeners,
              });
            }
          }
        } else if (source === "soundcloud") {
          for (const item of entry.data.followers) {
            const country = item.country_code;
            const total_followers = item.followers_total;

            // If the country code is not in the object, add it
            if (!soundcloud_followers.hasOwnProperty(country)) {
              soundcloud_followers[country] = 0;
            }
            soundcloud_followers[country] += total_followers;
          }
          for (const country in soundcloud_followers) {
            if (soundcloud_followers.hasOwnProperty(country)) {
              const total_followers = soundcloud_followers[country];
              combinedData[source].push({
                country_code: country,
                total_followers: total_followers,
              });
            }
          }
        }
      }
      const spotifyData = combinedData?.spotify || [];
      const youtubeData = combinedData?.youtube || [];
      const tiktokData = combinedData?.tiktok || [];
      const soundcloudData = combinedData?.soundcloud || [];

      const combinedArray = [];

      for (let i = 0; i < spotifyData.length; i++) {
        const countryData = {
          country_code: spotifyData[i].country_code,
          total_listeners: spotifyData[i]?.total_listeners || 0,
          total_views: youtubeData[i]?.total_views || 0,
          followers_total: tiktokData[i]?.followers_total || 0,
          soundcloud_followers: soundcloudData[i]?.total_followers || 0,
        };
        combinedArray.push(countryData);
      }
      setallsourcesdata(combinedArray);
      setIsLoading(false);
      let total_current_listner_US = 0;
      let total_current_listner_RU = 0;
      let total_current_listner_FR = 0;
      let total_current_listner_DE = 0;
      let total_current_listner_GB = 0;
      let total_current_listner_AU = 0;
      let total_current_listner_FI = 0;
      let total_current_listner_NO = 0;
      let total_current_listner_IE = 0;
      let total_current_listner_SE = 0;
      let total_current_listner_MX = 0;
      let total_current_listner_BR = 0;
      let total_current_listner_TR = 0;
      let total_current_listner_PL = 0;
      let total_current_listner_ES = 0;
      let total_current_listner_CA = 0;
      let total_current_listner_IN = 0;
      let total_current_listner_PK = 0;
      let total_current_listner_NG = 0;
      let total_current_listner_CB = 0;
      let total_current_listner_CN = 0;

      for (
        var i = 0;
        i <
        country_data_resp?.data?.data?.audience[0]?.data?.monthly_listeners
          .length;
        i++
      ) {
        let country_code =
          country_data_resp?.data?.data?.audience[0]?.data?.monthly_listeners[i]
            .country_code;
        let current_listenrs =
          country_data_resp?.data?.data?.audience[0]?.data?.monthly_listeners[i]
            .current_listeners;
        if (country_code === "US") {
          total_current_listner_US += current_listenrs;
        } else if (country_code === "RU") {
          total_current_listner_RU += current_listenrs;
        } else if (country_code === "FR") {
          total_current_listner_FR += current_listenrs;
        } else if (country_code === "DE") {
          total_current_listner_DE += current_listenrs;
        } else if (country_code === "GB") {
          total_current_listner_GB += current_listenrs;
        } else if (country_code === "AU") {
          total_current_listner_AU += current_listenrs;
        } else if (country_code === "FI") {
          total_current_listner_FI += current_listenrs;
        } else if (country_code === "NO") {
          total_current_listner_NO += current_listenrs;
        } else if (country_code === "IE") {
          total_current_listner_IE += current_listenrs;
        } else if (country_code === "SE") {
          total_current_listner_SE += current_listenrs;
        } else if (country_code === "MX") {
          total_current_listner_MX += current_listenrs;
        } else if (country_code === "BR") {
          total_current_listner_BR += current_listenrs;
        } else if (country_code === "TR") {
          total_current_listner_TR += current_listenrs;
        } else if (country_code === "PL") {
          total_current_listner_PL += current_listenrs;
        } else if (country_code === "ES") {
          total_current_listner_ES += current_listenrs;
        } else if (country_code === "CA") {
          total_current_listner_CA += current_listenrs;
        } else if (country_code === "IN") {
          total_current_listner_IN += current_listenrs;
        } else if (country_code === "PK") {
          total_current_listner_PK += current_listenrs;
        } else if (country_code === "CB") {
          total_current_listner_CB += current_listenrs;
        } else if (country_code === "NG") {
          total_current_listner_NG += current_listenrs;
        } else if (country_code === "FR") {
          total_current_listner_FR += current_listenrs;
        } else if (country_code === "CN") {
          total_current_listner_CN += current_listenrs;
        }

        const payload = [
          {
            country_code: "US",
            listners: total_current_listner_US,
          },
          {
            country_code: "UK",
            listners: total_current_listner_GB,
          },
          {
            country_code: "CA",
            listners: total_current_listner_CA,
          },
          {
            country_code: "AU",
            listners: total_current_listner_AU,
          },
          {
            country_code: "PK",
            listners: total_current_listner_PK,
          },
          {
            country_code: "IN",
            listners: total_current_listner_IN,
          },
          {
            country_code: "NG",
            listners: total_current_listner_NG,
          },
          {
            country_code: "CB",
            listners: total_current_listner_CB,
          },
          {
            country_code: "FR",
            listners: total_current_listner_FR,
          },
          {
            country_code: "CN",
            listners: total_current_listner_CN,
          },
          {
            country_code: "ES",
            listners: total_current_listner_ES,
          },
          {
            country_code: "DE",
            listners: total_current_listner_DE,
          },
        ];

        setData(payload);
      }
    }
  }

  useEffect(() => {
    drawChart(artist);
  }, [artist]);

  return (
    <Box component="div" variant="div" className={classes.page}>
      <Box component="div" variant="div" className={classes.page__banner}>
        <span className={classes.page__banner__topheading}>Top Countries</span>

        {isLoading ? (
          Array.from({ length: 3 }).map((_, index) => (
            <SkeletonLoaderComponent />
          ))
        ) : (
          <CountriesListCompopnent allsourcesdata={allsourcesdata} />
        )}
      </Box>
    </Box>
  );
};
export default TourRecGrapth;

const SkeletonLoaderComponent = () => {
  return (
    <CustomTableContainer className={classes.page__container}>
      <CustomTable>
        <CustomTable>
          {Array.from(new Array(3)).map((_, index) => (
            <React.Fragment key={index}>
              <Box sx={{ margin: "1rem" }} />
              <CustomTableRow
                sx={{
                  backgroundColor: "transparent !important",
                  border: "none !important",
                }}
              >
                <CustomTableCell
                  sx={{
                    display: "flex",
                    alignItems: "center",

                    backgroundColor:
                      "var(--base-inner-container-background-color) !important",
                    borderTopLeftRadius: "12px !important",
                    borderTopRightRadius: "12px !important",
                    borderBottomLeftRadius: "12px !important",
                    borderBottomRightRadius: "12px !important",
                  }}
                >
                  <Skeleton variant="circular" width={50} height={50} />
                  <Box sx={{ ml: 2 }}>
                    <Skeleton width="70%" />
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mt: 1,
                        gap: 2,
                      }}
                    >
                      <Skeleton variant="rectangular" width={24} height={24} />
                      <Skeleton width={60} />
                      <Skeleton variant="rectangular" width={24} height={24} />
                      <Skeleton width={60} />
                      <Skeleton variant="rectangular" width={24} height={24} />
                      <Skeleton width={60} />
                    </Box>
                  </Box>
                </CustomTableCell>
              </CustomTableRow>
            </React.Fragment>
          ))}
        </CustomTable>
      </CustomTable>
    </CustomTableContainer>
  );
};

const IconWithValue = ({ icon, value, subHeading }) => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Avatar
            src={icon}
            alt=""
            sx={{ width: 20, height: 20, marginRight: "10px" }}
          />
        </Box>
        <Box>
          <Typography className={classes.page__countrySubHeading}>
            {value}
          </Typography>
          <Typography className={classes.page__countrySubHeading2}>
            {subHeading}
          </Typography>
        </Box>
      </Box>
      {/* <Typography className={classes.page__countrySubHeading2}>
        {subHeading}
      </Typography> */}
    </Box>
  );
};

const CountriesListCompopnent = ({ allsourcesdata }) => {
  return (
    <CustomTableContainer component={Paper} className={classes.page__container}>
      <CustomTable stickyHeader aria-label="sticky table">
        <CustomTableBody>
          {allsourcesdata
            .sort((a, b) => b.total_listeners - a.total_listeners)
            .map((row, index) => (
              <React.Fragment key={index}>
                <Box sx={{ margin: "1rem" }} />
                <CustomTableRow
                  sx={{
                    backgroundColor: "transparent !important",
                    border: "none !important",
                  }}
                >
                  <CustomTableCell
                    sx={{
                      display: "flex",
                      alignItems: "center",

                      backgroundColor:
                        "var(--base-inner-container-background-color) !important",

                      borderTopLeftRadius: "12px !important",
                      borderTopRightRadius: "12px !important",
                      borderBottomLeftRadius: "12px !important",
                      borderBottomRightRadius: "12px !important",
                    }}
                  >
                    <ReactCountryFlag
                      countryCode={row.country_code}
                      svg
                      style={{
                        width: 50,
                        height: 50,
                        borderRadius: "50%",
                        objectFit: "cover",
                        marginRight: "40px",
                      }}
                    />
                    <Box>
                      <Typography className={classes.page__countryTopHeading}>
                        {countries[row.country_code].name}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                          gap: 5,
                        }}
                      >
                        {row.total_listeners > 0 ? (
                          <IconWithValue
                            icon={SpotifyIcon}
                            value={addCommasToNumber(row.total_listeners)}
                            subHeading={"Monthly Listners"}
                          />
                        ) : null}

                        {row.total_views > 0 ? (
                          <IconWithValue
                            icon={YoutubeIcon}
                            value={addCommasToNumber(row.total_views)}
                            subHeading={"Views"}
                          />
                        ) : null}

                        {row.followers_total > 0 ? (
                          <IconWithValue
                            icon={TiktokIcon}
                            value={addCommasToNumber(row.followers_total)}
                            subHeading={"Followers"}
                          />
                        ) : null}

                        {row.soundcloud_followers > 0 ? (
                          <IconWithValue
                            icon={DeezeerIcon}
                            value={addCommasToNumber(row.soundcloud_followers)}
                            subHeading={"Followers"}
                          />
                        ) : null}
                      </Box>
                    </Box>
                  </CustomTableCell>
                </CustomTableRow>
              </React.Fragment>
            ))}
        </CustomTableBody>
      </CustomTable>
    </CustomTableContainer>
  );
};
