import React, { useEffect, useState } from "react";
import classess from "./style.module.scss";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Circle from "@mui/icons-material/Circle";
import axios from "axios";
import { config as URLconfig } from "../../enviorment/enviorment";
import { useNavigate } from "react-router-dom";
import AuthEnum from "../../enums/auth.enum";
import { getItemToLocalStorage } from "../../services/storage";
import { IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";

import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import Skeleton from "@mui/material/Skeleton";
import EyeIcon from "../../assets/buttonsicons/EyeIcon.png";
import SearchIcon from "@mui/icons-material/Search";

import {
  CustomTableContainer,
  CustomTable,
  CustomTableBody,
  CustomTableCell,
  CustomTableHead,
  CustomTableRow,
  CustomTableCellHeader,
} from "../../custom-mui-style/custom-mui-styles";

const ArtistContractList = () => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    let isSubscribed = true;
    if (!dataFetched) {
      axios({
        url: `${URLconfig.BASE_URL}/contracts`,
        method: "GET",
        headers: {
          authorization: `Bearer ${getItemToLocalStorage(AuthEnum.TOKEN)}`,
        },
      })
        .then((response) => {
          if (isSubscribed) {
            setIsLoading(false);
            setList(response.data.data);
            setDataFetched(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    return () => {
      isSubscribed = false;
    };
  }, [dataFetched]);

  return (
    <Box className={classess.page}>
      <Box className={classess.page__container}>
        <Box
          varient="div"
          component="div"
          className={classess.page__container__header}
        >
          <span className={classess.page__container__header__title}>
            My Contracts
          </span>

          <Box
            varient="div"
            component="div"
            className={classess.page__container__header__search}
          >
            <SearchIcon
              className={classess.page__container__header__search__seaarch_icon}
            />
            <input
              className={classess.page__container__header__search__input}
              placeholder="Search"
              type="search"
              // onInput={(e) => handleSearch(e)}
              required
            />
          </Box>
        </Box>
        <Box
          component="div"
          varient="div"
          className={classess.page__container__list}
        >
          {isLoading ? (
            <SkeletonLoaderComponent />
          ) : (
            <ContractTableComponent list={list} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ArtistContractList;

const SkeletonLoaderComponent = () => {
  return (
    <CustomTableContainer className={classess.page__container__list__table}>
      <CustomTable stickyHeader aria-label="sticky table">
        <CustomTableHead>
          <CustomTableRow>
            <CustomTableCellHeader />
            <CustomTableCellHeader>ARTIST NAME</CustomTableCellHeader>
            <CustomTableCellHeader>CREATED ON</CustomTableCellHeader>
            <CustomTableCellHeader>SUBMITTED BY</CustomTableCellHeader>
            <CustomTableCellHeader>STATUS</CustomTableCellHeader>
            <CustomTableCellHeader>ACTION</CustomTableCellHeader>
          </CustomTableRow>
        </CustomTableHead>
        <CustomTableBody>
          {Array.from({ length: 7 }).map((_, index) => (
            <React.Fragment key={index}>
              <Box sx={{ m: "1rem" }} />
              <CustomTableRow>
                <CustomTableCell
                  sx={{
                    borderTopLeftRadius: "12px",
                    borderBottomLeftRadius: "12px",
                  }}
                >
                  <Skeleton variant="circular" width={40} height={40} />
                </CustomTableCell>
                <CustomTableCell>
                  <Skeleton variant="text" fontSize="1rem" />
                </CustomTableCell>
                <CustomTableCell>
                  <Skeleton variant="text" fontSize="1rem" />
                </CustomTableCell>
                <CustomTableCell>
                  <Skeleton variant="text" fontSize="1rem" />
                </CustomTableCell>
                <CustomTableCell>
                  <Skeleton variant="text" fontSize="1rem" />
                </CustomTableCell>
                <CustomTableCell
                  sx={{
                    borderTopRightRadius: "12px",
                    borderBottomRightRadius: "12px",
                  }}
                >
                  <Skeleton variant="text" fontSize="1rem" />
                </CustomTableCell>
              </CustomTableRow>
            </React.Fragment>
          ))}
        </CustomTableBody>
      </CustomTable>
    </CustomTableContainer>
  );
};

const ContractTableComponent = ({ list }) => {
  const navigate = useNavigate();

  return (
    <CustomTableContainer className={classess.page__container__list__table}>
      <CustomTable stickyHeader aria-label="sticky table">
        <CustomTableHead>
          <CustomTableRow>
            <CustomTableCellHeader />
            <CustomTableCellHeader>ARTIST NAME</CustomTableCellHeader>
            <CustomTableCellHeader>CREATED ON</CustomTableCellHeader>
            <CustomTableCellHeader>SUBMITTED BY</CustomTableCellHeader>
            <CustomTableCellHeader>STATUS</CustomTableCellHeader>
            <CustomTableCellHeader>ACTION</CustomTableCellHeader>
          </CustomTableRow>
        </CustomTableHead>

        <CustomTableBody>
          {list[0] === undefined ? (
            <span
              className={classess.page__container__list__table__no_contracts}
            >
              No Contract
            </span>
          ) : (
            list.map((row, index) => (
              <React.Fragment>
                <Box sx={{ m: "1rem" }} />
                <CustomTableRow>
                  <CustomTableCell
                    sx={{
                      maxWidth: {
                        xs: 150,
                        lg: 50,
                        sm: 150,
                      },
                      // maxWidth: 40,
                      borderTopLeftRadius: "12px",
                      borderBottomLeftRadius: "12px",
                    }}
                  >
                    <Avatar
                      src={row?.artist_image}
                      alt={row?.name}
                      className={
                        classess.page__container__list__table__row__artist_image
                      }
                    />
                  </CustomTableCell>

                  <CustomTableCell
                    sx={{
                      maxWidth: 150,
                    }}
                  >
                    <Tooltip
                      title={row?.artist_name}
                      placement="top"
                      arrow
                      enterDelay={100}
                    >
                      <span
                        className={
                          classess.page__container__list__table__row__artistname
                        }
                      >
                        <a
                          className={
                            classess.page__container__list__table__row__artistname__href
                          }
                          href={"/blig/view-artist/" + row?.artist_id}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {row?.artist_name}
                        </a>
                      </span>
                    </Tooltip>
                  </CustomTableCell>

                  <CustomTableCell>
                    <span
                      className={
                        classess.page__container__list__table__row__date
                      }
                    >
                      {new Date(row?.createdAt).toLocaleDateString({
                        weekday: "short",
                        year: "numeric",
                        month: "2-digit",
                        day: "numeric",
                      })}
                    </span>
                  </CustomTableCell>

                  <CustomTableCell>
                    <span
                      className={
                        classess.page__container__list__table__row__rep_name
                      }
                    >
                      {row.artist_representative_name}
                    </span>
                  </CustomTableCell>

                  <CustomTableCell sx={{ maxWidth: 100 }}>
                    <span
                      className={
                        classess.page__container__list__table__row__status
                      }
                    >
                      <Chip
                        variant="outlined"
                        icon={
                          <Circle
                            sx={{
                              fill:
                                row?.status === "PENDING" ||
                                row?.status === "Approved"
                                  ? "green"
                                  : "Orange",
                              fontSize: "14px",
                            }}
                          />
                        }
                        label={row?.status}
                        sx={{ color: "#fff", borderColor: "transparent" }}
                      />
                    </span>
                  </CustomTableCell>

                  <CustomTableCell
                    sx={{
                      maxWidth: "50px",
                      borderBottomRightRadius: "12px",
                      borderTopRightRadius: "12px",
                    }}
                  >
                    <Tooltip
                      title={`View ${row?.artist_name} Contracts`}
                      placement="top"
                      arrow
                      enterDelay={150}
                    >
                      <IconButton
                        style={{
                          backgroundColor: "var(--base-action-icon-background)",
                          height: "30px",
                          width: "30px",
                        }}
                        onClick={() => navigate(`/blig/contracts/${row._id}`)}
                      >
                        <img
                          src={EyeIcon}
                          alt="Eye"
                          style={{ height: "12px", width: "16px" }}
                        />
                        {/* <VisibilityIcon
                      style={{
                        color: "#000",
                        height: "15px",
                        width: "15px",
                      }}
                      className={classess.page__table__row__icon}
                    /> */}
                      </IconButton>
                    </Tooltip>
                  </CustomTableCell>
                </CustomTableRow>
              </React.Fragment>
            ))
          )}
        </CustomTableBody>
      </CustomTable>
    </CustomTableContainer>
  );
};
