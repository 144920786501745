/* eslint-disable no-useless-concat */
import React, { useState } from "react";
import classess from "./style.module.scss";
import Box from "@mui/material/Box";
import { IconButton } from "@mui/material";
import Stack from "@mui/material/Stack";
import docicon from "../../assets/msword.png";
import { getItemToLocalStorage } from "../../services/storage";
import AuthEnum from "../../enums/auth.enum";
import { config as URLconfig } from "../../enviorment/enviorment";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import FileDownload from "js-file-download";
import moment from "moment";
import closebutton from "../../assets/closeBtn.png";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Moment from "react-moment";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import DownloadIcon from "../../assets/buttonsicons/DownloadIcon.png";
import CheckIcon from "../../assets/buttonsicons/CheckIcon.png";
import AddNotesIcon from "../../assets/buttonsicons/AddNotesIcon.png";
import { useSelector } from "react-redux";

import {
  CustomTableContainer,
  CustomTable,
  CustomTableBody,
  CustomTableCell,
  CustomTableHead,
  CustomTableRow,
  CustomTableCellHeader,
} from "../../custom-mui-style/custom-mui-styles";

const ContractHistory = ({
  props,
  open,
  onvaluechange,
  contract,
  updateNotes,
  // updateContract,
}) => {
  const { id } = useParams();
  const [note, setNote] = useState("");
  const [toggleTab, setToggleTab] = useState(false);
  const [openPanel, setOpenPanel] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [documentData] = useState([]);
  const user = useSelector((state) => state.auth.user);

  const handleClose = () => {
    onvaluechange(false);
    setNote("");
  };

  const handleToggle = (id) => {
    setToggleTab(toggleTab === id ? null : id);
  };

  function convertHtmlToDoc(contract_info) {
    const data = {
      name: contract_info?.artist_name,
      email: contract_info?.artist_email,
      legal_name: contract_info.legel_name,
      address: contract_info.recipient_address,
      city: contract_info.city,
      country: contract_info.country,
      zip_code: contract_info.zip_code,
      contract_length: contract_info.contract_length,
      selected_tracks: contract_info?.Selected_tracks,
      document: contract_info.document || documentData,
    };
    axios({
      url: `${URLconfig.BASE_URL}/contract-gen/convert`,
      data,
      method: "POST",
      responseType: "blob",
      headers: {
        authorization: `Bearer ${getItemToLocalStorage(AuthEnum.TOKEN)}`,
      },
    })
      .then((response) => {
        const reader = new FileReader();
        reader.onload = () => {
          const base64String = reader.result.split(",")[1];
          const data2 = {
            name: contract_info?.artist_name,
            email: contract_info?.artist_email,
            sender_name: contract_info?.artist_representative_name,
            sender_email: contract_info?.artist_representative_email,
            file: base64String,
          };
          sendContract(data2);
        };
        reader.readAsDataURL(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function download_first_contract(contract_info) {
    const data = {
      name: contract_info?.artist_name,
      email: contract_info?.artist_email,
      legal_name: contract_info.legel_name,
      address: contract_info.recipient_address,
      city: contract_info.city,
      country: contract_info.country,
      zip_code: contract_info.zip_code,
      contract_length: contract_info.contract_length,
      selected_tracks: contract_info?.Selected_tracks,
      createdAt: contract_info?.createdAt,
      document: contract_info?.document,
    };
    axios({
      url: `${URLconfig.BASE_URL}/contract-gen/convert`,
      data,
      method: "POST",
      responseType: "blob",
      headers: {
        authorization: `Bearer ${getItemToLocalStorage(AuthEnum.TOKEN)}`,
      },
    })
      .then((response) => {
        FileDownload(
          response.data,
          `Contract_agreement_${contract_info?.artist_name}.docx`
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const sendContract = async (data) => {
    const { name, email, file, sender_name, sender_email } = data;
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_API}` + "/docu-sign",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: JSON.stringify({
            name,
            email,
            file,
            sender_name,
            sender_email,
          }),
        }
      );
      const data = await res.json();
      if (res.status === 200) {
        toast.success(`${data.message}`);
      } else {
        toast.error(`${data.message}`);
      }
    } catch (e) {
      toast.error("Something went wrong");
    }
  };

  const eventHandler = (id) => {
    const data = {
      status: "Approved",
    };
    axios({
      url: `${URLconfig.BASE_URL}/contracts/${id}`,
      method: "PUT",
      data: data,
      headers: {
        authorization: `Bearer ${getItemToLocalStorage(AuthEnum.TOKEN)}`,
      },
    })
      .then((response) => {
        convertHtmlToDoc(response.data.data);
        toast.success(`${response.data.message}`);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const noteHandler = (id) => {
    const formData = new FormData();
    formData.append("note", note);
    formData.append("file", selectedFile);
    if (!selectedFile) {
      toast.warning("Please upload your contract file.");
    } else {
      let config = {
        headers: {
          authorization: `Bearer ${getItemToLocalStorage(AuthEnum.TOKEN)}`,
        },
      };

      axios
        .post(`${URLconfig.BASE_URL}/contracts/${id}/notes`, formData, config)
        .then((res) => {
          setNote("");
          setSelectedFile(null);
          onvaluechange(false);
          toast.success("Revision uploaded successfully");
          updateNotes(res?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const handleDownload = (fileKey) => {
    const link = document.createElement("a");
    link.href = fileKey;
    link.setAttribute("download", "");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <Box container spacing={2} className={classess.page}>
      <ContractsListComponent
        contract={contract}
        download_first_contract={download_first_contract}
        setOpenPanel={setOpenPanel}
        openPanel={openPanel}
        user={user}
        eventHandler={eventHandler}
        id={id}
        handleDownload={handleDownload}
        handleToggle={handleToggle}
        toggleTab={toggleTab}
      />

      <AddRevisionModalComponent
        open={open}
        handleClose={handleClose}
        selectedFile={selectedFile}
        note={note}
        setNote={setNote}
        setSelectedFile={setSelectedFile}
        noteHandler={noteHandler}
        id={id}
      />
    </Box>
  );
};

export default ContractHistory;

const ContractsListComponent = ({
  contract,
  download_first_contract,
  setOpenPanel,
  openPanel,
  user,
  eventHandler,
  id,
  handleDownload,
  handleToggle,
  toggleTab,
}) => {
  return (
    <CustomTableContainer className={classess.page__table}>
      <CustomTable stickyHeader={true} aria-label="sticky table">
        <CustomTableHead>
          <CustomTableRow>
            <CustomTableCellHeader />
            <CustomTableCellHeader>TITLE</CustomTableCellHeader>
            <CustomTableCellHeader>DATE</CustomTableCellHeader>
            <CustomTableCellHeader>UPLOADED BY</CustomTableCellHeader>
            <CustomTableCellHeader>VERSION</CustomTableCellHeader>
            <CustomTableCellHeader />
          </CustomTableRow>
        </CustomTableHead>

        <CustomTableBody>
          <React.Fragment>
            <Box sx={{ m: "1rem" }} />
            <CustomTableRow>
              <CustomTableCell
                sx={{
                  borderTopLeftRadius: "12px",
                  borderBottomLeftRadius: "12px",
                }}
              >
                <span className={classess.page__table__row__artistname}>
                  <img src={docicon} alt="doc" width="30" />
                </span>
              </CustomTableCell>

              <CustomTableCell>
                <Box className={classess.page__table__row__filename}>
                  {"Contract_agreement " + contract?.artist_name}
                </Box>
              </CustomTableCell>

              <CustomTableCell>
                <span className={classess.page__table__row__date}>
                  {moment(contract?.createdAt).format("MMM-DD-YYYY")}
                </span>
              </CustomTableCell>

              <CustomTableCell>
                <span className={classess.page__table__row__rep_name}>
                  {contract?.artist_representative_name}
                </span>
              </CustomTableCell>

              <CustomTableCell>
                <span className={classess.page__table__row__version}>
                  {"1.0"}
                </span>
              </CustomTableCell>

              <CustomTableCell
                sx={{
                  borderTopRightRadius: "12px",
                  borderBottomRightRadius: "12px",
                }}
              >
                <span className={classess.page__table__row__action2}>
                  <Stack direction="row" spacing={1}>
                    <Tooltip
                      title="Download Contract File"
                      placement="top"
                      arrow
                      enterDelay={100}
                    >
                      <IconButton
                        style={{
                          backgroundColor: "var(--base-action-icon-background)",
                          height: "30px",
                          width: "30px",
                        }}
                        onClick={() => download_first_contract(contract)}
                      >
                        <img
                          src={DownloadIcon}
                          alt="Eye"
                          style={{ height: "12px", width: "16px" }}
                        />
                      </IconButton>
                    </Tooltip>

                    <Tooltip
                      title="Add Revisions First"
                      placement="top"
                      arrow
                      enterDelay={100}
                    >
                      <IconButton
                        variant=""
                        onClick={() => setOpenPanel(!openPanel)}
                        style={{
                          backgroundColor: "var(--base-action-icon-background)",

                          height: "30px",
                          width: "30px",
                          opacity: "0.1",
                        }}
                      >
                        <img
                          src={AddNotesIcon}
                          alt="Eye"
                          style={{ height: "14px", width: "16px" }}
                        />
                      </IconButton>
                    </Tooltip>
                    {user.role === "admin" && (
                      <Tooltip
                        title="Contract Approve"
                        placement="top"
                        arrow
                        enterDelay={100}
                      >
                        <IconButton
                          variant=""
                          onClick={() => eventHandler(id)}
                          style={{
                            backgroundColor:
                              "var(--base-action-icon-background)",

                            height: "30px",
                            width: "30px",
                          }}
                        >
                          <img
                            src={CheckIcon}
                            alt="Eye"
                            style={{ height: "12px", width: "16px" }}
                          />
                          {/* <CheckOutlinedIcon
                        fontSize="small"
                        style={{
                          color: "#000000",
                          height: "15px",
                          width: "15px",
                        }}
                      /> */}
                        </IconButton>
                      </Tooltip>
                    )}
                  </Stack>
                </span>
              </CustomTableCell>
            </CustomTableRow>
          </React.Fragment>
        </CustomTableBody>

        {Array.isArray(contract?.notes) &&
          contract?.notes
            // Sort in ascending order
            .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
            .map((data, key) => {
              return (
                <>
                  <>
                    <Box sx={{ m: "1rem" }}></Box>
                  </>
                  <CustomTableBody>
                    <CustomTableRow>
                      <CustomTableCell
                        sx={{
                          borderTopLeftRadius: "12px",
                          borderBottomLeftRadius: "12px",
                        }}
                      >
                        <span className={classess.page__table__row__artistname}>
                          <img src={docicon} alt="doc" width="30" />
                        </span>
                      </CustomTableCell>

                      <CustomTableCell>
                        <Box className={classess.page__table__row__filename}>
                          {data.fileKey}
                        </Box>
                      </CustomTableCell>

                      <CustomTableCell>
                        <span className={classess.page__table__row__date}>
                          {moment(data.createdAt).format("MMM-DD-YYYY")}
                        </span>
                      </CustomTableCell>

                      <CustomTableCell>
                        <span className={classess.page__table__row__rep_name}>
                          {data.user?.firstName + " " + data.user?.lastName}
                        </span>
                      </CustomTableCell>

                      <CustomTableCell>
                        <span className={classess.page__table__row__version}>
                          {"1." + (key + 1)}
                        </span>
                      </CustomTableCell>

                      <CustomTableCell
                        sx={{
                          borderTopRightRadius: "12px",
                          borderBottomRightRadius: "12px",
                        }}
                      >
                        <span className={classess.page__table__row__action2}>
                          <Stack direction="row" spacing={1}>
                            <Tooltip
                              title="Download Contract File"
                              placement="top"
                              arrow
                              enterDelay={100}
                            >
                              <IconButton
                                variant=""
                                style={{
                                  backgroundColor:
                                    "var(--base-action-icon-background)",
                                  height: "30px",
                                  width: "30px",
                                }}
                                onClick={() => handleDownload(data?.file)}
                              >
                                <img
                                  src={DownloadIcon}
                                  alt="Eye"
                                  style={{ height: "12px", width: "16px" }}
                                />
                              </IconButton>
                            </Tooltip>

                            <Tooltip
                              title="Open Comment Panel"
                              placement="top"
                              arrow
                              enterDelay={100}
                            >
                              <IconButton
                                variant=""
                                onClick={() => handleToggle(key)}
                                style={{
                                  backgroundColor:
                                    "var(--base-action-icon-background)",
                                  height: "30px",
                                  width: "30px",
                                }}
                              >
                                <img
                                  src={AddNotesIcon}
                                  alt="Eye"
                                  style={{ height: "14px", width: "16px" }}
                                />
                              </IconButton>
                            </Tooltip>

                            {user.role === "admin" && (
                              <Tooltip
                                title="Contract Approve"
                                placement="top"
                                arrow
                                enterDelay={100}
                              >
                                <IconButton
                                  variant=""
                                  onClick={() => eventHandler(id)}
                                  style={{
                                    backgroundColor:
                                      "var(--base-action-icon-background)",
                                    height: "30px",
                                    width: "30px",
                                  }}
                                >
                                  <img
                                    src={CheckIcon}
                                    alt="Eye"
                                    style={{ height: "12px", width: "16px" }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Stack>
                        </span>
                      </CustomTableCell>
                    </CustomTableRow>

                    {toggleTab === key && (
                      <CustomTableRow>
                        <CustomTableCell colSpan={6}>
                          <Grid
                            container
                            mb={2}
                            key={data._id}
                            sx={{
                              position: "relative",
                            }}
                          >
                            <Grid
                              sm
                              className={`${classess.page__notes__notebox} ${classess.note}`}
                            >
                              <Box
                                className={classess.page__notes__notebox__chips}
                              >
                                <span>
                                  <Chip
                                    label="Note"
                                    variant="filled"
                                    className={
                                      classess.page__notes__notebox__chips__label_chip
                                    }
                                  />
                                </span>
                                <span>
                                  <Chip
                                    className={
                                      classess.page__notes__notebox__chips__moment_chip
                                    }
                                    label={
                                      <Moment fromNow ago>
                                        {data.createdAt}
                                      </Moment>
                                    }
                                    variant="filled"
                                  />
                                </span>
                              </Box>
                              <Box
                                className={
                                  classess.page__notes__notebox__notetext
                                }
                              >
                                <p>{data.note}</p>
                              </Box>
                            </Grid>
                          </Grid>
                        </CustomTableCell>
                      </CustomTableRow>
                    )}
                  </CustomTableBody>
                </>
              );
            })}
      </CustomTable>
    </CustomTableContainer>
  );
};

const AddRevisionModalComponent = ({
  open,
  handleClose,
  selectedFile,
  note,
  setNote,
  setSelectedFile,
  noteHandler,
  id,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      // keepMounted
    >
      <Box className={classess.modalbox}>
        <Grid container>
          <Grid item sm={11}>
            <span className={classess.modalbox__top_heading}>
              Please add your notes:{" "}
              {selectedFile && <span>Selected file: {selectedFile.name}</span>}
            </span>
          </Grid>
          <Grid item sm={1}>
            <Box
              varient="div"
              component="div"
              className={classess.modalbox__closebutton}
            >
              <img src={closebutton} alt="close" onClick={handleClose} />
            </Box>
          </Grid>
        </Grid>

        <TextField
          placeholder="Add comment"
          multiline="true"
          rows="5"
          className={classess.modalbox__textfield}
          value={note}
          onChange={(e) => setNote(e.target.value)}
          sx={{
            "& fieldset": {
              border: "1px solid var(--base-input-border-color)",
              borderRadius: "12px",
              transition: "all 0.3s ease-in-out",
            },
            "& .MuiOutlinedInput-root": {
              color: "#979797",
              "&.Mui-focused fieldset": {
                border: "1px solid var(--base-input-focus-color)",
              },
            },
            "&:hover fieldset": {
              border: "1px solid var(--base-input-focus-color) !important",
            },
          }}
        />
        <Box className={classess.modalbox__button}>
          <Box className={classess.modalbox__button__container}>
            <IconButton
              variant="contained"
              className={classess.modalbox__button__urev}
              component="label"
            >
              {/* {" "}
              <FileUploadOutlinedIcon /> */}
              Upload File
              <input
                type="file"
                accept=".doc,.docx"
                hidden
                onChange={(e) => setSelectedFile(e.target.files[0])}
              />
            </IconButton>

            <IconButton
              className={classess.modalbox__button__cmnt}
              variant="contained"
              onClick={() => noteHandler(id)}
            >
              Add Revision
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
