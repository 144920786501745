import React, { forwardRef, useImperativeHandle } from "react";
import { useState } from "react";
import classess from "./style.module.scss";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import axios from "axios";
import { toast } from "react-toastify";
import { config as configURL } from "../../enviorment/enviorment";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  FormControl,
  ListItemText,
  OutlinedInput,
  Typography,
} from "@mui/material";
import {
  CustomCheckbox,
  CustomEmailMenuItem,
  CustomEmailSelect,
} from "../../custom-mui-style/custom-mui-styles";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { getItemToLocalStorage } from "../../services/storage";
import AuthEnum from "../../enums/auth.enum";
const ContractSubmitPreview = forwardRef((props, ref) => {
  const contract = props.contract;
  const { updateSelectedEmails } = props;

  const [list, setList] = useState([]);
  const [personName, setPersonName] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handleChange = (event) => {
    if (!isValidEmail(event.target.value)) {
    } else {
    }
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };

  const getEmailFromName = (name) => {
    const foundItem = data.find((item) => item.name === name);
    return foundItem ? foundItem.email : "";
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleAddEmail = async (e) => {
    e.preventDefault();
    let emailArray = [];

    for (let i = 0; i < personName.length; i++) {
      const name = personName[i];
      const email = getEmailFromName(name);
      const emailObj = {
        name: name,
        email: email,
      };
      emailArray.push(emailObj);
    }

    setList(emailArray);
    updateSelectedEmails(emailArray);
  };

  useImperativeHandle(ref, () => ({
    async onSubmit() {
      if (list.length > 0) {
        try {
          await axios.post(
            `${configURL.BASE_URL}/contract-gen/send-email`,
            {
              recipients: list,
              artistname: props.contract?.artist_name,
              artist_representative_name:
                props.contract?.artist_representative_name,
            },
            {
              headers: {
                authorization: `Bearer ${getItemToLocalStorage(
                  AuthEnum.TOKEN
                )}`,
              },
            }
          );
          toast.success("Email sent successfully.");
        } catch (error) {
          toast.error("Something went wrong");
        }
      } else {
        toast.warning("Add at least one email.");
      }
    },
  }));
  const data = [
    {
      name: "Finance",
      email: "finance@blacklionapp.xyz",
    },
    {
      name: "Legal",
      email: "nouman@blacklionapp.xyz",
    },
    {
      name: "Management",
      email: "management@blacklionapp.xyz",
    },
    {
      name: "CEO",
      email: "ceo@blacklionapp.xyz ",
    },
  ];

  const handleOpenSelect = () => setIsOpen(true);
  const handleCloseSelect = () => setIsOpen(false);
  return (
    <Grid container spacing={2} className={classess.page}>
      <Grid item sm={12} lg={12} xl={12} className={classess.page__details}>
        <Box
          varient="div"
          component="div"
          className={classess.page__details__box}
        >
          <Box
            varient="div"
            component="div"
            className={classess.page__details__box__tracks}
          >
            <Box
              varient="div"
              component="div"
              className={classess.page__details__box__tracks__header}
            ></Box>
            <form action="" className={classess.page__fieldsContainer__form}>
              <Grid container spacing={4} rowSpacing={4}>
                <Grid item md={12} lg={12} sm={12} xs={12}>
                  <Box
                    varient="div"
                    component="div"
                    className={classess.page__fieldsContainer__form__formfield}
                    // sx={{ width: "100%" }}
                  >
                    {list.map((el, index) => (
                      <Grid
                        item
                        md={12}
                        lg={12}
                        sm={12}
                        xs={12}
                        display={"flex"}
                      >
                        <input
                          className={
                            classess.page__fieldsContainer__form__formfield__input
                          }
                          name="name"
                          placeholder={el.name}
                          type="email"
                          onChange={(e) => {
                            // Update the email in the list
                            let newList = list.map((obj) => {
                              if (obj.email === el.email) {
                                return { ...obj, email: e.target.value };
                              }
                              return obj;
                            });
                            setList(newList);
                          }}
                          value={el.email}
                          defaultValue={el.email}
                          required
                          disabled={true}
                        />
                        {/* <div> */}
                        {/* <span> */}
                        <CancelIcon
                          className={
                            classess.page__fieldsContainer__form__formfield__remove
                          }
                          onClick={() => {
                            const updatedList = list.filter(
                              (_, selectedIndex) => selectedIndex !== index
                            );
                            setList(updatedList);
                            updateSelectedEmails(updatedList);
                          }}
                        />
                        {/* </span> */}
                        {/* </div> */}
                      </Grid>
                    ))}

                    <label
                      className={
                        classess.page__fieldsContainer__form__formfield__label
                      }
                    >
                      Email address
                    </label>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        // alignItems: "center",
                        gap: "20px",
                        width: "100%",
                      }}
                    >
                      <FormControl sx={{ width: "70%" }}>
                        <CustomEmailSelect
                          multiple
                          displayEmpty
                          value={personName}
                          onChange={handleChange}
                          renderValue={(selected) => {
                            if (selected.length === 0) {
                              return <>Select recipients for approval</>;
                            }
                            return selected.join(", ");
                          }}
                          MenuProps={MenuProps}
                          onOpen={handleOpenSelect}
                          onClose={handleCloseSelect}
                          IconComponent={() =>
                            isOpen ? (
                              <IoIosArrowUp
                                className={classess.page__select_icon}
                              />
                            ) : (
                              <IoIosArrowDown
                                className={classess.page__select_icon}
                              />
                            )
                          }
                        >
                          <CustomEmailMenuItem disabled value="">
                            <em>Select recipients for approval</em>
                          </CustomEmailMenuItem>
                          {data.map((item, index) => (
                            <CustomEmailMenuItem
                              key={index}
                              value={item.name}
                              className={
                                classess.page__fieldsContainer__form__list
                              }
                            >
                              <CustomCheckbox
                                checked={personName.indexOf(item.name) > -1}
                              />
                              <ListItemText
                                primary={item.name}
                                secondary={item.email}
                                sx={{ padding: "0px", color: "#fff" }}
                              />
                            </CustomEmailMenuItem>
                          ))}
                        </CustomEmailSelect>
                        {list && list.length === 0 && (
                          <Typography variant="caption" color="error">
                            Please select an email.
                          </Typography>
                        )}
                      </FormControl>

                      <Box
                        sx={{
                          width: "30%",
                        }}
                      >
                        <Button
                          className={
                            classess.page__fieldsContainer__form__formfield__button
                          }
                          type="submit"
                          onClick={handleAddEmail}
                        >
                          Add
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
});

export default ContractSubmitPreview;
