import React, { useEffect, useState } from "react";
import classess from "./style.module.scss";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { addArtist } from "../../../redux/slice/artist";
import { toast } from "react-toastify";
import LoadingButton from "@mui/lab/LoadingButton";
import { searchArtist } from "../../../api/spotify.api";
import SearchAutcomplete from "../../../components/search-autcomplete/search-autcomplete";
import Avatar from "@mui/material/Avatar";
import PhoneInput from "react-phone-number-input";
// import SpotifySvg from "../../../assets/spotify.svg";
import { _fetchToken } from "../../../../src/utils/spotifyApiServiceHandler";
import AuthEnum from "../../../enums/auth.enum";
import { getItemToLocalStorage } from "../../../services/storage";
import {
  Box,
  // MenuItem,
  Grid,
} from "@mui/material";
import {
  // CustomFormSelect,
  CustomMenuItem,
  CustomSelect,
  CustomTextField,
} from "../../../custom-mui-style/custom-mui-styles";
import { getCountries } from "../../../redux/slice/country";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useParams } from "react-router-dom";
import ArtistForm from "../../../components/artist-form/ArtistForm";

const AddArtist = () => {
  const { query } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [image, setImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [genres, setgenres] = useState([]);
  const [telephone, setTelephone] = useState("");
  const [country, setCountry] = useState("");
  const [spotify_id, setSpotify_id] = useState("");
  const [search, setSearch] = useState(query || "");
  const [searchedArtistList, setSearchedArtistList] = useState([]);
  const [spotifyDisabled, setSpotifyDisabled] = useState(false);
  const [manual, setManual] = useState(true);
  const allCountries = useSelector((state) => state.country.countries);
  const status = useSelector((state) => state.country.status);
  // const [isFocused, setIsFocused] = useState(false);

  const handleClose = () => {
    setManual(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!country) {
      toast.error("Country is required");
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    setSpotifyDisabled(false);
    const payload = {
      accessToken: `${getItemToLocalStorage(AuthEnum.TOKEN)}`,
      artist: {
        name,
        email,
        telephone,
        country,
        spotify_id,
        avatar: image,
        genres,
      },
    };

    let response = dispatch(
      addArtist({
        data: payload,
      })
    );

    response
      .then(async (res) => {
        if (res.payload.message === "artist created") {
          toast.message("New artist adding");
          navigate("/blig/my-artist");
        }
      })
      .catch((error) => {
        toast.error(error);
      })
      .finally(() => {
        setIsLoading(false);
        navigate("/blig/my-artist");
      });
  };

  const emptyFields = () => {
    setName("");
    setEmail("");
    setTelephone("");
    setCountry("");
    setSpotify_id("");
    setSearch("");
    setImage("");
    setSearchedArtistList("");
  };

  const mapSearchArtist = (item) => {
    let obj = {};

    obj.id = item?.id;
    obj.name = item?.name;
    obj.genres = item?.genres;

    if (item?.images && item?.images.length) {
      obj.image = item?.images[0].url;
    } else {
      obj.image = "";
    }

    return obj;
  };

  const fetchSpotifyToken = async () => {
    await _fetchToken();
  };

  const handleSearch = (event) => {
    const toSearch = event.target.value;
    navigate(`/blig/add-artist/${toSearch}`);
    setSearch(toSearch);
    setSpotifyDisabled(true);
    if (toSearch.length >= 3) {
      searchArtist(toSearch)
        .then((res) => {
          const artist = res.artists.items;
          setSearchedArtistList([...artist.map(mapSearchArtist)]);
        })
        .catch((err) => {
          console.log("Err: ", err);
          fetchSpotifyToken();
        });
    } else {
      setSearchedArtistList([]);
    }
  };

  const handleSearchItem = (artist) => {
    // setSearch("");
    // emptyFields();
    setSearchedArtistList([]);
    setImage(artist.image);
    setName(artist.name);
    setSpotify_id(artist.id);
    setgenres(artist.genres);
    setManual(false);
  };

  // const handleFocus = () => {
  //   setIsFocused(true);
  // };

  // const handleBlur = () => {
  //   setIsFocused(false);
  // };
  useEffect(() => {
    if (status === "idle") {
      dispatch(getCountries());
    }
  }, [dispatch, status]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Backspace") {
        if (
          event.target === document.activeElement &&
          window.getSelection().toString() === search
        ) {
          setSearch("");
          navigate(`/blig/add-artist/`);
        } else if (search.length <= 1) {
          const updatedSearchInput = search?.slice(0, -1);
          setSearch(updatedSearchInput);
          navigate(`/blig/add-artist/`);
        }
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [search]);
  return (
    <Container maxWidth="xxl">
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}
        className={classess.page}
      >
        <Grid
          item
          sm={12}
          md={12}
          lg={12}
          xl={9}
          className={classess.page__bannerGrid}
        >
          <Box className={classess.page__bannerGrid__searchBannerContainer}>
            <Box
              className={
                classess.page__bannerGrid__searchBannerContainer__textContainer
              }
            >
              <span
                className={
                  classess.page__bannerGrid__searchBannerContainer__textContainer__text
                }
              >
                Add New Artist
              </span>
            </Box>

            <Box
              className={
                classess.page__bannerGrid__searchBannerContainer__searchInput
              }
            >
              <SearchAutcomplete
                onInput={handleSearch}
                search={search}
                list={searchedArtistList}
                handleSearchItem={handleSearchItem}
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={9}>
          {!manual && (
            <ArtistForm
              handleSubmit={handleSubmit}
              image={image}
              name={name}
              onInputName={(event) => setName(event.target.value)}
              country={country}
              telephone={telephone}
              setTelephone={setTelephone}
              email={email}
              onInputEmail={(event) => setEmail(event.target.value)}
              onInputCountry={(event) => setCountry(event.target.value)}
              allCountries={allCountries}
              spotifyDisabled={spotifyDisabled}
              spotify_id={spotify_id}
              onInputSpotifyId={(event) => setSpotify_id(event.target.value)}
              isLoading={isLoading}
              handleClose={handleClose}
              adjust_height={"500px"}
            />
          )}
          {/* <AddNewForm
            manual={manual}
            handleSubmit={handleSubmit}
            image={image}
            name={name}
            onInputName={(event) => setName(event.target.value)}
            country={country}
            telephone={telephone}
            setTelephone={setTelephone}
            handleFocus={handleFocus}
            handleBlur={handleBlur}
            email={email}
            onInputEmail={(event) => setEmail(event.target.value)}
            onInputCountry={(event) => setCountry(event.target.value)}
            handleOpenSelect={handleOpenSelect}
            handleCloseSelect={handleCloseSelect}
            isOpen={isOpen}
            allCountries={allCountries}
            isFocused={isFocused}
            SpotifySvg={SpotifySvg}
            spotifyDisabled={spotifyDisabled}
            spotify_id={spotify_id}
            onInputSpotifyId={(event) => setSpotify_id(event.target.value)}
            isLoading={isLoading}
            handleClose={handleClose}
          /> */}
        </Grid>
      </Grid>
    </Container>
  );
};

// const AddNewForm = ({
//   manual,
//   handleSubmit,
//   image,
//   name,
//   onInputName,
//   country,
//   telephone,
//   setTelephone,
//   handleFocus,
//   handleBlur,
//   email,
//   onInputEmail,
//   onInputCountry,
//   handleOpenSelect,
//   handleCloseSelect,
//   isOpen,
//   allCountries,
//   isFocused,
//   SpotifySvg,
//   spotifyDisabled,
//   spotify_id,
//   onInputSpotifyId,
//   isLoading,
//   handleClose,
// }) => (
//   <Box>
//     {!manual && (
//       <Box className={classess.page__fieldsContainer}>
//         <form
//           action=""
//           className={classess.page__fieldsContainer__form}
//           autoComplete="off"
//           onSubmit={handleSubmit}
//         >
//           {!manual && (
//             <Box variant="div" component="div">
//               <Avatar
//                 src={image}
//                 alt={name}
//                 className={classess.page__fieldsContainer__form__avatar}
//               />
//             </Box>
//           )}
//           <Stack
//             direction="row"
//             sx={{
//               gap: { xs: 3, sm: 8, lg: 3 },
//               flexWrap: { xs: "wrap", sm: "nowrap" },
//             }}
//           >
//             <Box
//               varient="div"
//               component="div"
//               className={classess.page__fieldsContainer__form__formfield}
//               sx={{ width: "100%" }}
//             >
//               <label
//                 className={
//                   classess.page__fieldsContainer__form__formfield__label
//                 }
//               >
//                 Artist Name *
//               </label>
//               <CustomTextField
//                 autoComplete="off"
//                 value={name}
//                 maxLength={100}
//                 onInput={onInputName}
//                 placeholder="Enter Artist Name"
//                 type="text"
//                 required
//               />
//             </Box>
//             <Box
//               varient="div"
//               component="div"
//               className={classess.page__fieldsContainer__form__formfield}
//               sx={{ width: "100%" }}
//             >
//               <label
//                 className={
//                   classess.page__fieldsContainer__form__formfield__label
//                 }
//               >
//                 Phone
//               </label>
//               <PhoneInput
//                 international
//                 className={
//                   classess.page__fieldsContainer__form__formfield__phoneinput
//                 }
//                 defaultCountry={country}
//                 formatPhoneNumberIntl={telephone}
//                 placeholder="Enter phone number"
//                 value={telephone}
//                 name="telephone"
//                 onChange={setTelephone}
//                 autoComplete="off"
//                 required
//                 countryCallingCodeEditable={false}
//                 style={{
//                   border: isFocused
//                     ? "1px solid var(--base-input-focus-color)"
//                     : "1px solid var(--base-border-line-color)",
//                   transition: "all 0.3s ease-in-out",
//                 }}
//                 onFocus={handleFocus}
//                 onBlur={handleBlur}
//               />
//             </Box>
//           </Stack>
//           <Stack
//             direction="row"
//             sx={{
//               gap: { xs: 3, sm: 8, lg: 3 },
//               flexWrap: { xs: "wrap", sm: "nowrap" },
//             }}
//           >
//             <Box
//               varient="div"
//               component="div"
//               className={classess.page__fieldsContainer__form__formfield}
//               sx={{ width: "100%" }}
//             >
//               <label
//                 className={
//                   classess.page__fieldsContainer__form__formfield__label
//                 }
//               >
//                 E-mail Address
//               </label>
//               <CustomTextField
//                 maxLength={80}
//                 value={email}
//                 onInput={onInputEmail}
//                 placeholder="Enter Email Address"
//                 type="email"
//               />
//             </Box>
//           </Stack>
//           <Stack
//             direction="row"
//             sx={{
//               gap: { xs: 3, sm: 8, lg: 3 },
//               flexWrap: { xs: "wrap", sm: "nowrap" },
//             }}
//           >
//             {" "}
//             <Box
//               varient="div"
//               component="div"
//               className={classess.page__fieldsContainer__form__formfield}
//               sx={{ width: "100%" }}
//             >
//               <label
//                 className={
//                   classess.page__fieldsContainer__form__formfield__label
//                 }
//               >
//                 Country *
//               </label>
//               <CustomSelect
//                 value={country}
//                 onChange={onInputCountry}
//                 required
//                 displayEmpty
//                 onOpen={handleOpenSelect}
//                 onClose={handleCloseSelect}
//                 IconComponent={() =>
//                   isOpen ? (
//                     <IoIosArrowUp
//                       className={
//                         classess.page__fieldsContainer__form__formfield__select_icon
//                       }
//                     />
//                   ) : (
//                     <IoIosArrowDown
//                       className={
//                         classess.page__fieldsContainer__form__formfield__select_icon
//                       }
//                     />
//                   )
//                 }
//                 MenuProps={{
//                   PaperProps: {
//                     style: {
//                       maxHeight: 300,
//                     },
//                   },
//                   anchorOrigin: {
//                     vertical: "bottom",
//                     horizontal: "left",
//                   },
//                   transformOrigin: {
//                     vertical: "top",
//                     horizontal: "left",
//                   },
//                   getContentAnchorEl: null,
//                 }}
//               >
//                 <CustomMenuItem value="">Select Country</CustomMenuItem>
//                 {allCountries && allCountries.length > 0
//                   ? allCountries.map((item) => (
//                       <CustomMenuItem
//                         required
//                         key={item.value}
//                         value={item.value}
//                       >
//                         {item.label}
//                       </CustomMenuItem>
//                     ))
//                   : null}
//               </CustomSelect>
//             </Box>
//             <Box
//               varient="div"
//               component="div"
//               className={classess.page__fieldsContainer__form__formfield}
//               sx={{ width: "100%" }}
//             >
//               <label
//                 className={
//                   classess.page__fieldsContainer__form__formfield__label
//                 }
//               >
//                 <img
//                   className={
//                     classess.page__fieldsContainer__form__formfield__label__image
//                   }
//                   src={SpotifySvg}
//                   alt="spotify"
//                 />{" "}
//                 <Box ml={1}>Spotify ID</Box>
//               </label>
//               <input
//                 className={
//                   classess.page__fieldsContainer__form__formfield__input
//                 }
//                 readOnly={spotifyDisabled}
//                 value={spotify_id}
//                 onInput={onInputSpotifyId}
//                 placeholder="Enter Spotify Id"
//                 required
//                 style={{
//                   border: "1px solid #5A7380",
//                 }}
//               />
//             </Box>
//           </Stack>
//           <Stack
//             direction="row"
//             sx={{
//               gap: { xs: 3, sm: 8, lg: 15 },
//               flexWrap: { xs: "wrap", sm: "nowrap" },
//               width: "100%",
//               mt: 3,
//             }}
//           >
//             <Box
//               varient="div"
//               component="div"
//               sx={{
//                 width: "45%",
//                 marginLeft: "auto",
//               }}
//             >
//               <Stack direction="row" gap={4}>
//                 <Button
//                   type="button"
//                   className={classess.page__fieldsContainer__form__cancel_btn}
//                   onClick={handleClose}
//                 >
//                   Cancel
//                 </Button>
//                 <LoadingButton
//                   type="submit"
//                   onClick={handleSubmit}
//                   loading={isLoading}
//                   loadingPosition="start"
//                   variant="contained"
//                   className={classess.page__fieldsContainer__form__submit_btn}
//                 >
//                   Submit
//                 </LoadingButton>
//               </Stack>
//             </Box>
//           </Stack>
//         </form>
//       </Box>
//     )}
//   </Box>
// );

export default AddArtist;
