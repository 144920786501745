// Import React and hooks
import React from "react";
import { useNavigate } from "react-router-dom";

// Import MUI components
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Skeleton,
  Tooltip,
  Stack,
} from "@mui/material";

// Import local assets and styles
import EyeIcon from "../../assets/buttonsicons/EyeIcon.png";
import PowerIcon from "../../assets/powerIcon.svg";
import classes from "./style.module.scss";
import { ReactSVG } from "react-svg";

// Import custom styled MUI components
import {
  CustomTableContainer,
  CustomTable,
  CustomTableHead,
  CustomTableRow,
  CustomTableCellHeader,
  CustomTableBody,
  CustomTableCell,
} from "../../custom-mui-style/custom-mui-styles";

// Define the main component
const ArtistTopTracks = ({
  tracks,
  loader,
  toptrackfunding,
  sortedTopTract,
  internationalNumberFormat,
}) => {
  // Hooks
  const navigate = useNavigate();

  return (
    <Box varient="div" component="div" className={classes.page}>
      <Box varient="div" component="div" className={classes.page__header}>
        <span className={classes.page__header__title}>top tracks</span>
        {/* <Button
          className={classes.page__header__view_all}
          // onClick={() => {
          //   {
          //     navigate(`/blig/artist-tracks/${id}`);
          //   }
          // }}
        >
          View All
        </Button> */}
      </Box>

      <Box component="div" varient="div">
        {loader && (
          //Skeleton Loader
          <SkeletonComponent />
        )}

        {/* Check if sortedTopTract exists and has items */}
        {sortedTopTract && sortedTopTract.length > 0 && (
          <TopTrackListComponent
            sortedTopTract={sortedTopTract}
            internationalNumberFormat={internationalNumberFormat}
          />
        )}

        {/* Display a message when loader is false and no tracks are available */}
        {!loader && sortedTopTract && sortedTopTract.length < 1 && (
          <Box component="div" varient="div">
            <p
              style={{
                color: "#ccc",
                fontSize: "12px",
                textAlign: "center",
                marginTop: 150,
              }}
            >
              There are no tracks
            </p>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ArtistTopTracks;

const SkeletonComponent = () => {
  return (
    <CustomTableContainer className={classes.page__tableContainer}>
      <CustomTable stickyHeader={true} aria-label="sticky table">
        <CustomTableHead>
          <CustomTableRow>
            <CustomTableCellHeader />
            <CustomTableCellHeader>TITLE</CustomTableCellHeader>
            <CustomTableCellHeader>TYPE</CustomTableCellHeader>
            <CustomTableCellHeader>EST Last Month</CustomTableCellHeader>
            <CustomTableCellHeader>OWNERSHIP</CustomTableCellHeader>
            <CustomTableCellHeader />
          </CustomTableRow>
        </CustomTableHead>
        <CustomTableBody>
          {Array.from({ length: 7 }).map((_, index) => (
            <React.Fragment key={index}>
              <Box sx={{ m: "1rem" }}></Box>
              <CustomTableRow>
                <CustomTableCell
                  sx={{
                    borderTopLeftRadius: "12px",
                    borderBottomLeftRadius: "12px",
                  }}
                >
                  <Skeleton variant="circular" width={40} height={40} />
                </CustomTableCell>
                <CustomTableCell>
                  <Skeleton variant="text" fontSize="1rem" />
                </CustomTableCell>
                <CustomTableCell>
                  <Skeleton variant="text" fontSize="1rem" />
                </CustomTableCell>
                <CustomTableCell>
                  <Skeleton variant="text" fontSize="1rem" />
                </CustomTableCell>
                <CustomTableCell>
                  <Skeleton variant="text" fontSize="1rem" />
                </CustomTableCell>
                <CustomTableCell
                  sx={{
                    borderTopRightRadius: "12px",
                    borderBottomRightRadius: "12px",
                  }}
                >
                  <Skeleton variant="text" fontSize="1rem" />
                </CustomTableCell>
              </CustomTableRow>
            </React.Fragment>
          ))}
        </CustomTableBody>
      </CustomTable>
    </CustomTableContainer>
  );
};

const TopTrackListComponent = ({
  sortedTopTract,
  internationalNumberFormat,
}) => {
  const navigate = useNavigate();

  return (
    <CustomTableContainer className={classes.page__tableContainer}>
      <CustomTable stickyHeader={true} aria-label="sticky table">
        <CustomTableHead>
          <CustomTableRow>
            <CustomTableCellHeader></CustomTableCellHeader>
            <CustomTableCellHeader>TITLE</CustomTableCellHeader>
            <CustomTableCellHeader>TYPE</CustomTableCellHeader>
            <CustomTableCellHeader>EST Last Month</CustomTableCellHeader>
            <CustomTableCellHeader>% OWNERSHIP</CustomTableCellHeader>
            <CustomTableCellHeader>Action</CustomTableCellHeader>
          </CustomTableRow>
        </CustomTableHead>
        <CustomTableBody>
          {sortedTopTract.map((track, idx) => (
            <React.Fragment key={idx}>
              <Box sx={{ m: "1rem" }}></Box>
              <CustomTableRow>
                <CustomTableCell
                  sx={{
                    maxWidth: 50,
                    borderTopLeftRadius: "12px",
                    borderBottomLeftRadius: "12px",
                  }}
                >
                  <Avatar
                    src={track?.image}
                    alt={track?.name}
                    onClick={() => {
                      // navigate(`/blig/track/${track?.id}`);
                    }}
                  />
                </CustomTableCell>

                <CustomTableCell>
                  <Box className={classes.page__tableContainer__nameContainer}>
                    <Tooltip
                      title={track?.name}
                      placement="top"
                      arrow
                      enterDelay={100}
                    >
                      <span
                        className={
                          classes.page__tableContainer__nameContainer__name
                        }
                      >
                        {track?.name}
                      </span>
                    </Tooltip>
                  </Box>
                </CustomTableCell>

                <CustomTableCell>
                  <span
                    className={classes.page__heading1}
                    title={track?.track_type}
                  >
                    {track?.track_type}
                  </span>
                </CustomTableCell>

                <CustomTableCell>
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ alignItems: "center" }}
                  >
                    <span className={classes.page__heading1}>
                      <span>$</span>{" "}
                      {internationalNumberFormat.format(track?.funding)}
                    </span>

                    {/* <IconButton className={classes.page__actionIcon}>
                      <ReactSVG src={PowerIcon} />
                    </IconButton> */}
                  </Stack>
                </CustomTableCell>

                <CustomTableCell>
                  <span className={classes.page__heading1}>
                    {track?.stream_income_share}
                    {"%"}
                  </span>
                </CustomTableCell>

                <CustomTableCell
                  sx={{
                    maxWidth: 50,
                    borderTopRightRadius: "12px",
                    borderBottomRightRadius: "12px",
                  }}
                >
                  <IconButton
                    onClick={() => {
                      // // navigate(`/blig/track/${track?.id}`);
                    }}
                    style={{
                      backgroundColor: "var(--base-action-icon-background)",
                      height: "30px",
                      width: "30px",
                    }}
                  >
                    <img
                      src={EyeIcon}
                      alt="Eye"
                      style={{ height: "12px", width: "16px" }}
                    />
                  </IconButton>
                </CustomTableCell>
              </CustomTableRow>
            </React.Fragment>
          ))}
        </CustomTableBody>
      </CustomTable>
    </CustomTableContainer>
  );
};
