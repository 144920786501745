import React from "react";
import classess from "./style.module.scss";
import { Box } from "@mui/material";
const CustomTwoTabs = ({
  btn_0,
  btn_1,
  click_0,
  click_1,
  text_0,
  text_1,
  padding,
  isLoading,
}) => {
  return (
    <Box className={classess.page}>
      <Box className={classess.page__tabs_container}>
        <button
          style={{
            backgroundColor: btn_0 ? "var(--base-tabs-active-background)" : "",
            padding: padding,
          }}
          onClick={click_0}
          disabled={isLoading}
        >
          {text_0}
        </button>
        <button
          style={{
            backgroundColor: btn_1 ? "var(--base-tabs-active-background)" : "",
            padding: padding,
          }}
          onClick={click_1}
          disabled={isLoading}
        >
          {text_1}
        </button>
      </Box>
    </Box>
  );
};

export default CustomTwoTabs;
