import React from "react";
import { useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";

import Home from "../containers/home/home";
import LoginContainer from "../containers/login/login";
import NotFound from "../components/notfound/notfound";
import MyArtist from "../containers/my-artist/my-artist";
import SignupContainer from "../containers/signup/signup";
import ArtistSignupContainer from "../containers/artist/signup-artist/signup-artist";
import AddArtist from "../containers/artist/add-artist/add-artist";
import ViewArtist from "../containers/artist/view-artist/view-artist";
import EditArtist from "../containers/artist/edit-artist/edit-artist";
import BaseComponent from "../components/base-component/base-component";
import ResetPassword from "../containers/reset-password/ResetPassword";
import ViewFundingDashboard from "../containers/view-funding-dashboard/view-funding-dashboard";
import ProtectedRoute from "../protected-routes/protectedRoute";
import ProfilePage from "../containers/profile-page/profile-page";
import ForgotPassword from "../containers/forgot-password/ForgotPassword";
import ArtistAllTracks from "../components/artist-all-tracks/artist-all-tracks";
import UserListing from "../components/list-of-users/list-of-users";
import DocuSign from "../containers/docu-sign";
import Success from "../containers/success";
import MyContracts from "../containers/my-contracts/MyContracts";
import NewContract from "../containers/my-contracts/new-contract/NewContract";
import PreviewContract from "../containers/preview-contract/previewContract";
import PaymentPreview from "../containers/payment-preview/PaymentPreview";
import MyPayments from "../containers/my-payments/MyPayments";
import KYC from "../containers/kyc/KYC";
import EditDashboard from "../containers/artist/edit-dashboard/edit-dashboard";
import Track from "../containers/track/track";
import Notifications from "../containers/notifications/Notifications";

import UserHome from "../containers/user-home/UserHome";

import ArtistDeals from "../containers/artist-flow/artist-deals/ArtistDeals";
import ArtistPayments from "../containers/artist-flow/artists-payments/ArtistPayments";
import ArtistTeam from "../containers/artist-flow/artist-team/ArtistTeam";
import ArtistMyAccount from "../containers/artist-flow/artist-my-account/ArtistMyAccount";
import ArtistNotification from "../containers/artist-flow/artist-notifications/ArtistNotification";

import AandRActivity from "../containers/aandr-activity/AandRActivity";
import AandRPreview from "../components/aandr-preview/AandRPreview";
import ContractDetails from "../containers/artist-flow/contract-details/ContractDetails";

const Navigation = () => {
  const user = useSelector((state) => state.auth.user);

  const isAdmin = user && user.role === "admin";
  const isArtist = user && user.role === "artist";
  const isUser = user && user.role === "user";
  return (
    <Routes>
      <Route path="/blig" element={<BaseComponent />}>
        {/* // ? for Admin */}
        {isAdmin && (
          <>
            <Route
              index
              path="home"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route
              path="my-artist"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <MyArtist />
                </ProtectedRoute>
              }
            />
            <Route
              path="add-artist/:query"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <AddArtist />
                </ProtectedRoute>
              }
            />
            <Route
              path="add-artist"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <AddArtist />
                </ProtectedRoute>
              }
            />
            <Route
              path="view-artist/:id"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <ViewArtist />
                </ProtectedRoute>
              }
            />
            <Route
              path="track/:id"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <Track />
                </ProtectedRoute>
              }
            />
            <Route
              path="view-artist/:id/users"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <UserListing />
                </ProtectedRoute>
              }
            />
            <Route
              index
              path="edit-dashboard/:id"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <EditDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              index
              path="edit-artist/:id"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <EditArtist />
                </ProtectedRoute>
              }
            />
            <Route
              path="view-funding-dashboard/:id"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <ViewFundingDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="profile"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <ProfilePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="artist-tracks/:id"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <ArtistAllTracks />
                </ProtectedRoute>
              }
            />
            <Route
              path="docu-sign"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <DocuSign />
                </ProtectedRoute>
              }
            />
            <Route
              path="contracts"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <MyContracts />
                </ProtectedRoute>
              }
            />
            <Route
              path="kyc"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <KYC />
                </ProtectedRoute>
              }
            />
            <Route
              path="NewContract"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <NewContract />
                </ProtectedRoute>
              }
            />
            <Route
              path="contracts/:id"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <PreviewContract />
                </ProtectedRoute>
              }
            />
            <Route
              path="payment/:id"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <PaymentPreview />
                </ProtectedRoute>
              }
            />
            <Route
              path="success"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <Success />
                </ProtectedRoute>
              }
            />
            <Route
              path="payments"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <MyPayments />
                </ProtectedRoute>
              }
            />
            <Route
              path="notifications"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <Notifications />
                </ProtectedRoute>
              }
            />

            <Route
              path="aandractivity"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <AandRActivity />
                </ProtectedRoute>
              }
            />
            <Route
              path="aandractivity/:id"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <AandRPreview />
                </ProtectedRoute>
              }
            />
          </>
        )}
        {/* // ? for A&R user */}
        {isUser && (
          <>
            <Route
              index
              path="home"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <MyArtist />
                </ProtectedRoute>
              }
            />
            <Route
              path="my-artist"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <MyArtist />
                </ProtectedRoute>
              }
            />
            <Route
              path="add-artist/:query"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <AddArtist />
                </ProtectedRoute>
              }
            />
            <Route
              path="add-artist"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <AddArtist />
                </ProtectedRoute>
              }
            />
            <Route
              path="view-artist/:id"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <ViewArtist />
                </ProtectedRoute>
              }
            />
            <Route
              path="track/:id"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <Track />
                </ProtectedRoute>
              }
            />
            <Route
              path="view-artist/:id/users"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <UserListing />
                </ProtectedRoute>
              }
            />
            <Route
              index
              path="edit-dashboard/:id"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <EditDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              index
              path="edit-artist/:id"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <EditArtist />
                </ProtectedRoute>
              }
            />
            <Route
              path="view-funding-dashboard/:id"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <ViewFundingDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="profile"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <ProfilePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="artist-tracks/:id"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <ArtistAllTracks />
                </ProtectedRoute>
              }
            />
            <Route
              path="docu-sign"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <DocuSign />
                </ProtectedRoute>
              }
            />
            <Route
              path="contracts"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <MyContracts />
                </ProtectedRoute>
              }
            />
            <Route
              path="kyc"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <KYC />
                </ProtectedRoute>
              }
            />
            <Route
              path="NewContract"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <NewContract />
                </ProtectedRoute>
              }
            />
            <Route
              path="contracts/:id"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <PreviewContract />
                </ProtectedRoute>
              }
            />
            <Route
              path="payment/:id"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <PaymentPreview />
                </ProtectedRoute>
              }
            />
            <Route
              path="success"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <Success />
                </ProtectedRoute>
              }
            />
            <Route
              path="payments"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <MyPayments />
                </ProtectedRoute>
              }
            />
            <Route
              path="notifications"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <Notifications />
                </ProtectedRoute>
              }
            />
          </>
        )}
        {/* // ? for Artist */}
        {isArtist && (
          <>
            <Route
              path="artist-deals"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <ArtistDeals />
                </ProtectedRoute>
              }
            />
            <Route
              path="artist-contract-details/:id"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <ContractDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path="artist-payments"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <ArtistPayments />
                </ProtectedRoute>
              }
            />
            <Route
              path="artist-team"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <ArtistTeam />
                </ProtectedRoute>
              }
            />
            <Route
              path="artist-my-account"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <ArtistMyAccount />
                </ProtectedRoute>
              }
            />
            <Route
              path="artist-notification"
              element={
                <ProtectedRoute isLoggedIn={user}>
                  <ArtistNotification />
                </ProtectedRoute>
              }
            />
          </>
        )}
      </Route>

      <Route path="/login" element={<LoginContainer />} />
      <Route path="/signup" element={<SignupContainer />} />
      <Route path="/artist-signup" element={<ArtistSignupContainer />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="/blig" element={<Navigate to="/blig/home" />} />
    </Routes>
  );
};

export default Navigation;
