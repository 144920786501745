// React and Redux Imports
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// MUI Imports
import {
  Box,
  Grid,
  Avatar,
  IconButton,
  Typography,
  Stack,
  MenuItem,
  Button,
  Tooltip,
} from "@mui/material";

// Custom Components and Styles Imports
import { CustomSelect } from "../../custom-mui-style/custom-mui-styles";
import {
  CustomTable,
  CustomTableContainer,
  CustomTableBody,
  CustomTableCell,
  CustomTableHead,
  CustomTableRow,
  CustomTableCellHeader,
} from "../../custom-mui-style/custom-mui-styles";
import classess from "./style.module.scss";

// Assets and Utilities Imports
import { getItemToLocalStorage } from "../../services/storage";
import { config as URLconfig } from "../../enviorment/enviorment";
import EyeIcon from "../../assets/eye.svg";
import MessageIcon from "../../assets/message.svg";

// External Libraries and Icons Imports
import axios from "axios";
import Lottie from "lottie-react";
import { ReactSVG } from "react-svg";
import NewLoader from "../lottie-loader/Loader.json";
import { useNavigate } from "react-router-dom";

const AandrActivity = () => {
  const user = useSelector((state) => state.auth.user);
  const storedToken = getItemToLocalStorage("accessToken");
  const [isLoading, setIsLoading] = useState(false);
  // const [value, setValue] = useState("Weekly");
  // const [isOpen, setIsOpen] = useState(false);
  const [activities, setActivities] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchActivity = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${URLconfig.BASE_URL}/users/activity`,
          {
            headers: { Authorization: `Bearer ${storedToken}` },
          }
        );
        setActivities(response.data.data);
        console.log("Activity", response.data.data);
      } catch (error) {
        console.error("Error fetching contracts:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchActivity();
  }, []);

  return (
    <Box className={classess.page}>
      <Box className={classess.page__topSec}>
        <Box>
          <Typography className={classess.page__topSec__heading}>
            A&R Activity
          </Typography>
        </Box>
      </Box>
      <Box container spacing={2}>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <ActivityTable isLoading={isLoading} activities={activities} />
          <Button
            className={classess.page__button}
            onClick={() => navigate("/blig/aandractivity")}
          >
            View All A&Rs
          </Button>
        </Grid>
      </Box>
    </Box>
  );
};

export default AandrActivity;

const ActivityTable = ({ isLoading, activities }) => {
  const navigate = useNavigate();

  const sortedActivities = activities.sort(
    (a, b) => b.user.artists.length - a.user.artists.length
  );

  return (
    <CustomTableContainer className={classess.page__table}>
      {isLoading ? (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "50px",
              height: "50px",
            }}
          >
            <Lottie
              animationData={NewLoader}
              className={classess.page__loader}
            />
          </Box>
        </Box>
      ) : (
        <CustomTable stickyHeader aria-label="sticky table">
          <CustomTableHead>
            <CustomTableCellHeader />
            <CustomTableCellHeader>NAME</CustomTableCellHeader>

            <CustomTableCellHeader>LAST VIEWED</CustomTableCellHeader>

            <CustomTableCellHeader>TOTAL ARTISTS</CustomTableCellHeader>

            <CustomTableCellHeader>DEALS</CustomTableCellHeader>

            <CustomTableCellHeader>REVENUE (EST.)</CustomTableCellHeader>

            <CustomTableCellHeader>ACTIONS</CustomTableCellHeader>
          </CustomTableHead>

          <CustomTableBody>
            {sortedActivities.length > 0 ? (
              sortedActivities.map((activity, index) => {
                let rowRoiAmountTotal = 0;
                activity.additionalData.forEach((data) => {
                  rowRoiAmountTotal += parseInt(data.ROI) || 0;
                });
                let rowRoiAmount = rowRoiAmountTotal;

                return (
                  <React.Fragment key={activity.user._id}>
                    <Box sx={{ mt: "1rem" }} />
                    <CustomTableRow>
                      <CustomTableCell
                        sx={{
                          // width: "50px !important",
                          borderBottomLeftRadius: "12px",
                          borderTopLeftRadius: "12px",
                        }}
                      >
                        <Tooltip
                          title={
                            activity.user.firstName +
                            " " +
                            activity.user.lastName
                          }
                          placement="top"
                          arrow
                          enterDelay={100}
                        >
                          <Avatar
                            src={activity?.user?.profilePicture}
                            alt={activity.user.firstName}
                            sx={{
                              height: 50,
                              width: 50,
                              border: "2px solid var(--picture-border-color)",
                            }}
                          />
                        </Tooltip>
                      </CustomTableCell>

                      <CustomTableCell>
                        <Tooltip
                          title={
                            activity.user.firstName +
                            " " +
                            activity.user.lastName
                          }
                          placement="top"
                          arrow
                          enterDelay={100}
                        >
                          <Typography className={classess.page__name}>
                            {activity.user.firstName +
                              " " +
                              activity.user.lastName}
                          </Typography>
                        </Tooltip>
                      </CustomTableCell>

                      <CustomTableCell>
                        <Tooltip
                          title={activity?.recent_views_artist?.name}
                          placement="top"
                          arrow
                          enterDelay={100}
                        >
                          <Typography className={classess.page__heading1}>
                            {activity?.recent_views_artist?.name}
                          </Typography>
                        </Tooltip>
                      </CustomTableCell>

                      <CustomTableCell>
                        <Typography className={classess.page__heading2}>
                          {activity.user.artists.length}
                        </Typography>
                      </CustomTableCell>

                      <CustomTableCell>
                        <Typography className={classess.page__heading2}>
                          {activity.additionalData.length}
                        </Typography>
                      </CustomTableCell>

                      <CustomTableCell>
                        <Typography className={classess.page__heading2}>
                          {rowRoiAmount > 0
                            ? "$" + rowRoiAmount.toLocaleString()
                            : "$0"}
                        </Typography>
                      </CustomTableCell>

                      <CustomTableCell
                        sx={{
                          borderBottomRightRadius: "12px",
                          borderTopRightRadius: "12px",
                        }}
                      >
                        <Stack direction="row" spacing={1}>
                          <IconButton
                            className={classess.page__actionIcon}
                            onClick={() =>
                              navigate(
                                `/blig/aandractivity/${activity?.user?._id}`
                              )
                            }
                          >
                            <ReactSVG src={EyeIcon} />
                          </IconButton>
                          {/* <IconButton className={classess.page__actionIcon}>
                            <ReactSVG src={MessageIcon} />
                          </IconButton> */}
                        </Stack>
                      </CustomTableCell>
                    </CustomTableRow>
                  </React.Fragment>
                );
              })
            ) : (
              <React.Fragment>
                <Box sx={{ marginTop: "1rem" }} />
                <CustomTableRow>
                  <CustomTableCell colSpan={7} sx={{ borderRadius: "12px" }}>
                    <Typography className={classess.page__notFound}>
                      No Activity found.
                    </Typography>
                  </CustomTableCell>
                </CustomTableRow>
              </React.Fragment>
            )}
          </CustomTableBody>
        </CustomTable>
      )}
    </CustomTableContainer>
  );
};
