import React from "react";
import classess from "./style.module.scss";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AuthEnum from "../../enums/auth.enum";
import { getItemFromCurrentSession } from "../../services/session";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import img404 from "../../assets/404.png";
const NotFound = () => {
  const user = useSelector((state) => state.auth.user);
  const isAdmin = user && user.role === "admin";
  const isArtist = user && user.role === "artist";
  const isUser = user && user.role === "user";
  const navigate = useNavigate();
  return (
    <Box varient="div" component="div" className={classess.page}>
      <Box className={classess.page__centerContainer}>
        <Box className={classess.page__centerContainer__404Img}>
          <img src={img404} alt="img404" />
        </Box>
        <Box className={classess.page__centerContainer__headingSecondary}>
          Page Not Found
        </Box>
        <Box className={classess.page__centerContainer__BtnContainer}>
          <Button
            className={classess.page__centerContainer__BtnContainer__button}
            onClick={(e) => {
              e.preventDefault();
              if (isAdmin) {
                navigate("/blig/home");
              } else if (isArtist) {
                navigate("/blig/artist-deals");
              } else if (isUser) {
                navigate("/blig/home");
              } else {
                // Handle other cases if needed
              }
            }}
          >
            Go to Home Page
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default NotFound;
