import React, { useState } from "react";
import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import classess from "./style.module.scss";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import SignStepper from "../../../containers/my-contracts/sign-stepper/SignStepper";
// import PayStepper from "../../../containers/my-contracts/pay-stepper/PayStepper";
// import { ReactSVG } from "react-svg";
// import rightArrow from "../../../assets/rightArrow.svg";
const InitiateContract = ({
  contract_length,
  artist_advance,
  marketing_budget,
  totalFunding,
}) => {
  const [containerExpanded, setContainerExpanded] = useState([
    false,
    false,
    false,
  ]);

  const toggleExpansion = (index) => {
    const updatedExpanded = containerExpanded.map((expanded, i) =>
      i === index ? !expanded : false
    );
    setContainerExpanded(updatedExpanded);
  };
  const OnBoardButton = () => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button className={classess.onBoard}>On Boarding</Button>
      </Box>
    );
  };
  const data = [
    {
      name: "1. Contract",
      function: () => toggleExpansion(0),
      component: (
        <SignStepper
          contract_length={contract_length}
          // nextGo={toggleExpansion(1)}
          artist_advance={artist_advance}
          marketing_budget={marketing_budget}
          totalFunding={totalFunding}
          nextGo={() => toggleExpansion(1)}
        />
      ),
    },
    {
      name: "2. Payment",
      function: () => toggleExpansion(1),
      component: <OnBoardButton />,
      // component: (
      //   <PayStepper
      //     artist_advance={artist_advance}
      //     marketing_budget={marketing_budget}
      //   />
      // ),
    },
    // {
    //   name: "Review Details",
    //   function: () => toggleExpansion(2),
    // },
  ];

  const [letStart, setLetStart] = useState(false);
  const GetLetStart = () => {
    setLetStart(true);
  };

  return (
    <Box className={classess.page}>
      {letStart || (
        <Box className={classess.page__letStart} onClick={GetLetStart}>
          <Box></Box>
          <Box>
            <Typography className={classess.page__letStart__heading}>
              Lets get started on a contract!
            </Typography>
          </Box>
          <Box>
            <IconButton
              className={classess.page__letStart__icon}
              onClick={GetLetStart}
            >
              <IoIosArrowForward
                className={classess.page__letStart__icon__rightIcon}
              />
            </IconButton>
          </Box>
        </Box>
      )}
      {letStart && (
        <Box className={classess.page__customPanel}>
          <Box sx={{ padding: "20px" }}>
            <Typography className={classess.page__customPanel__heading}>
              Lets generate a contract
            </Typography>
          </Box>
          <Divider className={classess.page__letStart__divider} />
          <Box className={classess.page__customPanel__contractContainer}>
            <Box
              className={
                classess.page__customPanel__contractContainer__contractList
              }
            >
              {data.map((item, index) => (
                <ListItems
                  index={index}
                  containerExpanded={containerExpanded[index]}
                  name={item.name}
                  toggle_btn={item.function}
                  component={item.component}
                />
              ))}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const ListItems = ({
  index,
  containerExpanded,
  name,
  component,
  toggle_btn,
}) => {
  return (
    <Box
      key={index}
      className={
        classess.page__customPanel__contractContainer__contractList__list
      }
      style={{
        maxHeight: containerExpanded ? "100%" : "71px",
        border: containerExpanded
          ? "2px solid var(--base-img-border-color)"
          : "2px solid var(--base-inner-container-background-color)",
        cursor: containerExpanded ? "default" : "pointer",
      }}
      onClick={containerExpanded ? null : toggle_btn}
    >
      <Box
        className={
          classess.page__customPanel__contractContainer__contractList__list__listContent
        }
      >
        <Typography
          className={
            classess.page__customPanel__contractContainer__contractList__list__listContent__heading
          }
        >
          {name}
        </Typography>
        <Box>
          <IconButton
            className={
              classess.page__customPanel__contractContainer__contractList__list__listContent__listContentIcon
            }
            onClick={toggle_btn}
          >
            {containerExpanded ? <IoIosArrowDown /> : <IoIosArrowForward />}
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ mt: 3 }}>{containerExpanded && component}</Box>
    </Box>
  );
};

export default InitiateContract;
