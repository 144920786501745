// React and Redux Imports
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// MUI Imports
import {
  Box,
  Grid,
  Avatar,
  Typography,
  Tooltip,
  Container,
} from "@mui/material";

// Custom Components and Styles Imports
import {
  CustomTable,
  CustomTableContainer,
  CustomTableBody,
  CustomTableCell,
  CustomTableHead,
  CustomTableRow,
  CustomTableCellHeader,
} from "../../custom-mui-style/custom-mui-styles";
import classess from "./style.module.scss";
import { viewArtistUseStyles } from "../../custom-mui-style/custom-mui-styles";

// Assets and Utilities Imports
import { getItemToLocalStorage } from "../../services/storage";
import { config as URLconfig } from "../../enviorment/enviorment";

// External Libraries and Icons Imports
import axios from "axios";
import Lottie from "lottie-react";
import NewLoader from "../lottie-loader/Loader.json";
import { useNavigate } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import { countries } from "country-data";
import { addCommasToNumber, scrollTopObserver } from "../../utils/helper";

import { useParams } from "react-router-dom";
import UserInfoContainer from "../aandr-activity-list/user-Info-container/UserInfoContainer";
const AandRPreview = () => {
  const { id } = useParams();

  const user = useSelector((state) => state.auth.user);
  const storedToken = getItemToLocalStorage("accessToken");
  const [isLoading, setIsLoading] = useState(false);
  const [userArtistsList, setUserArtistsList] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const styles = viewArtistUseStyles();

  const fetchartistUser = async (id) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${URLconfig.BASE_URL}/artists/action/artists-by-user/${id}`,
        {
          headers: { Authorization: `Bearer ${storedToken}` },
        }
      );
      setUserArtistsList(response.data.data.artists);
      setSelectedUser(response.data.data.user);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching contracts:", error);
    } finally {
    }
  };

  useEffect(() => {
    if (id) {
      fetchartistUser(id);
    }
  }, [id]);

  return (
    <Container maxWidth="xxl" className={styles.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={9}>
          <UserInfoContainer />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={9}>
          <Box className={classess.page}>
            {isLoading ? (
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "50px",
                    height: "50px",
                  }}
                >
                  <Lottie
                    animationData={NewLoader}
                    className={classess.page__loader}
                  />
                </Box>
              </Box>
            ) : (
              <ActivityTable
                isLoading={isLoading}
                userArtistsList={userArtistsList}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AandRPreview;

const ActivityTable = ({ isLoading, userArtistsList }) => {
  const navigate = useNavigate();

  const getEmail = (email, name) => {
    if (email) {
      return email;
    }
    const newName = name.replace(/\s+/, "");
    return `${newName}@blacklionapp.xyz`;
  };

  return (
    <CustomTableContainer className={classess.page__table}>
      <CustomTable stickyHeader aria-label="sticky table">
        <CustomTableHead>
          <CustomTableCellHeader />
          <CustomTableCellHeader>ARTIST NAME</CustomTableCellHeader>
          <CustomTableCellHeader>COUNTRY</CustomTableCellHeader>
          <CustomTableCellHeader>EMAIL</CustomTableCellHeader>
          <CustomTableCellHeader>Earnings(Yearly)</CustomTableCellHeader>
        </CustomTableHead>

        <CustomTableBody>
          {userArtistsList.length > 0 ? (
            userArtistsList.map((row, index) => {
              return (
                <React.Fragment key={row._id}>
                  <Box sx={{ mt: "1rem" }} />
                  <CustomTableRow>
                    <CustomTableCell
                      sx={{
                        borderBottomLeftRadius: "12px",
                        borderTopLeftRadius: "12px",
                      }}
                    >
                      <Avatar
                        src={row?.avatar}
                        alt={row?.name}
                        className={classess.page__placement__artist_image}
                      />
                    </CustomTableCell>

                    <CustomTableCell>
                      <Box className={classess.page__placement}>
                        <Tooltip
                          title={row?.name}
                          placement="top"
                          arrow
                          enterDelay={100}
                        >
                          <span
                            onClick={() => {
                              navigate(`/blig/view-artist/${row?._id}`);
                              scrollTopObserver();
                            }}
                          >
                            {row?.name}
                          </span>
                        </Tooltip>
                      </Box>
                    </CustomTableCell>

                    <CustomTableCell>
                      <Box className={classess.page__country}>
                        <ReactCountryFlag
                          countryCode={row?.chartmetric?.code2}
                          svg
                        />
                        {row?.chartmetric?.code2
                          ? countries[row?.chartmetric?.code2.toUpperCase()]
                              ?.name || "N/A"
                          : "N/A"}
                      </Box>
                    </CustomTableCell>

                    <CustomTableCell>
                      <Box className={classess.page__email}>
                        {getEmail(row?.email, row?.name)}
                      </Box>
                    </CustomTableCell>

                    <CustomTableCell
                      sx={{
                        borderTopRightRadius: "12px",
                        borderBottomRightRadius: "12px",
                      }}
                    >
                      <span className={classess.page__yearly_earning}>
                        {addCommasToNumber(
                          row?.oneyearIncome ? row?.oneyearIncome : "N/A"
                        )}
                      </span>
                    </CustomTableCell>
                  </CustomTableRow>
                </React.Fragment>
              );
            })
          ) : (
            <React.Fragment>
              <Box sx={{ marginTop: "1rem" }} />
              <CustomTableRow>
                <CustomTableCell colSpan={7} sx={{ borderRadius: "12px" }}>
                  <Typography className={classess.page__notFound}>
                    No Artists Found.
                  </Typography>
                </CustomTableCell>
              </CustomTableRow>
            </React.Fragment>
          )}
        </CustomTableBody>
      </CustomTable>
    </CustomTableContainer>
  );
};
