/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import classess from "./style.module.scss";
import { Box, Button } from "@mui/material";
import ContractDetails from "../../../components/contract-details/ContractDetails";
import ContractPreview from "../../../components/contract-preview/ContractPreview";
import ContractSubmitPreview from "../../../components/contract-submit/ContractSubmitPreview";
import { useSelector } from "react-redux";
import axios from "axios";
import { config as URLConfig } from "../../../enviorment/enviorment";
import FileDownload from "js-file-download";
import { toast } from "react-toastify";
import CustomThreeTabs from "../../../components/custom-three-tabs/custom-three-tabs";
import { getItemToLocalStorage } from "../../../services/storage";
import AuthEnum from "../../../enums/auth.enum";
const steps = ["Contract Details", "Contract Preview", "Submit for Review"];

const SignStepper = (props) => {
  const childRef = useRef(null);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [getNumber, setGetNumber] = useState(0);
  const selected = useSelector((state) => state.artist.selectedTracks);
  const tracks = useSelector((state) => state.artist.tracks);
  const [contract, setContractData] = useState([]);
  const [selected_tracts, SetSelected_Tracts] = useState([]);
  const [formData, setFormData] = useState({});
  const [backDataSending, setBackDataSending] = useState({});
  const [documentData, SetdocumentData] = useState();
  const [selectedEmails, setSelectedEmails] = useState([]);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  function getSingleTrack(id) {
    const {
      id: trackId,
      title,
      stream_income_share,
      isrc,
    } = tracks.filter((track) => track.id === id)[0];
    return { trackId, title, stream_income_share, isrc };
  }

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (activeStep === 0) {
      childRef.current.onSubmit();
    }
    if (activeStep === 2) {
      childRef.current.onSubmit();
      saveinfo(formData);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + getNumber);
    setSkipped(newSkipped);
  };
  const saveinfo = async (info) => {
    const payload = {
      legel_name: info?.legel_name,
      artist_id: info?.artist_id,
      artist_name: info?.artist_name,
      artist_representative_name: info?.artist_representative_name,
      artist_email: info?.artist_email,
      artist_phone: info?.artist_phone,
      artist_image: info?.artist_image,
      artist_representative_email: info?.artist_representative_email,
      advance: info?.advance,
      advance_amount: info?.advance_amount,
      recipient_address: info?.recipient_address,
      city: info?.city,
      zip_code: info?.zip_code,
      country: info?.country,
      Selected_tracks: info?.Selected_tracks,
      contract_length: info?.contract_length,
      artist_advance: props.artist_advance || 0,
      artist_marketing_budget: props.marketing_budget || 0,
      ROI:
        props.totalFunding -
          (parseInt(props.artist_advance || 0) +
            parseInt(props.marketing_budget || 0)) || 0,
      document: documentData,
    };
    const res = await fetch(`${URLConfig.BASE_URL}/contracts/create`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `Bearer ${getItemToLocalStorage(AuthEnum.TOKEN)}`,
      },
      body: JSON.stringify(payload),
    });
    const data = await res.json();
    if (res.status === 200) {
      toast.success(`${data.message}`);
    } else {
      toast.error(`${data.message}`);
    }
  };
  const handleBack = () => {
    setBackDataSending(formData);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function convertHtmlToDoc(contract) {
    const data = {
      name: contract?.artist_name,
      email: contract?.artist_email,
      legal_name: contract?.legel_name,
      address: contract?.recipient_address,
      city: contract?.city,
      country: contract?.country,
      zip_code: contract?.zip_code,
      contract_length: props.contract_length,
      selected_tracks: selected_tracts,
      document: documentData,
    };

    axios({
      url: `${URLConfig.BASE_URL}/contract-gen/convert`,
      data,
      method: "POST",
      responseType: "blob",
      headers: {
        authorization: `Bearer ${getItemToLocalStorage(AuthEnum.TOKEN)}`,
      },
    })
      .then((response) => {
        FileDownload(
          response.data,
          `Contract_agreement_${contract?.artist_name}.docx`
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    const selecttacts = selected.map((e) => getSingleTrack(e));
    SetSelected_Tracts(selecttacts);
    setActiveStep((prevActiveStep) => prevActiveStep + getNumber);
  }, [getNumber]);
  const handleTabs = (value) => {
    setBackDataSending(formData);
    setActiveStep(value);
  };

  const updateSelectedEmails = (emails) => {
    setSelectedEmails(emails);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Box className={classess.page__tabs_container}>
        <CustomThreeTabs
          text_0={"details"}
          text_1={"preview"}
          text_2={"review"}
          btn_0={activeStep === 0}
          btn_1={activeStep === 1}
          btn_2={activeStep === 2}
          click_0={() => handleTabs(0)}
          click_1={() => handleTabs(1)}
          click_2={() => handleTabs(2)}
        />
      </Box>

      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography
            sx={{
              mt: 2,
              mb: 1,
              textAlign: "center",
              color: "#fff",
              padding: "30px",
              background: "#222C41",
              borderRadius: 4,
            }}
          >
            Contract Submitted for Review Successfully!
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              pt: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                width: "40%",
              }}
            >
              <Button
                onClick={() => convertHtmlToDoc(contract)}
                variant="contained"
                className={classess.page__return_home_btn}
              >
                Download a Copy
              </Button>
              <Button
                variant="contained"
                onClick={props.nextGo}
                className={classess.page__download_btn}
              >
                Next
              </Button>
            </Box>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {activeStep === 0 ? (
            <ContractDetails
              ref={childRef}
              setGetNumber={setGetNumber}
              setContractData={setContractData}
              selected_tracts={selected_tracts}
              contract_length={props.contract_length}
              setFormData={setFormData}
              backDataSending={backDataSending}
            />
          ) : null}
          {activeStep === 1 ? (
            <ContractPreview
              contract={contract}
              contract_length={props.contract_length}
              SetdocumentData={SetdocumentData}
            />
          ) : null}
          {activeStep === 2 ? (
            <ContractSubmitPreview
              ref={childRef}
              contract={contract}
              updateSelectedEmails={updateSelectedEmails}
            />
          ) : null}

          {activeStep === 0 ? (
            <Box>
              <Button
                onClick={handleNext}
                className={classess.page__preview_btn}
              >
                Preview Contract
              </Button>
            </Box>
          ) : (
            <Box className={classess.page__btn_container}>
              <Box sx={{ width: "70%" }}>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classess.page__btn_container__back_btn}
                >
                  Go back
                </Button>
              </Box>
              <Box sx={{ width: "100%" }}>
                <Button
                  onClick={handleNext}
                  // disabled={false}
                  disabled={activeStep === 2 && selectedEmails.length === 0}
                  className={classess.page__btn_container__save_btn}
                >
                  {activeStep === 1
                    ? "Save & Submit for review"
                    : "Save & submit for approvals"}
                </Button>
              </Box>
            </Box>
          )}
        </React.Fragment>
      )}
    </Box>
  );
};

export default SignStepper;
