import React, { useState } from "react";
import classess from "./style.module.scss";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
// import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  CustomCheckbox,
  CustomMenuItem,
  CustomRadioButton,
  CustomSelectMonth,
  CustomTable,
  CustomTableBody,
  CustomTableCell,
  CustomTableCellHeader,
  CustomTableContainer,
  CustomTableHead,
  CustomTableRow,
  // CustomSliderWithStyles,
  // muiTableCellUseStyles,
} from "../../custom-mui-style/custom-mui-styles";
import FormControl from "@mui/material/FormControl";
// import { grey } from "@mui/material/colors";
import { getFullYearWithRange, monthsOptions } from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { PiMusicNotesPlusBold } from "react-icons/pi";
import { BsPlus } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import {
  deleteNewMusic,
  getNewMusicByID,
  postNewMusic,
  updateNewMusic,
} from "../../redux/slice/new-music";
import { setIsLoadingNew } from "../../redux/slice/artist";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
// import DeleteNewMusicRecordDialog from "../../dialogs/delete-new-music-record-dialog/delete-new-music-record-dialog";
import {
  Divider,
  // MenuItem,
  // Select,
  // Divider,
  // Table,
  // TableBody,
  // TableCell,
  // TableContainer,
  // TableHead,
  TablePagination,
  // TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
// import PencilIcon from "../../assets/buttonsicons/EditIcon.png";
// import DeleteIcon from "../../assets/buttonsicons/DeleteIcon.png";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import Pen from "../../assets/pen.svg";
import Delete from "../../assets/deleteIcon.svg";
import { ReactSVG } from "react-svg";
import DeleteConformationDialog from "../../dialogs/delete-conformation-dialog/delete-conformation-dialog";
const NewMusic = ({
  // newMusicIncomeArtistKeeps,
  // new_music_income,
  artist_id,
  getArtistFunding,
  // set_new_music_income,
  // openNewMusicForm,
  // closeNewMusicForm,
  // openTrackes,
  Showtracks,
  Hidetracks,
}) => {
  const artist = useSelector((state) => state.artist.artist);
  // console.log(artist);
  const dispatch = useDispatch();
  const newMusicData = useSelector((state) => state.new_music.newMusic);

  const [includeMusicActive, setIncludeMusicActive] = useState(false);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [radioValue, setRadioValue] = useState("single");
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editRecord, setEditRecord] = useState();
  const [delete_new_music_records, setdelete_new_music_records] = useState();
  const [open, setOpen] = useState(false);
  const [recoupmentRate, setRecoupmentRate] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const initUIData = () => {
    dispatch(
      getNewMusicByID({
        id: artist_id,
      })
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (year !== "") {
      setIsLoading(true);
      dispatch(setIsLoadingNew(true));

      if (isEdit) {
        updateNewMusicRelease();
      } else {
        postNewMusicRelease();
      }
      getArtistFunding();
    } else {
      toast.error("Please complete the year field.");
    }
    setIsEditMode(false);
    // setRecoupmentRate("");
    hideTracks();
  };

  const postNewMusicRelease = () => {
    const current_date = new Date();
    const selected_date = new Date();
    selected_date.setMonth(parseInt(month));
    selected_date.setFullYear(parseInt(year));

    if (selected_date <= current_date) {
      toast.error("You cannot select past date.");
      setIsLoading(false);
    } else {
      const payload = {
        year,
        month: monthsOptions[parseInt(month)].value,
        music: radioValue,
        artist_id,
      };
      const request = dispatch(
        postNewMusic({
          data: payload,
        })
      );

      request
        .then(() => {
          setIsLoading(false);
          initUIData();
          emptyFields();
          setIncludeMusicActive(false);
          // closeNewMusicForm();
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
          emptyFields();
          setIncludeMusicActive(false);
          // closeNewMusicForm();
        });
    }
  };

  const updateNewMusicRelease = () => {
    const current_date = new Date();
    const selected_date = new Date();
    selected_date.setMonth(parseInt(month));
    selected_date.setFullYear(parseInt(year));

    if (selected_date <= current_date) {
      toast.error("You cannot select past date.");
      setIsLoading(false);
    } else {
      const payload = {
        year,
        month: monthsOptions[month].value,
        music: radioValue,
        artist_id: editRecord?.artist_id,
      };
      const request = dispatch(
        updateNewMusic({
          data: payload,
          id: editRecord?._id,
        })
      );

      request
        .then(() => {
          setIsLoading(false);
          initUIData();
          emptyFields();
          setIsEdit(false);
          setIncludeMusicActive(false);
          // closeNewMusicForm();
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
          emptyFields();
          setIsEdit(false);
          setIncludeMusicActive(false);
          // closeNewMusicForm();
        });
    }
  };
  const handleOpenDeleteDialog = (new_misic) => {
    setdelete_new_music_records(new_misic);
    setOpen(true);
  };
  const handleDeleteDialog = (res) => {
    setOpen(false);
    if (res) {
      deleteNewMusicRelease(delete_new_music_records);
    }
    dispatch(setIsLoadingNew(true));
  };
  const deleteNewMusicRelease = (id) => {
    const request = dispatch(
      deleteNewMusic({
        id: id,
      })
    );
    request
      .then(() => {
        initUIData();
        getArtistFunding();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEdit = (record) => {
    setIsEditMode(true);
    setIsEdit(true);
    setEditRecord(record);
    setYear(record?.year);
    setRecoupmentRate("");
    let index = monthsOptions.findIndex((e) => e.value === record?.month);
    setMonth(index);
    setRadioValue(record?.music);
    setIncludeMusicActive(true);
  };

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };

  const cancelForm = () => {
    emptyFields();
    setIncludeMusicActive(false);
    // closeNewMusicForm();
    // setOpenNewMusicForm(false);
  };

  const emptyFields = () => {
    setMonth("");
    setYear(undefined);
    setRadioValue("single");
  };

  // useEffect(() => {
  //   initUIData();
  // }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const showTracks = () => {
    Showtracks();
  };
  const hideTracks = () => {
    Hidetracks();
  };

  const handleRecoupmentRate = (event) => {
    let inputValue = event.target.value;

    // Ensure the input is within the range of 1 to 100
    if (inputValue === "" || (inputValue >= 1 && inputValue <= 100)) {
      setRecoupmentRate(inputValue);
    }
  };

  const handleOpenSelect = () => setIsOpen(true);
  const handleCloseSelect = () => setIsOpen(false);
  return (
    <Box varient="div" component="div" className={classess.page}>
      <Box varient="div" component="div" className={classess.page__new_music}>
        <Typography sx={{ fontSize: "12px" }}>
          <b>Note:</b> new music projects are based on historical tracks public
          training data; it is important to select tracks that would fairly
          represent the upcoming music. Please select new music tracks that best
          represent your next release(S)
        </Typography>

        <Box
          varient="div"
          component="div"
          className={classess.page__music_container}
        >
          <Box
            varient="div"
            component="div"
            className={classess.page__music_container__button_container}
          >
            <Button
              type="button"
              onClick={() => setIncludeMusicActive(true)}
              className={`${
                classess.page__music_container__button_container__button
              }
                ${
                  includeMusicActive
                    ? classess.buttonDisabled
                    : classess.buttonActive
                }`}
              startIcon={<PiMusicNotesPlusBold />}
            >
              Add New Music
            </Button>
          </Box>
        </Box>
      </Box>
      <Divider className={classess.page__divider} />

      {/* Form */}

      {includeMusicActive && (
        <Box
          varient="div"
          component="div"
          className={classess.page__new_music__main}
        >
          <Stack
            direction="row"
            gap={3}
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              mt: 1,
              pb: 2,
            }}
          >
            <Box>
              <FormControl>
                <Typography className={classess.page__new_music__main__heading}>
                  TYPE OF TRACK
                </Typography>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={radioValue}
                  defaultValue={radioValue}
                  name="radio-buttons-group"
                  onChange={handleRadioChange}
                  sx={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <FormControlLabel
                    value="single"
                    control={<CustomRadioButton />}
                    label="Single"
                  />
                  <FormControlLabel
                    value="ep"
                    control={<CustomRadioButton />}
                    label="EP"
                  />
                  <FormControlLabel
                    value="album"
                    control={<CustomRadioButton />}
                    label="Album"
                  />
                </RadioGroup>
              </FormControl>
            </Box>

            <Box>
              <form
                autoComplete="off"
                className={classess.page__new_music__main__form}
                onSubmit={onSubmit}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignContent: "center",
                    gap: "30px",
                    flexWrap: "wrap",
                  }}
                >
                  <Box>
                    <Box
                      varient="div"
                      component="div"
                      className={
                        classess.page__new_music__main__form__form_fields
                      }
                    >
                      <Box>
                        <Typography
                          className={classess.page__new_music__main__heading}
                        >
                          MONTH
                        </Typography>
                        <CustomSelectMonth
                          value={month}
                          onChange={(e) => {
                            setMonth(e.target.value);
                          }}
                          displayEmpty
                          onOpen={handleOpenSelect}
                          onClose={handleCloseSelect}
                          IconComponent={() =>
                            isOpen ? (
                              <IoIosArrowUp
                                className={
                                  classess.page__new_music__main__select_icon
                                }
                              />
                            ) : (
                              <IoIosArrowDown
                                className={
                                  classess.page__new_music__main__select_icon
                                }
                              />
                            )
                          }
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 300,
                              },
                            },
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          <CustomMenuItem value="" selected>
                            Select Month
                          </CustomMenuItem>

                          {monthsOptions.map((month, index) => (
                            <CustomMenuItem value={index}>
                              {month.key}
                            </CustomMenuItem>
                          ))}
                        </CustomSelectMonth>
                      </Box>
                      <Box>
                        <Typography
                          className={classess.page__new_music__main__heading}
                        >
                          YEAR
                        </Typography>
                        <input
                          type="number"
                          min={getFullYearWithRange(0)}
                          maxLength={4}
                          minLength={4}
                          max={2033}
                          name="year"
                          placeholder="2024"
                          value={year}
                          onInput={(event) => setYear(event.target.value)}
                          className={
                            classess.page__new_music__main__form__form_fields__input
                          }
                          requried
                        />
                      </Box>
                      <Box
                        sx={{
                          position: "relative",
                        }}
                      >
                        <Typography
                          className={classess.page__new_music__main__heading}
                        >
                          RECOUPMENT RATE
                        </Typography>
                        <input
                          type="number"
                          placeholder="80"
                          value={recoupmentRate}
                          onChange={handleRecoupmentRate}
                          className={
                            classess.page__new_music__main__form__form_fields__input
                          }
                          min="1"
                          max="100"
                          required
                        />
                        <Typography
                          sx={{
                            fontSize: "10px",
                            position: "absolute",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Input should be 1 to 100
                        </Typography>
                        <Box
                          sx={{
                            position: "absolute",
                            fontSize: "16px",
                            top: "33px",
                            left: "33px",
                            color: "var(--base-input-text-color) ",
                          }}
                        >
                          %
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      mt: 3.8,
                    }}
                  >
                    <Box
                      varient="div"
                      component="div"
                      className={
                        classess.page__new_music__main__form__form_fields
                      }
                    >
                      <Button
                        type="submit"
                        className={
                          classess.page__new_music__main__form__add_button
                        }
                        disabled={isLoading}
                        startIcon={<BsPlus />}
                      >
                        {isLoading && (
                          <CircularProgress size={25} color="inherit" />
                        )}
                        {isEditMode ? "Update" : "Add"}
                      </Button>
                      <Button
                        type="button"
                        className={
                          classess.page__new_music__main__form__cancel_button
                        }
                        disabled={isLoading}
                        // onClick={cancelForm}
                        onClick={() => {
                          hideTracks();
                          cancelForm();
                          setIsEditMode(false);
                        }}
                        startIcon={<RxCross2 />}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </form>
            </Box>
          </Stack>
        </Box>
      )}

      {/* Listing after add track */}
      {!includeMusicActive && (
        <Box
          varient="div"
          component="div"
          className={classess.page__music_details}
        >
          {newMusicData && newMusicData.length ? (
            <Box
              varient="div"
              component="div"
              className={classess.page__music_details__main_container}
            >
              <CustomTableContainer>
                <CustomTable stickyHeader={true} aria-label="sticky table">
                  <CustomTableHead>
                    <CustomTableRow>
                      {/* <CustomTableCellHeader padding="checkbox">
                        <Box sx={{ ml: 1.5 }}>
                          <CustomCheckbox />
                        </Box>
                      </CustomTableCellHeader> */}
                      <CustomTableCellHeader>
                        <Box sx={{ ml: 7 }}>Type</Box>
                      </CustomTableCellHeader>
                      <CustomTableCellHeader>
                        Release Date
                      </CustomTableCellHeader>{" "}
                      <CustomTableCellHeader>
                        <Box
                          sx={{
                            textAlign: "right",
                            paddingRight: "40px",
                          }}
                        >
                          Action
                        </Box>
                      </CustomTableCellHeader>
                    </CustomTableRow>
                  </CustomTableHead>
                  <CustomTableBody>
                    {newMusicData.map((music) => (
                      <React.Fragment>
                        <Box sx={{ m: "1rem" }}></Box>

                        <CustomTableRow>
                          {/* <CustomTableCell
                            sx={{
                              borderTopLeftRadius: "12px",
                              borderEndStartRadius: "12px",
                            }}
                          >
                            <CustomCheckbox />
                          </CustomTableCell> */}
                          <CustomTableCell
                            sx={{
                              borderTopLeftRadius: "12px",
                              borderEndStartRadius: "12px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                gap: "20px",
                                alignItems: "center",
                              }}
                            >
                              <Box>
                                <LazyLoadImage
                                  src={artist?.avatar}
                                  alt="artist img"
                                  className={classess.page__avatar}
                                />
                              </Box>
                              <Box>
                                <Typography
                                  className={classess.page__type_name}
                                >
                                  {music?.music}
                                </Typography>
                              </Box>
                            </Box>
                          </CustomTableCell>{" "}
                          <CustomTableCell>
                            <Typography className={classess.page__type_name}>
                              {music?.month} {music?.year}
                            </Typography>
                          </CustomTableCell>{" "}
                          <CustomTableCell
                            sx={{
                              borderTopRightRadius: "12px",
                              borderEndEndRadius: "12px",
                            }}
                          >
                            {/* <Box className={classess.page__action}> */}
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                gap: "10px",
                                mr: 2,
                              }}
                            >
                              <Tooltip
                                title="Edit Track"
                                placement="top"
                                arrow
                                enterDelay={100}
                              >
                                <IconButton
                                  className={classess.page__action__edit_btn}
                                  onClick={() => {
                                    handleEdit(music);
                                    showTracks();
                                  }}
                                >
                                  <ReactSVG src={Pen} />
                                </IconButton>
                              </Tooltip>

                              <Tooltip
                                title="Delete Track"
                                placement="top"
                                arrow
                                enterDelay={100}
                              >
                                <IconButton
                                  className={classess.page__action__delete_btn}
                                  onClick={() =>
                                    handleOpenDeleteDialog(music?._id)
                                  }
                                >
                                  <ReactSVG src={Delete} />
                                </IconButton>
                              </Tooltip>
                            </Box>
                            {/* </Box> */}
                          </CustomTableCell>
                        </CustomTableRow>
                      </React.Fragment>
                    ))}
                  </CustomTableBody>
                </CustomTable>
              </CustomTableContainer>
            </Box>
          ) : null}
        </Box>
      )}

      {!includeMusicActive && newMusicData?.length >= 1 ? (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          sx={{ color: "#d6d6d6" }}
          count={newMusicData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          classes={{
            actions: "custom-pagination-actions",
            select: "custom-pagination-select",
            displayedRows: "custom-select-style",
            selectLabel: "custom-select-style",
          }}
          SelectProps={{
            classes: {
              select: "custom-select", // Apply the custom-select class to the Select component
            },
          }}
        />
      ) : null}

      {/* <DeleteNewMusicRecordDialog
        onClose={handleDeleteDialog}
        open={open}
        track={delete_new_music_records}
      /> */}
      <DeleteConformationDialog
        onClose={handleDeleteDialog}
        open={open}
        artist={""}
        delete_new_music_records={delete_new_music_records}
      />
    </Box>
  );
};

export default NewMusic;
