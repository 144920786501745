import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsLoading,
  setReload,
  setSearchResultTracks,
  setSelectedTrackCount,
  setSelectedTracks,
  setShowSelectedTracksFunding,
  setTotalTracks,
  setTracks,
} from "../../redux/slice/artist";
import classess from "./style.module.scss";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
// import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import { RxCross2 } from "react-icons/rx";
import axios from "axios";
import Slider from "../view-funding-dashboard-items/verify/slider/slider";
import Publishslider from "../view-funding-dashboard-items/verify/slider/publishslider";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import "react-lazy-load-image-component/src/effects/blur.css";
import TablePagination from "@mui/material/TablePagination";
// import DeleteTrackDialog from "../../dialogs/delete-track-dialog/delete-track-dialog";
import { abbreviateNumber } from "../../utils/helper";
import SpotifyIcon from "../../assets/spotifyIcon.png";
import YoutubeIcon from "../../assets/youtubeIcon.png";
import {
  CustomCheckbox,
  CustomTable,
  CustomTableBody,
  CustomTableCell,
  CustomTableCellHeader,
  CustomTableContainer,
  CustomTableHead,
  CustomTableRow,
  // muiTableCellUseStyles,
} from "../../custom-mui-style/custom-mui-styles";
import { config as URLconfig } from "../../enviorment/enviorment";
import { IconButton, Skeleton, Tooltip } from "@mui/material";
import { openModal } from "../../redux/slice/modal";
import { toast } from "react-toastify";
import { getItemToLocalStorage } from "../../services/storage";
import Chip from "@mui/material/Chip";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
// import PencilIcon from "../../assets/buttonsicons/EditIcon.png";
// import DeleteIcon from "../../assets/buttonsicons/DeleteIcon.png";
import { useNavigate } from "react-router-dom";
import Pen from "../../assets/pen.svg";
import Delete from "../../assets/deleteIcon.svg";
import { ReactSVG } from "react-svg";
import DeleteConformationDialog from "../../dialogs/delete-conformation-dialog/delete-conformation-dialog";

export default function BasicTable({
  included_music,
  contract_length,
  catelog_income,
  new_music_income,
  calcalute_tracks_estimate,
  calcalute_Year_income_by_tracks,
  searchTracks,
  showPublishValue,
}) {
  // const cellUseStyles = muiTableCellUseStyles();
  // const cellUseStyles2 = muiTableCellUseStylesforBorderRedius();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dispatchref = useRef(dispatch);
  const artist = useSelector((state) => state.artist.artist);
  const tracks = useSelector((state) => state.artist.tracks);
  const selected = useSelector((state) => state.artist.selectedTracks);
  const newMusicTracks = useSelector((state) => state.artist.newMusicTracks);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const showSelectedTracksFunding = useSelector(
    (state) => state.artist.showSelectedTracksFunding
  );
  const status = useSelector((state) => state.artist.status);
  const [open, setOpen] = useState(false);
  const [selectedTrackToDelete, setSelectedTrackToDelete] = useState({});
  const reload = useSelector((state) => state.artist.reload);
  // const inputRef = useRef(null);
  const timeoutRef = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [remainingGenres, setRemainingGenres] = useState([]);
  // const classes = useStyles();
  useEffect(() => {
    dispatchref.current(setSelectedTrackCount(selected.length));
    dispatchref.current(setSelectedTracks(selected));
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      getArtistFunding();
    }, 1000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, dispatchref]);

  useEffect(() => {
    if (tracks.length && reload) {
      getArtistFunding();
      dispatchref.current(setReload(false));
    }
  }, [tracks, reload, dispatchref]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const isSelected = (id) => selected.includes(id);

  const handleSelectAllClick = (event) => {
    dispatch(setIsLoading(true));

    if (event.target.checked) {
      const newSelected = tracks.map((n) => n.id);
      dispatch(setSelectedTracks(newSelected));
      getArtistFunding();
      return;
    }

    dispatch(setSelectedTracks([]));
  };

  const handleSingleSelect = (id) => {
    dispatch(setIsLoading(true));

    if (isSelected(id)) {
      // console.log("unselected: ", id);
      dispatch(setSelectedTracks(selected.filter((ids) => ids !== id)));
    } else {
      // console.log("selected: ", id);
      dispatch(setSelectedTracks([...selected, id]));
    }
  };

  const onChangeHandler = async (track_id, stream_income_share) => {
    let update_tracks = tracks.map((elem) => {
      if (elem.id === track_id) {
        return {
          ...elem,
          stream_income_share,
        };
      }
      return elem;
    });
    dispatch(setTracks(update_tracks));
    dispatch(setIsLoading(true));

    let update_search_tracks = searchTracks.map((elem) => {
      if (elem.id === track_id) {
        return {
          ...elem,
          stream_income_share,
        };
      }
      return elem;
    });

    dispatch(setSearchResultTracks(update_search_tracks));

    dispatch(setReload(true));

    const payload = {
      stream_income_share,
    };

    let endpoint = `${URLconfig.BASE_URL}/artist-tracks/${track_id}/stream-income-share`;

    const token = getItemToLocalStorage("accessToken");
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };

    axios.put(endpoint, payload, config).then(() => {
      // console.log("success");
    });
  };
  const onChangeHandlerPublishing = async (
    track_id,
    publish_stream_income_share
  ) => {
    let update_tracks = tracks.map((elem) => {
      if (elem.id === track_id) {
        return {
          ...elem,
          publish_stream_income_share,
        };
      }
      return elem;
    });
    dispatch(setTracks(update_tracks));
    dispatch(setIsLoading(true));

    let update_search_tracks = searchTracks.map((elem) => {
      if (elem.id === track_id) {
        return {
          ...elem,
          publish_stream_income_share,
        };
      }
      return elem;
    });

    dispatch(setSearchResultTracks(update_search_tracks));

    dispatch(setReload(true));

    const payload = {
      publish_stream_income_share,
    };

    let endpoint = `${URLconfig.BASE_URL}/artist-tracks/${track_id}/publish_stream-income-share`;

    const token = getItemToLocalStorage("accessToken");
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };

    axios.put(endpoint, payload, config).then(() => {
      // console.log("success");
    });
  };
  function getSingleTrack(id) {
    return tracks.filter((track) => track.id === id)[0];
  }

  const getArtistFunding = async () => {
    const selected_tracks =
      selected.length > 0
        ? selected.map((e) => getSingleTrack(e))
        : showSelectedTracksFunding
        ? tracks
            .filter((track) => track.is_selected === 1)
            .map((checkedtracks) => checkedtracks)
        : tracks;
    const ids = selected_tracks.map((item) => item.id);

    const new_music = newMusicTracks.map((e) => getSingleTrack(e));

    dispatch(setShowSelectedTracksFunding(false));

    const val = {
      included_music,
      contract_length,
      catelog_income,
      new_music_income,
      selected_tracks: selected_tracks,
      new_music_tracks: new_music,
    };
    const data_for_year = {
      tracksId: ids,
    };
    calcalute_Year_income_by_tracks(data_for_year);

    calcalute_tracks_estimate(val);
  };

  const handleOpenDeleteDialog = (track) => {
    setSelectedTrackToDelete(track);
    setOpen(true);
  };

  const handleCloseDeleteDialog = (res) => {
    setOpen(false);
    if (res) {
      deleteTrack(selectedTrackToDelete?.id);
    }
  };

  const deleteTrack = async (trackId) => {
    let filter_tracks = tracks.filter((el) => el.id !== trackId);
    dispatch(setTracks(filter_tracks));
    dispatch(setTotalTracks(filter_tracks.length));

    let filter_selected_tracks = selected.filter((ids) => ids !== trackId);
    dispatch(setSelectedTracks(filter_selected_tracks));
    dispatch(setSelectedTrackCount(filter_selected_tracks.length));
    dispatch(setReload(true));

    const token = getItemToLocalStorage("accessToken");
    const config = {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };

    axios
      .delete(`${URLconfig.BASE_URL}/artist-tracks/${trackId}`, config)
      .then(() => {
        toast.success("Track has been removed");
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    height: "199px",
    bgcolor: "#222C41",
    borderRadius: "12px",
    boxShadow: 24,
  };

  return (
    <>
      <Box className={classess.page}>
        {artist && Object.keys(artist).length ? (
          <CustomTableContainer className={classess.table}>
            <CustomTable stickyHeader={true} aria-label="sticky table">
              <CustomTableHead>
                <CustomTableRow>
                  <CustomTableCellHeader
                    padding="checkbox"
                    className={classess.table__col}
                  >
                    <CustomCheckbox
                      indeterminate={
                        selected.length > 0 && selected.length < tracks.length
                      }
                      checked={
                        tracks.length > 0 && selected.length === tracks.length
                      }
                      onClick={handleSelectAllClick}
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                    />
                  </CustomTableCellHeader>
                  <CustomTableCellHeader></CustomTableCellHeader>
                  <CustomTableCellHeader>Title</CustomTableCellHeader>
                  <CustomTableCellHeader>Streams</CustomTableCellHeader>
                  <CustomTableCellHeader>Masters Shares</CustomTableCellHeader>
                  {showPublishValue && (
                    <CustomTableCellHeader>
                      Publishing Shares
                    </CustomTableCellHeader>
                  )}

                  <CustomTableCellHeader>Genres</CustomTableCellHeader>
                  <CustomTableCellHeader>Actions</CustomTableCellHeader>
                </CustomTableRow>
              </CustomTableHead>
              <CustomTableBody>
                {/* search tracks for look via map */}
                {searchTracks
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row?.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <React.Fragment key={index}>
                        <Box sx={{ m: "1rem" }} />
                        <CustomTableRow key={index}>
                          <CustomTableCell
                            padding="checkbox"
                            sx={{
                              borderTopLeftRadius: "12px",
                              borderEndStartRadius: "12px",
                            }}
                          >
                            <CustomCheckbox
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                              onClick={() => handleSingleSelect(row.id)}
                            />
                          </CustomTableCell>

                          <CustomTableCell>
                            {status === "succeeded" ? (
                              <Avatar
                                src={row?.image}
                                alt={row?.image}
                                className={classess.page__placement__avatar}
                              />
                            ) : (
                              <Skeleton
                                variant="circular"
                                width={45}
                                height={45}
                                sx={{ backgroundColor: "gray" }}
                              />
                            )}
                          </CustomTableCell>

                          <CustomTableCell>
                            <Box className={classess.page__placement}>
                              <Tooltip
                                title={row?.title}
                                key={index}
                                placement="top"
                                arrow
                                enterDelay={100}
                              >
                                <span>{row?.title}</span>
                              </Tooltip>
                            </Box>
                          </CustomTableCell>

                          <CustomTableCell>
                            <div
                              style={{
                                display: "flex",
                                cursor: "pointer",
                                color: "#fff",
                              }}
                              onClick={() =>
                                dispatch(
                                  openModal({
                                    name: "track-chart",
                                    data: row,
                                  })
                                )
                              }
                            >
                              {row?.last_streams_growth && (
                                <span
                                  className={
                                    row?.last_streams_growth?.growth_rate > 0
                                      ? classess.table__row__green
                                      : classess.table__row__red
                                  }
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyItems: "center",
                                      gap: "5px",
                                      fontSize: "16px",
                                    }}
                                  >
                                    <span>
                                      {row?.last_streams_growth?.growth_rate >
                                      0 ? (
                                        <TrendingUpIcon fontSize="16" />
                                      ) : (
                                        <TrendingDownIcon fontSize="16" />
                                      )}
                                    </span>
                                    <span
                                      style={{
                                        marginLeft: "2px",
                                        fontSize: "16px",
                                      }}
                                    >
                                      {row?.last_streams_growth?.growth_rate}%
                                    </span>
                                  </div>
                                </span>
                              )}
                              <span
                                style={{
                                  marginLeft: "5px",
                                  fontSize: "16px",
                                }}
                              >
                                {abbreviateNumber(
                                  row.last_streams_growth?.last_month
                                )}
                              </span>
                            </div>
                            <Tooltip
                              title={"Click to Edit"}
                              key={index}
                              placement="top"
                              arrow
                              enterDelay={100}
                            >
                              <Box
                                onClick={() =>
                                  dispatch(
                                    openModal({
                                      name: "edit-track",
                                      data: row,
                                    })
                                  )
                                }
                                sx={{ cursor: "pointer" }}
                              >
                                {(row?.spotify_streams_total === null ||
                                  row?.spotify_streams_total === 0) &&
                                (row?.youtube_video_views_total === null ||
                                  row?.youtube_video_views_total === 0) ? (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      flexWrap: "nowrap",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <Avatar
                                      src={YoutubeIcon}
                                      sx={{
                                        width: "20px",
                                        height: "auto",
                                        marginRight: "5px",
                                      }}
                                    />

                                    <Avatar
                                      src={SpotifyIcon}
                                      sx={{
                                        width: "20px",
                                        height: "20px",
                                        marginRight: "5px",
                                        marginLeft: "5px",
                                      }}
                                    />

                                    <span
                                      style={{
                                        marginTop: "5px",
                                        fontSize: "12px",
                                        color: "#fff",
                                      }}
                                    >
                                      Missing Data
                                    </span>
                                  </Box>
                                ) : (
                                  <span>
                                    {row?.spotify_streams_total === null ||
                                    row?.spotify_streams_total === 0 ? (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                          flexWrap: "nowrap",
                                        }}
                                      >
                                        <Avatar
                                          src={SpotifyIcon}
                                          sx={{
                                            width: "20px",
                                            height: "20px",
                                            marginRight: "5px",
                                          }}
                                        />
                                        <span
                                          style={{
                                            marginTop: "5px",
                                            fontSize: "12px",
                                            color: "#fff",
                                          }}
                                        >
                                          Missing Data
                                        </span>
                                      </Box>
                                    ) : null}

                                    {row?.youtube_video_views_total === null ||
                                    row?.youtube_video_views_total === 0 ? (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                          flexWrap: "nowrap",
                                        }}
                                      >
                                        <Avatar
                                          src={YoutubeIcon}
                                          sx={{
                                            width: "20px",
                                            height: "20px",
                                            marginRight: "5px",
                                          }}
                                        />
                                        <span
                                          style={{
                                            marginTop: "5px",
                                            fontSize: "12px",
                                            color: "#fff",
                                          }}
                                        >
                                          Missing Data
                                        </span>
                                      </Box>
                                    ) : null}
                                  </span>
                                )}
                              </Box>
                            </Tooltip>
                          </CustomTableCell>

                          <CustomTableCell>
                            <Slider
                              onChangeHandler={onChangeHandler}
                              row={row}
                            />
                          </CustomTableCell>
                          {showPublishValue && (
                            <CustomTableCell>
                              <Publishslider
                                onChangeHandler={onChangeHandlerPublishing}
                                row={row}
                              />
                            </CustomTableCell>
                          )}

                          <CustomTableCell>
                            <Box className={classess.table__row__genre}>
                              {row.genres
                                ? row.genres
                                    .split(",")
                                    .slice(0, 2)
                                    .map((genre, index) => (
                                      <React.Fragment key={index}>
                                        {index > 0 && "  "}
                                        <Tooltip
                                          title={genre}
                                          placement="top"
                                          arrow
                                          enterDelay={100}
                                        >
                                          <Chip
                                            label={
                                              genre.length > 12
                                                ? `${genre.slice(0, 12)}...`
                                                : genre
                                            }
                                            size="small"
                                            className={
                                              classess.table__row__genre__chip
                                            }
                                          />
                                        </Tooltip>
                                      </React.Fragment>
                                    ))
                                : ""}
                              {row.genres &&
                                row.genres.split(",").length > 2 && (
                                  <React.Fragment>
                                    <Tooltip
                                      title="Remaining Genres"
                                      placement="top"
                                      arrow
                                      enterDelay={100}
                                    >
                                      <Chip
                                        label={`+${
                                          row.genres.split(",").length
                                        }`}
                                        size="small"
                                        className={
                                          classess.table__row__genre__chip
                                        }
                                        onClick={() => {
                                          const remaining = row.genres
                                            .split(",")
                                            .map((genre) => genre.trim());
                                          setRemainingGenres(remaining);
                                          setModalOpen(true);
                                        }}
                                        style={{ cursor: "pointer" }}
                                      />
                                    </Tooltip>
                                  </React.Fragment>
                                )}
                            </Box>
                          </CustomTableCell>

                          <CustomTableCell
                            sx={{
                              borderTopRightRadius: "12px",
                              borderEndEndRadius: "12px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                gap: "10px",
                              }}
                            >
                              <IconButton
                                className={classess.actionEdit}
                                onClick={() =>
                                  dispatch(
                                    openModal({
                                      name: "edit-track",
                                      data: row,
                                    })
                                  )
                                }
                              >
                                <ReactSVG src={Pen} />
                              </IconButton>
                              <IconButton
                                className={classess.actionDelete}
                                onClick={() => handleOpenDeleteDialog(row)}
                              >
                                <ReactSVG src={Delete} />
                              </IconButton>
                            </Box>
                          </CustomTableCell>
                        </CustomTableRow>
                      </React.Fragment>
                    );
                  })}

                {/* if artist tracks 0 in the search, then loop the all tracks */}
                {searchTracks.length < 1 &&
                  tracks
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row?.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <React.Fragment key={index}>
                          <Box sx={{ m: "1rem" }} />
                          <CustomTableRow key={index}>
                            <CustomTableCell
                              padding="checkbox"
                              sx={{
                                borderTopLeftRadius: "12px",
                                borderEndStartRadius: "12px",
                              }}
                            >
                              <CustomCheckbox
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                                onClick={() => handleSingleSelect(row.id)}
                              />
                            </CustomTableCell>

                            <CustomTableCell>
                              {status === "succeeded" ? (
                                <Avatar
                                  src={row?.image}
                                  alt={row?.image}
                                  className={classess.page__placement__avatar}
                                />
                              ) : (
                                <Skeleton
                                  variant="circular"
                                  width={45}
                                  height={45}
                                  sx={{ backgroundColor: "gray" }}
                                />
                              )}
                            </CustomTableCell>

                            <CustomTableCell>
                              <Box className={classess.page__placement}>
                                <Tooltip
                                  title={row?.title}
                                  key={index}
                                  placement="top"
                                  arrow
                                  enterDelay={100}
                                >
                                  <span>{row?.title}</span>
                                </Tooltip>
                              </Box>
                            </CustomTableCell>

                            <CustomTableCell>
                              <div
                                style={{
                                  display: "flex",
                                  cursor: "pointer",
                                  color: "#fff",
                                }}
                                onClick={() =>
                                  dispatch(
                                    openModal({
                                      name: "track-chart",
                                      data: row,
                                    })
                                  )
                                }
                              >
                                {row?.last_streams_growth && (
                                  <span
                                    className={
                                      row?.last_streams_growth?.growth_rate > 0
                                        ? classess.table__row__green
                                        : classess.table__row__red
                                    }
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyItems: "center",
                                        gap: "5px",
                                        fontSize: "16px",
                                      }}
                                    >
                                      <span>
                                        {row?.last_streams_growth?.growth_rate >
                                        0 ? (
                                          <TrendingUpIcon fontSize="16" />
                                        ) : (
                                          <TrendingDownIcon fontSize="16" />
                                        )}
                                      </span>
                                      <span
                                        style={{
                                          marginLeft: "2px",
                                          fontSize: "16px",
                                        }}
                                      >
                                        {row?.last_streams_growth?.growth_rate}%
                                      </span>
                                    </div>
                                  </span>
                                )}
                                <span
                                  style={{
                                    marginLeft: "5px",
                                    fontSize: "16px",
                                  }}
                                >
                                  {abbreviateNumber(
                                    row.last_streams_growth?.last_month
                                  )}
                                </span>
                              </div>
                              <Tooltip
                                title={"Click to Edit"}
                                key={index}
                                placement="top"
                                arrow
                                enterDelay={100}
                              >
                                <Box
                                  onClick={() =>
                                    dispatch(
                                      openModal({
                                        name: "edit-track",
                                        data: row,
                                      })
                                    )
                                  }
                                  sx={{ cursor: "pointer" }}
                                >
                                  {(row?.spotify_streams_total === null ||
                                    row?.spotify_streams_total === 0) &&
                                  (row?.youtube_video_views_total === null ||
                                    row?.youtube_video_views_total === 0) ? (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        flexWrap: "nowrap",
                                        marginTop: "10px",
                                      }}
                                    >
                                      <Avatar
                                        src={YoutubeIcon}
                                        sx={{
                                          width: "20px",
                                          height: "auto",
                                          marginRight: "5px",
                                        }}
                                      />

                                      <Avatar
                                        src={SpotifyIcon}
                                        sx={{
                                          width: "20px",
                                          height: "20px",
                                          marginRight: "5px",
                                          marginLeft: "5px",
                                        }}
                                      />

                                      <span
                                        style={{
                                          marginTop: "5px",
                                          fontSize: "12px",
                                          color: "#fff",
                                        }}
                                      >
                                        Missing Data
                                      </span>
                                    </Box>
                                  ) : (
                                    <span>
                                      {row?.spotify_streams_total === null ||
                                      row?.spotify_streams_total === 0 ? (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            flexWrap: "nowrap",
                                          }}
                                        >
                                          <Avatar
                                            src={SpotifyIcon}
                                            sx={{
                                              width: "20px",
                                              height: "20px",
                                              marginRight: "5px",
                                            }}
                                          />
                                          <span
                                            style={{
                                              marginTop: "5px",
                                              fontSize: "12px",
                                              color: "#fff",
                                            }}
                                          >
                                            Missing Data
                                          </span>
                                        </Box>
                                      ) : null}

                                      {row?.youtube_video_views_total ===
                                        null ||
                                      row?.youtube_video_views_total === 0 ? (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            flexWrap: "nowrap",
                                          }}
                                        >
                                          <Avatar
                                            src={YoutubeIcon}
                                            sx={{
                                              width: "20px",
                                              height: "20px",
                                              marginRight: "5px",
                                            }}
                                          />
                                          <span
                                            style={{
                                              marginTop: "5px",
                                              fontSize: "12px",
                                              color: "#fff",
                                            }}
                                          >
                                            Missing Data
                                          </span>
                                        </Box>
                                      ) : null}
                                    </span>
                                  )}
                                </Box>
                              </Tooltip>
                            </CustomTableCell>

                            <CustomTableCell>
                              <Slider
                                onChangeHandler={onChangeHandler}
                                row={row}
                              />
                            </CustomTableCell>
                            {showPublishValue && (
                              <CustomTableCell>
                                <Publishslider
                                  onChangeHandler={onChangeHandlerPublishing}
                                  row={row}
                                />
                              </CustomTableCell>
                            )}

                            <CustomTableCell>
                              <Box className={classess.table__row__genre}>
                                {row.genres
                                  ? row.genres
                                      .split(",")
                                      .slice(0, 2)
                                      .map((genre, index) => (
                                        <React.Fragment key={index}>
                                          {index > 0 && "  "}
                                          <Tooltip
                                            title={genre}
                                            placement="top"
                                            arrow
                                            enterDelay={100}
                                          >
                                            <Chip
                                              label={
                                                genre.length > 12
                                                  ? `${genre.slice(0, 12)}...`
                                                  : genre
                                              }
                                              size="small"
                                              className={
                                                classess.table__row__genre__chip
                                              }
                                            />
                                          </Tooltip>
                                        </React.Fragment>
                                      ))
                                  : ""}
                                {row.genres &&
                                  row.genres.split(",").length > 2 && (
                                    <React.Fragment>
                                      <Tooltip
                                        title="Remaining Genres"
                                        placement="top"
                                        arrow
                                        enterDelay={100}
                                      >
                                        <Chip
                                          label={`+${
                                            row.genres.split(",").length
                                          }`}
                                          size="small"
                                          className={
                                            classess.table__row__genre__chip
                                          }
                                          onClick={() => {
                                            const remaining = row.genres
                                              .split(",")
                                              .map((genre) => genre.trim());
                                            setRemainingGenres(remaining);
                                            setModalOpen(true);
                                          }}
                                          style={{ cursor: "pointer" }}
                                        />
                                      </Tooltip>
                                    </React.Fragment>
                                  )}
                              </Box>
                            </CustomTableCell>

                            <CustomTableCell
                              sx={{
                                borderTopRightRadius: "12px",
                                borderEndEndRadius: "12px",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "10px",
                                }}
                              >
                                <Tooltip
                                  title="Edit Track"
                                  placement="top"
                                  arrow
                                  enterDelay={100}
                                >
                                  <IconButton
                                    className={classess.actionEdit}
                                    onClick={() =>
                                      dispatch(
                                        openModal({
                                          name: "edit-track",
                                          data: row,
                                        })
                                      )
                                    }
                                  >
                                    <ReactSVG src={Pen} />
                                  </IconButton>
                                </Tooltip>

                                <Tooltip
                                  title="Delete Track"
                                  placement="top"
                                  arrow
                                  enterDelay={100}
                                >
                                  <IconButton
                                    className={classess.actionDelete}
                                    onClick={() => handleOpenDeleteDialog(row)}
                                  >
                                    <ReactSVG src={Delete} />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </CustomTableCell>
                          </CustomTableRow>
                        </React.Fragment>
                      );
                    })}
              </CustomTableBody>
            </CustomTable>
          </CustomTableContainer>
        ) : (
          <Box varient="div" component="div" sx={{ p: 4 }}>
            <Typography
              variant="h5"
              gutterBottom
              component="div"
              sx={{ color: "#d6d6d6" }}
            >
              Waiting for the Response...
            </Typography>
          </Box>
        )}
        {/* Show only, all tracks */}
        {searchTracks.length < 1 ? (
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            sx={{ color: "#d6d6d6" }}
            count={tracks.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            classes={{
              actions: "custom-pagination-actions",
              select: "custom-pagination-select",
              displayedRows: "custom-select-style",
              selectLabel: "custom-select-style",
            }}
            SelectProps={{
              classes: {
                select: "custom-select", // Apply the custom-select class to the Select component
              },
            }}
          />
        ) : null}

        {searchTracks.length > 1 ? (
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            sx={{ color: "#d6d6d6" }}
            count={searchTracks.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            classes={{
              actions: "custom-pagination-actions",
              select: "custom-pagination-select",
              displayedRows: "custom-select-style",
              selectLabel: "custom-select-style",
            }}
            SelectProps={{
              classes: {
                select: "custom-select", // Apply the custom-select class to the Select component
              },
            }}
          />
        ) : null}
        {/* <DeleteTrackDialog
          onClose={handleCloseDeleteDialog}
          open={open}
          track={selectedTrackToDelete}
        />{" "} */}
        <DeleteConformationDialog
          onClose={handleCloseDeleteDialog}
          open={open}
          artist={selectedTrackToDelete}
        />
      </Box>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={classess.modalCss}>
          <Box className={classess.modalCss__heading}>
            Genres
            <Box className={classess.modalCss__heading__icon}>
              <IconButton
                className={classess.modalCss__heading__icon__inner}
                onClick={() => setModalOpen(false)}
              >
                <RxCross2 />
              </IconButton>
            </Box>
          </Box>
          <Box
            sx={{
              p: 3,
              display: "flex",
              justifyContent: "center",
              gap: "10px",
              flexWrap: "wrap",
              backgroundColor: "#222C41",
              borderRadius: "12px",
            }}
          >
            {remainingGenres.map((genre, index) => (
              <>
                <Chip
                  key={index}
                  label={genre}
                  sx={{
                    backgroundColor: "#5A7380",
                    color: "#fff",
                    cursor: "pointer",
                    fontSize: "11px",
                  }}
                  style={{ marginBottom: "5px" }}
                />
              </>
            ))}
          </Box>
        </Box>
      </Modal>
    </>
  );
}
