import React from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AandRActivityList from "../../components/aandr-activity-list/AandRActivityList";

// Custom MUI Styles
import { viewArtistUseStyles } from "../../custom-mui-style/custom-mui-styles";

const AandRActivity = () => {
  const styles = viewArtistUseStyles();
  return (
    <Container maxWidth="xxl" className={styles.root}>
      <Grid
        container
        sm={12}
        md={12}
        lg={12}
        xl={12}
        rowSpacing={2}
        columnSpacing={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}
      >
        <Grid item sm={12} md={12} lg={12} xl={10}>
          <AandRActivityList />
        </Grid>
      </Grid>
    </Container>
  );
};

export default AandRActivity;
