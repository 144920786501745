import React, { useState } from "react";
import { useSelector } from "react-redux";

import classes from "./style.module.scss"; // Importing module CSS

import Box from "@mui/material/Box"; // Material UI Box component
import Typography from "@mui/material/Typography"; // Material UI Typography component

import CustomThreeTabs from "../../../components/custom-three-tabs/custom-three-tabs";

import RevenueGraph from "../revenuegraph/RevenueGraph";
import SocialMediaGraph from "../socialMediaGraph/SocialMediaGraph";
import CareerGraph from "../../../containers/graph/Careergraph/CareerGraph";

const PlatformComparison = () => {
  const [activeTab, setActiveTab] = useState("Streams");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <Box component="div" variant="div" className={classes.page}>
      <Box component="div" variant="div" className={classes.page__container}>
        <Box
          component="div"
          variant="div"
          className={classes.page__container__header}
        >
          <Typography className={classes.page__container__header__heading}>
            Platform Comparison
          </Typography>
          <CustomThreeTabs
            text_0={"Streams"}
            text_1={"Social Media"}
            text_2={"Career"}
            click_0={() => handleTabClick("Streams")}
            click_1={() => handleTabClick("Social Media")}
            click_2={() => handleTabClick("Career")}
            btn_0={activeTab === "Streams"}
            btn_1={activeTab === "Social Media"}
            btn_2={activeTab === "Career"}
          />
        </Box>

        <Box
          component="div"
          variant="div"
          className={classes.page__container__innerContainer}
        >
          {activeTab === "Streams" && <RevenueGraph />}

          {activeTab === "Social Media" && <SocialMediaGraph />}

          {activeTab === "Career" && <CareerGraph />}
        </Box>
      </Box>
    </Box>
  );
};

export default PlatformComparison;
