import React from "react";
import classess from "./style.module.scss";
import { Box } from "@mui/material";
import TabsSearch from "./tabs-search/tabs-search";
import TrendingNowList from "./trending-now-list/trending-now-list";
const SearchAddArtist = () => {
  return (
    <Box className={classess.page}>
      <TabsSearch />
      <TrendingNowList />
    </Box>
  );
};

export default SearchAddArtist;
