import React, { useEffect, useState } from "react";
import classess from "./style.module.scss";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";
import { config as URLconfig } from "../../enviorment/enviorment";
import axios from "axios";
import ContractHistory from "../../components/ContractHistory/ContractHistory";
import PostAddIcon from "@mui/icons-material/PostAdd";
import Avatar from "@mui/material/Avatar";
import { getItemToLocalStorage } from "../../services/storage";
import AuthEnum from "../../enums/auth.enum";

const PreviewContract = () => {
  const { id } = useParams();
  const [contract, setContract] = useState(null);
  // const [updateContract, setUpdateContract] = useState(null);
  const [open, setOpen] = useState(false);
  // console.log("contract", contract);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(value);
  };

  useEffect(() => {
    let isApiSubscribed = true;
    const token = getItemToLocalStorage(AuthEnum.TOKEN);
    const authConfig = {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(`${URLconfig.BASE_URL}/contracts/${id}`, authConfig)
      .then((res) => {
        if (isApiSubscribed) {
          setContract(res.data.data);
          // setUpdateContract(res?.data?.data?.notes);

          // setUpdateContract(res.data.data.notes);
        }
      });

    return () => {
      isApiSubscribed = false;
    };
  }, [id]);
  // console.log("contract", contract);

  // const updateNotes = (newContract) => {
  //   setUpdateContract([...contract.notes, newContract?.data]);
  // };
  const updateNotes = (newContract) => {
    setContract((prevContract) => ({
      ...prevContract,
      notes: [...prevContract.notes, newContract?.data],
    }));
  };
  // console.log("updateContract", updateContract);
  return (
    <Container maxWidth="xxl">
      <Grid container spacing={2} className={classess.page}>
        <Grid
          item
          sm={12}
          md={12}
          lg={12}
          xl={9}
          className={classess.page__details}
        >
          <Box
            varient="div"
            component="div"
            className={classess.page__details__box}
          >
            <Box
              varient="div"
              component="div"
              className={classess.page__details__box__adetails}
            >
              <Box
                varient="div"
                component="div"
                className={classess.page__details__box__adetails__header}
              >
                <Box
                  varient="div"
                  component="div"
                  className={
                    classess.page__details__box__adetails__header__img_title_container
                  }
                >
                  <Avatar
                    className={
                      classess.page__details__box__adetails__header__img_title_container__image
                    }
                    src={contract?.artist?.avatar}
                    alt={contract?.name}
                  />
                  <span
                    className={
                      classess.page__details__box__adetails__header__img_title_container__title
                    }
                  >
                    {contract?.artist_name}
                  </span>
                </Box>

                <Button
                  sx={{ color: "#000" }}
                  startIcon={<PostAddIcon />}
                  className={
                    classess.page__details__box__adetails__header__amendbtn
                  }
                  onClick={handleOpen}
                >
                  Add Revisions
                </Button>
              </Box>
              <ContractHistory
                updateNotes={updateNotes}
                // updateContract={updateContract}
                contract={contract}
                open={open}
                onvaluechange={handleClose}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PreviewContract;
