/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import classess from "./style.module.scss";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getArtistById } from "../../redux/slice/artist";
import { config as URLconfig } from "../../enviorment/enviorment";
import { IconButton } from "@mui/material";
import BackIcon from "../../assets/BackIcon.png";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import axios from "axios";
import EyeIcon from "../../assets/buttonsicons/EyeIcon.png";
import Chip from "@mui/material/Chip";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import moment from "moment";
import NewLoader from "../lottie-loader/Loader.json";
import Lottie from "lottie-react";

const ArtistAllTracks = ({
  included_music,
  contract_length,
  catelog_income,
  new_music_income,
  calcalute_tracks_estimate,
  calcalute_Year_income_by_tracks,
  searchTracks,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const artist = useSelector((state) => state.artist.artist);
  const [tracks, settracks] = useState([]);
  const BASE_URL = URLconfig.BASE_URL;
  const [setSocialLinks] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [remainingGenres, setRemainingGenres] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if (id && !artist) {
      initUIData();
    }
  }, [id, artist]);

  const initUIData = () => {
    dispatch(
      getArtistById({
        id,
      })
    );
  };

  useEffect(() => {
    if (artist && Object.keys(artist).length) {
      getTopTracks(artist?.spotify_id);
    }
  }, [artist]);

  const getTopTracks = (spoid) => {
    fetch(`${BASE_URL}/artist-tracks/${spoid}`)
      .then(async (res) => {
        const artistTacks = await res.json();
        settracks(artistTacks.data);
      })
      .catch((error) => {
        console.log("Error Of GetTracks " + error);
      });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    height: "199px",
    bgcolor: "#222C41",
    borderRadius: "12px",
    boxShadow: 24,
  };

  // const onChangeHandler = async (track_id, stream_income_share) => {
  //   let update_tracks = tracks.map((elem) => {
  //     if (elem.id === track_id) {
  //       return {
  //         ...elem,
  //         stream_income_share,
  //       };
  //     }
  //     return elem;
  //   });
  //   dispatch(setTracks(update_tracks));

  //   let update_search_tracks = searchTracks.map((elem) => {
  //     if (elem.id === track_id) {
  //       return {
  //         ...elem,
  //         stream_income_share,
  //       };
  //     }
  //     return elem;
  //   });

  //   dispatch(setSearchResultTracks(update_search_tracks));

  //   dispatch(setReload(true));

  //   const payload = {
  //     stream_income_share,
  //   };

  //   let endpoint = `${URLconfig.BASE_URL}/artist-tracks/${track_id}/stream-income-share`;

  //   const token = getItemToLocalStorage("accessToken");
  //   const config = {
  //     headers: {
  //       authorization: `Bearer ${token}`,
  //     },
  //   };

  //   axios.put(endpoint, payload, config).then(() => {
  //     console.log("success");
  //   });
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Container maxWidth="xxl">
      <Grid container spacing={2} className={classess.page}>
        <Grid
          item
          sm={12}
          md={12}
          lg={12}
          xl={9}
          className={classess.page__details}
        >
          <Box
            varient="div"
            component="div"
            className={classess.page__details__box}
          >
            <Box
              varient="div"
              component="div"
              className={classess.page__details__box__tracks}
            >
              {tracks && tracks.length > 0 ? (
                <Box
                  className={classess.page__details__box__tracks__header}
                  varient="div"
                  component="div"
                >
                  <Box
                    className={
                      classess.page__details__box__tracks__header__back_icon
                    }
                  >
                    <IconButton
                      onClick={() => {
                        navigate(-1);
                      }}
                      style={{
                        backgroundColor: "#4ffcb7",
                        height: "21px",
                        width: "21px",
                      }}
                    >
                      <img
                        src={BackIcon}
                        alt="Eye"
                        style={{
                          height: "9.92px",
                          width: "5.86px",
                        }}
                      />
                    </IconButton>
                  </Box>

                  <Box
                    className={
                      classess.page__details__box__tracks__header__img_title_container
                    }
                  >
                    <Box
                      className={
                        classess.page__details__box__tracks__header__img_title_container__img
                      }
                    >
                      <Avatar
                        src={artist?.avatar}
                        alt={artist?.avatar}
                        sx={{
                          height: 65,
                          width: 65,
                          // transform: "translateX(-12px)",

                          ":hover": {
                            cursor: "pointer",
                          },
                        }}
                      />
                    </Box>
                    <Box
                      className={
                        classess.page__details__box__tracks__header__img_title_container__title
                      }
                    >
                      {artist?.name}
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "42px",
                      height: "42px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Lottie
                      animationData={NewLoader}
                      className={classess.page__loader}
                    />
                  </Box>
                </Box>
              )}

              {/* {tracks && tracks.length > 0 ? ( */}
              <TableContainer className={classess.table}>
                <Table stickyHeader={true} aria-label="sticky table">
                  <TableHead className={classess.table__head}>
                    <TableRow>
                      <TableCell
                        className={classess.table__col}
                        sx={{ maxWidth: 50 }}
                      >
                        {/* <span className={classess.table__col__heading}></span> */}
                      </TableCell>
                      <TableCell className={classess.table__col}>
                        <span className={classess.table__col__heading}>
                          TITLE
                        </span>
                      </TableCell>

                      <TableCell className={classess.table__col}>
                        <span className={classess.table__col__heading}>
                          Release Date
                        </span>
                      </TableCell>

                      <TableCell className={classess.table__col}>
                        <span className={classess.table__col__heading}>
                          Genre
                        </span>
                      </TableCell>

                      <TableCell className={classess.table__col}>
                        <span className={classess.table__col__heading}>
                          % OWNERSHIP
                        </span>
                      </TableCell>
                      <TableCell className={classess.table__col}>
                        <span></span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tracks.map((track, idx) => (
                      <React.Fragment>
                        <Box sx={{ m: "1rem" }}></Box>
                        <TableRow
                          key={idx}
                          // sx={{
                          //   "&:last-child td, &:last-child th": {
                          //     border: 0,
                          //   },
                          // }}
                        >
                          <TableCell
                            className={classess.table__row}
                            sx={{
                              maxWidth: 50,
                              borderTopLeftRadius: "12px",
                              borderBottomLeftRadius: "12px",
                            }}
                          >
                            <Avatar
                              src={track?.track_img}
                              alt={track?.track_img}
                              onClick={() => {
                                navigate(`/blig/track/${track?._id}`);
                              }}
                              sx={{
                                height: 50,
                                width: 50,
                                transform: "translateX(-12px)",
                                ":hover": {
                                  cursor: "pointer",
                                },
                              }}
                            />
                          </TableCell>

                          <TableCell className={classess.table__row}>
                            <Tooltip
                              title={track?.title}
                              placement="top"
                              arrow
                              enterDelay={100}
                            >
                              <span
                                className={classess.table__row__name}
                                onClick={() => {
                                  // navigate(`/blig/track/${track?._id}`);
                                }}
                              >
                                {track?.title}
                              </span>
                            </Tooltip>
                          </TableCell>

                          <TableCell className={classess.table__row}>
                            <span className={classess.table__row__release_date}>
                              {/* {} */}
                              {moment(track?.release_date).format(
                                "DD-MMM-YYYY"
                              )}
                            </span>
                          </TableCell>

                          {/* <TableCell className={classess.table__row}>
                            <Slider
                              onChangeHandler={onChangeHandler}
                              row={track}
                            />
                          </TableCell> */}

                          <TableCell className={classess.table__row}>
                            <Box className={classess.table__row__genre}>
                              {track.genres
                                ? track.genres
                                    .split(",")
                                    .slice(0, 2)
                                    .map((genre, index) => (
                                      <React.Fragment key={index}>
                                        {index > 0 && "  "}
                                        <Chip
                                          label={genre.trim()}
                                          size="small"
                                          className={
                                            classess.table__row__genre__chip
                                          }
                                        />
                                      </React.Fragment>
                                    ))
                                : ""}
                              {track.genres &&
                                track.genres.split(",").length > 2 && (
                                  <React.Fragment>
                                    <Chip
                                      label={`+${
                                        track.genres.split(",").length - 2
                                      }`}
                                      size="small"
                                      className={
                                        classess.table__row__genre__chip
                                      }
                                      onClick={() => {
                                        const remaining = track.genres
                                          .split(",")
                                          .slice(0)
                                          .map((genre) => genre.trim());
                                        setRemainingGenres(remaining);
                                        setModalOpen(true);
                                      }}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </React.Fragment>
                                )}
                            </Box>
                          </TableCell>

                          <TableCell className={classess.table__row}>
                            <span className={classess.table__row__own}>
                              {track?.stream_income_share}
                              {"%"}
                            </span>
                          </TableCell>

                          <TableCell
                            className={classess.table__row}
                            sx={{
                              borderBottomRightRadius: "12px",
                              borderTopRightRadius: "12px",
                            }}
                          >
                            <IconButton
                              onClick={() => {
                                // navigate(`/blig/track/${track?._id}`);
                              }}
                              style={{
                                backgroundColor: "#4ffcb7",
                                height: "30px",
                                width: "30px",
                              }}
                            >
                              <img
                                src={EyeIcon}
                                alt="Eye"
                                style={{
                                  height: "12px",
                                  width: "16px",
                                }}
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {tracks.length < 1 ? null : (
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  sx={{ color: "#d6d6d6" }}
                  count={tracks.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  classes={{
                    actions: "custom-pagination-actions",
                    select: "custom-pagination-select",
                    displayedRows: "custom-select-style",
                    selectLabel: "custom-select-style",
                  }}
                  SelectProps={{
                    classes: {
                      select: "custom-select", // Apply the custom-select class to the Select component
                    },
                  }}
                />
              )}
              {/* ) : null} */}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={classess.modalCss}>
          <Box className={classess.modalCss__heading}>Genres </Box>
          <Box
            sx={{
              pt: 3,
              pl: 3,
              pr: 3,
              pb: 1,
              color: "#ffffff",
              fontSize: "14px",
              textAlign: "center",
            }}
          >
            {remainingGenres.map((genre, index) => (
              <Chip
                key={index}
                label={genre}
                size="small"
                sx={{
                  backgroundColor: "#5A7380 !important",
                  color: "white",
                  fontSize: "12px",
                }}
                style={{ marginBottom: "5px" }}
              />
            ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 6,
            }}
          >
            <Button
              className={classess.modalCss__button}
              onClick={() => setModalOpen(false)}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </Container>
  );
};

export default ArtistAllTracks;
