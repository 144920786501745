import React, { useEffect, useState } from "react";
import { Box, Typography, Avatar } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { addCommasToNumber } from "../../../utils/helper";
import classes from "./style.module.scss";

import SpotifyIcon from "../../../assets/social/social-icon3.png";
import YoutubeIcon from "../../../assets/social/social-icon2.png";
import DeezerIcon from "../../../assets/social/social-icon5.png";
import SoundCloudIcon from "../../../assets/social/social-icon4.png";
import AppleMusicIcon from "../../../assets/social/social-icon1.png";
import TiktokIcon from "../../../assets/social/social-icon8.png";

import { useSelector } from "react-redux";
import axios from "axios";
import { config as URLconfig } from "../../../enviorment/enviorment";

const RevenueGraph = () => {
  const [spotifyData, setSpotifyData] = useState(0);
  const [soundCloudData, setSoundCloudData] = useState(0);
  const [appleMusicData, setAppleMusicData] = useState(0);
  const [deezerData, setDeezerData] = useState(0);
  const [youtubeData, setYoutubeData] = useState(0);
  const [tiktokData, settiktokData] = useState(0);
  const artist = useSelector((state) => state.artist.artist);

  useEffect(() => {
    if (artist) {
      axios
        .get(`${URLconfig.BASE_URL}/artist-stats/${artist?.spotify_id}`)
        .then((res) => {
          const data = res?.data?.data;

          let deezer = data.stats.filter((el) => el.source === "deezer");
          let youtube = data.stats.filter((el) => el.source === "youtube");
          let soundcloud = data.stats.filter(
            (el) => el.source === "soundcloud"
          );
          let spotify = data.stats.filter((el) => el.source === "spotify");
          let tiktok = data.stats.filter((el) => el.source === "tiktok");
          // let apple_music = data.stats.filter(
          //   (el) => el.source === "apple_music"
          // );

          setDeezerData(deezer[0].data.playlist_reach_total);
          setYoutubeData(youtube[0].data.video_views_total);
          setSoundCloudData(soundcloud[0].data.streams_total);
          setSpotifyData(spotify[0].data.streams_total);
          settiktokData(tiktok[0].data.views_total);
        })
        .catch(console.error);
    }
  }, [artist]);

  const platformData = [
    {
      name: "Spotify",
      icon: SpotifyIcon,
      data: spotifyData,
      dec: "Streams",
    },
    {
      name: "YouTube",
      icon: YoutubeIcon,
      data: youtubeData,
      dec: "Views",
    },
    {
      name: "SoundCloud",
      icon: SoundCloudIcon,
      data: soundCloudData,
      dec: "Streams",
    },
    {
      name: "Apple Music",
      icon: AppleMusicIcon,
      data: appleMusicData,
      dec: "Streams",
    },
    { name: "Deezer", icon: DeezerIcon, data: deezerData, dec: "Streams" },
    { name: "Tiktok", icon: TiktokIcon, data: tiktokData, dec: "Views" },
  ];

  const filteredPlatformData = platformData.filter((platform) => platform.data);

  const chartData = filteredPlatformData.map((platform) => ({
    icon: platform.icon,
    streams: new Intl.NumberFormat().format(platform.data),
    subStreams: platform.dec,
  }));

  const chartOptions = {
    series: [
      {
        name: "Platform Data",
        data: filteredPlatformData.map((platform) =>
          platform.data > 0 ? Math.log10(platform.data) : 0
        ),
      },
    ],
    chart: {
      type: "radar",
      toolbar: { show: false },
      events: {
        dataPointMouseEnter: function (event, chartContext, config) {
          chartContext.tooltip.show();
        },
      },
    },
    title: { text: "", align: "center" },
    stroke: { show: false, width: 2, colors: [], dashArray: 0 },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "diagonal2",
        shadeIntensity: 1,
        opacityFrom: 0.9,
        opacityTo: 1,

        colorStops: [
          {
            offset: 0,
            color: "#FFFF17",
            opacity: 1,
          },
          {
            offset: 20,
            color: "#FFFF17",
            opacity: 0.9,
          },
          {
            offset: 50,
            color: "#FF6161",
            opacity: 0.9,
          },
          {
            offset: 100,
            color: "#AB00FF",
            opacity: 0.9,
          },
        ],
      },
    },
    xaxis: {
      labels: { show: true },
      categories: filteredPlatformData.map((platform) => platform.name),
    },
    yaxis: { show: false },
    dataLabels: { enabled: false },
    plotOptions: {
      radar: {
        size: 90,
        offsetX: 0,
        offsetY: -30,
        polygons: {
          strokeColor: "#999999",
          fill: { colors: ["#5A7380", "#5A7380"] },
        },
      },
    },
    tooltip: {
      enabled: true,
      hideEmptySeries: true,
      theme: "dark",
      highlightDataSeries: true,
      style: {
        fontFamily: "DM Sans",
        fontSize: "12px",
      },
      x: {
        show: false,
      },
      y: {
        formatter: (val, { series, seriesIndex, dataPointIndex, w }) => {
          const platformName = filteredPlatformData[dataPointIndex].name;
          const dataValue = filteredPlatformData[dataPointIndex].data;

          const term = ["Tiktok", "YouTube"].includes(platformName)
            ? "Views"
            : "Streams";

          return `${platformName}: ${new Intl.NumberFormat().format(
            dataValue
          )} ${term}`;
        },
        title: {
          formatter: () => {
            return "";
          },
        },
      },
      z: {
        formatter: undefined,
        title: "Size: ",
      },
      marker: {
        show: false,
      },
      items: {
        display: "flex",
      },
      fixed: {
        enabled: false,
        position: "BottomRight",
        offsetX: 0,
        offsetY: 0,
      },
      title: {
        formatter: (seriesName, { seriesIndex, dataPointIndex, w }) => {
          return "";
        },
      },
    },
  };

  return (
    <Box component="div" className={classes.page}>
      <Typography className={classes.page__topHeading}>
        Total Streaming Stats
      </Typography>
      <Box component="div" className={classes.page__innerContainer}>
        <Box
          component="div"
          className={classes.page__innerContainer__rightContainer}
        >
          {chartData.map((item, index) => (
            <Box
              key={index}
              component="div"
              className={
                classes.page__innerContainer__rightContainer__infoContainer
              }
            >
              <Avatar
                src={item.icon}
                alt="Platform Icon"
                sx={{ height: "30px", width: "30px" }}
              />
              <Box>
                <Typography
                  className={
                    classes.page__innerContainer__rightContainer__infoContainer__streams
                  }
                >
                  {item.streams}
                </Typography>
                <Typography
                  className={
                    classes.page__innerContainer__rightContainer__infoContainer__streamsSecondary
                  }
                >
                  {item.subStreams}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>

        <Box
          component="div"
          className={classes.page__innerContainer__leftContainer}
        >
          <ReactApexChart
            options={chartOptions}
            series={chartOptions.series}
            type="radar"
            height={280}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default RevenueGraph;
