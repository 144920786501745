import React from "react";
import ReactApexChart from "react-apexcharts";
import classess from "./style.module.scss";

const Graph = (data) => {
  const [YoutubeState] = React.useState({
    series: [
      {
        name:"Views",
        data: data.youtubeState.data,
      },
    ],
    options: {
      chart: {
        id: "Youtubechart2",
        type: "area",
        height: 350,
        foreColor: "#FF0000",
        toolbar: {
          autoSelected: "pan",
          show: false,
        },
      },
      colors: ["#FF0000"],
      stroke: {
        width: 3,
        curve: "straight",
      },
      grid: {
        borderColor: "#ffffff20",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        gradient: {
          enabled: true,
          opacityFrom: 0.55,
          opacityTo: 0,
        },
      },
      // markers: {
      //   size: 3,
      //   colors: ["#222c41"],
      //   strokeColor: "#4ffcb7",
      //   strokeWidth: 3,
      // },
      tooltip: {
        theme: "dark",
      },
      xaxis: {
        type: "datetime",
      },
      yaxis: {
        min: 0,
        tickAmount: 4,
      },
    },

    //   Second Graph Starts

    seriesLine: [
      {
        data: data.youtubeState.data,
      },
    ],

    optionsLine: {
      chart: {
        id: "Youtubechart1",
        height: 130,
        type: "bar",
        foreColor: "#ccc",
        brush: {
          target: "Youtubechart2",
          enabled: true,
        },
        selection: {
          enabled: true,
          fill: {
            color: "#222c41",
            opacity: 0.4,
          },
          xaxis: {
            min: new Date(data.youtubeState.minDate).getTime(),
            max: new Date(data.youtubeState.maxDate).getTime(),
          },
        },
      },
      colors: ["#FF0000"],
      stroke: {
        width: 2,
      },
      grid: {
        borderColor: "#444",
      },
      markers: {
        size: 0,
      },
      xaxis: {
        type: "datetime",
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        tickAmount: 2,
      },
      //   fill: {
      //     type: "gradient",
      //     gradient: {
      //       opacityFrom: 0.91,
      //       opacityTo: 0.1,
      //     },
      //   },
    },
  });

  var chart1 = new ReactApexChart(
    document.querySelector("#chart-area"),
    YoutubeState.options
  );
  chart1.render();

  var chart2 = new ReactApexChart(
    document.querySelector("#chart-bar"),
    YoutubeState.optionsLine
  );

  chart2.render();

  return (
    <div id="wrapper" className={classess.wrapper}>
      <div id="chart-line2">
        <ReactApexChart
          options={YoutubeState.options}
          series={YoutubeState.series}
          type="line"
          height={230}
        />
      </div>
      <div id="chart-line" className={classess.chart_line}>
        <ReactApexChart
          options={YoutubeState.optionsLine}
          series={YoutubeState.seriesLine}
          type="area"
          height={130}
        />
      </div>
    </div>
  );
};

export default Graph;

// Remove the following lines from this component and manage rendering in the parent component.
// const domContainer = document.querySelector('#app');
// ReactDOM.render(React.createElement(ApexChart), domContainer);
