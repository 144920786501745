import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// Icons and UI Components
import { TiStarOutline } from "react-icons/ti";
import ReactCountryFlag from "react-country-flag";
import {
  Box,
  Stack,
  Avatar,
  IconButton,
  Skeleton,
  Tooltip,
  TablePagination,
  Typography,
} from "@mui/material";
import Lottie from "lottie-react";
import { ReactSVG } from "react-svg";

// Redux Actions and Selectors
import {
  deleteArtist,
  removeArtist,
  getArtist,
  emptySingularArtist,
} from "../../redux/slice/artist";

// Utils and Services
import { getItemToLocalStorage } from "../../services/storage";
import { addCommasToNumber, scrollTopObserver } from "../../utils/helper";
import DeleteConformationDialog from "../../dialogs/delete-conformation-dialog/delete-conformation-dialog";

// Constants and Enums
import AuthEnum from "../../enums/auth.enum";
import { countries } from "country-data";
import { config as URLconfig } from "../../enviorment/enviorment";

// Assets
import Eye from "../../assets/eye.svg";
import Pen from "../../assets/pen.svg";
import Delete from "../../assets/deleteIcon.svg";
import NewLoader from "../lottie-loader/Loader.json";
import SearchIcon from "@mui/icons-material/Search";

// Styles
import classes from "./style.module.scss";

// Material Custom Design Components
import {
  CustomTableContainer,
  CustomTable,
  CustomTableBody,
  CustomTableCell,
  CustomTableHead,
  CustomTableRow,
  CustomTableCellHeader,
} from "../../custom-mui-style/custom-mui-styles";

const MyArtistList = () => {
  const [artistList, setArtistList] = useState([]);
  const [totalArtist, setTotalArtist] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedArtistToDelete, setSelectedArtistToDelete] = useState({});

  // Redux and Navigation
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const artist = useSelector((state) => state.artist.artists);
  const artistApiStatus = useSelector((state) => state.artist.status);

  // Local storage token
  const storedToken = getItemToLocalStorage("accessToken");

  useEffect(() => {
    dispatch(getArtist());
  }, []);

  useEffect(() => {
    initUIData();
  }, []);

  useEffect(() => {
    if (Array.isArray(artist.data)) {
      setArtistList(artist.data);
      setTotalArtist(artist.message);
    }
  }, [artist]);

  const initUIData = () => {
    dispatch(getArtist());
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    loadArtists(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    loadArtists(0, parseInt(event.target.value, 10));
  };

  const handleOpenDeleteDialog = (artist) => {
    setSelectedArtistToDelete(artist);
    setOpen(true);
  };

  const handleCloseDeleteDialog = (res) => {
    setOpen(false);
    if (res) {
      deleteArtistByID(selectedArtistToDelete?._id);
    }
  };

  const deleteArtistByID = (id) => {
    const uid = user.id;
    if (user.role === "user") {
      dispatch(
        removeArtist({
          id,
          uid,
        })
      ).then(() => {
        removespotlightartist(id, uid);
        initUIData();
      });
    } else {
      dispatch(
        deleteArtist({
          id,
        })
      ).then(() => {
        removespotlightartist(id, uid);
        initUIData();
      });
    }
  };

  const loadArtists = (currentPage, pageSize) => {
    setIsLoading(true);
    try {
      let configAuth = {
        headers: {
          authorization: `Bearer ${getItemToLocalStorage(AuthEnum["TOKEN"])}`,
        },
      };
      axios
        .get(
          `${URLconfig.BASE_URL}/artists?page=${currentPage}&limit=${pageSize}`,
          configAuth
        )
        .then((res) => {
          const response = res.data.data;
          setArtistList(response);
          setTotalArtist(res.data.message);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const removespotlightartist = (_id, uid) => {
    const body = {
      _id: uid,
    };
    axios
      .put(`${URLconfig.BASE_URL}/spotlight/myartistlist/${_id}`, body)
      .then((response) => {
        console.log("response", response);
      })
      .catch((error) => {
        console.error("Error fetching spotlight data:", error);
      });
  };

  const getEmail = (email, name) => {
    if (email) {
      return email;
    }
    const newName = name.replace(/\s+/, "");
    return `${newName}@blacklionapp.xyz`;
  };

  const artist_add_to_spotlight = (artist) => {
    const payload = {
      spotify_id: artist?.spotify_id,
      name: artist?.name,
      email: artist?.email,
      image: artist?.avatar,
      artist_id: artist._id,
      country: artist?.country,
    };
    axios
      .post(`${URLconfig.BASE_URL}/spotlight`, payload, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      })
      .then((res) => {
        toast.success("Spotlight added");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  let cancelToken;

  const handleSearch = async (event) => {
    const { value } = event.target;

    //Check if there are any previous pending requests
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }

    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();

    if (value && value.length >= 3) {
      let configAuth = {
        headers: {
          authorization: `Bearer ${getItemToLocalStorage(AuthEnum["TOKEN"])}`,
        },
        cancelToken: cancelToken.token, //Pass the cancel token to the current request
      };
      axios
        .get(`${URLconfig.BASE_URL}/artists/search/${value}`, configAuth)
        .then((response) => {
          let newList = response.data.data;
          setArtistList(newList);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (value.length === 0) {
      dispatch(getArtist());
    }
  };

  return (
    <Box varient="div" component="div" className={classes.page}>
      <Box varient="div" component="div" className={classes.page__header}>
        <span className={classes.page__header__title}>My Artists</span>

        <Box
          varient="div"
          component="div"
          className={classes.page__header__search}
        >
          <SearchIcon className={classes.page__header__search__seaarch_icon} />
          <input
            className={classes.page__header__search__input}
            placeholder="Search"
            type="search"
            onInput={(e) => handleSearch(e)}
            required
          />
        </Box>
      </Box>

      {artistApiStatus === "loading" ? (
        // <SkeletonLoader rows={rowsPerPage} />
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: "42px", height: "42px" }}>
            <Lottie
              animationData={NewLoader}
              className={classes.page__loader}
            />
          </Box>
        </Box>
      ) : (
        // <SkeletonLoader rows={rowsPerPage} />
        // Display artistList when available
        <ArtitsTable
          artistList={artistList}
          isLoading={isLoading}
          rowsPerPage={rowsPerPage}
          onNavigate={navigate}
          handleOpenDeleteDialog={handleOpenDeleteDialog}
          navigate={navigate}
          dispatch={dispatch}
          getEmail={getEmail}
          artist_add_to_spotlight={artist_add_to_spotlight}
          totalArtist={totalArtist}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          artistApiStatus={artistApiStatus}
        />
      )}

      <DeleteConformationDialog
        onClose={handleCloseDeleteDialog}
        open={open}
        artist={selectedArtistToDelete}
      />
    </Box>
  );
};

export default MyArtistList;

const ArtitsTable = ({
  artistList,
  isLoading,
  rowsPerPage,
  onNavigate,
  handleOpenDeleteDialog,
  navigate,
  dispatch,
  getEmail,
  artist_add_to_spotlight,
  totalArtist,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  artistApiStatus,
}) => {
  return (
    <React.Fragment>
      <CustomTableContainer
        className={classes.page__tableContainer}
        sx={{ height: "90%" }}
      >
        <CustomTable
          className={classes.page__table}
          stickyHeader
          aria-label="sticky table"
        >
          <CustomTableHead>
            <CustomTableRow>
              <CustomTableCellHeader />
              <CustomTableCellHeader>ARTIST NAME</CustomTableCellHeader>
              <CustomTableCellHeader>COUNTRY</CustomTableCellHeader>
              <CustomTableCellHeader>EMAIL</CustomTableCellHeader>
              <CustomTableCellHeader>Earnings(Yearly)</CustomTableCellHeader>
              <CustomTableCellHeader>ACTION</CustomTableCellHeader>
            </CustomTableRow>
          </CustomTableHead>
          <CustomTableBody>
            {isLoading
              ? Array.from({ length: rowsPerPage }).map((_, index) => (
                  // ? Array.from({ length: rowsPerPage }).map((_, index) => (
                  <React.Fragment key={index}>
                    <Box sx={{ m: "1rem" }} />
                    <CustomTableRow key={index}>
                      <CustomTableCell
                        sx={{
                          borderTopLeftRadius: "12px",
                          borderBottomLeftRadius: "12px",
                        }}
                      >
                        <Skeleton variant="circular" width={40} height={40} />
                      </CustomTableCell>
                      <CustomTableCell>
                        <Skeleton variant="text" width="100%" />
                      </CustomTableCell>
                      <CustomTableCell>
                        <Skeleton variant="text" width="100%" />
                      </CustomTableCell>
                      <CustomTableCell>
                        <Skeleton variant="text" width="100%" />
                      </CustomTableCell>
                      <CustomTableCell>
                        <Skeleton variant="text" width="100%" />
                      </CustomTableCell>
                      <CustomTableCell
                        sx={{
                          borderTopRightRadius: "12px",
                          borderBottomRightRadius: "12px",
                        }}
                      >
                        <Skeleton variant="text" width="100%" />
                      </CustomTableCell>
                    </CustomTableRow>
                  </React.Fragment>
                ))
              : artistList
                  // .slice(
                  //   page * rowsPerPage,
                  //   page * rowsPerPage + rowsPerPage
                  // )
                  .map((row, index) => (
                    <React.Fragment key={index}>
                      <Box sx={{ m: "1rem" }} />
                      <CustomTableRow>
                        <CustomTableCell
                          sx={{
                            borderBottomLeftRadius: "12px",
                            borderTopLeftRadius: "12px",
                          }}
                        >
                          <Avatar
                            src={row?.avatar}
                            alt={row?.name}
                            className={classes.page__placement__artist_image}
                          />
                          {/* <Box className={classes.wrapper}>
                            <Box className={classes.controls}>
                              <Box
                                className={classes.add}
                                onClick={() => artist_add_to_spotlight(row)}
                              >
                                <TiStarOutline />
                              </Box>
                            </Box>
 
                          </Box> */}
                        </CustomTableCell>

                        <CustomTableCell>
                          <Box className={classes.page__placement}>
                            <Tooltip
                              title={row?.name}
                              placement="top"
                              arrow
                              enterDelay={100}
                            >
                              <span
                                onClick={() => {
                                  navigate(`/blig/view-artist/${row?._id}`);
                                  scrollTopObserver();
                                }}
                              >
                                {row?.name}
                              </span>
                            </Tooltip>
                          </Box>
                        </CustomTableCell>

                        <CustomTableCell>
                          <Box className={classes.page__country}>
                            <ReactCountryFlag
                              countryCode={row?.chartmetric?.code2}
                              svg
                            />
                            {row?.chartmetric?.code2
                              ? countries[row?.chartmetric?.code2.toUpperCase()]
                                  ?.name || "N/A"
                              : "N/A"}
                          </Box>
                        </CustomTableCell>

                        <CustomTableCell>
                          <Box className={classes.page__email}>
                            {getEmail(row?.email, row?.name)}
                          </Box>
                        </CustomTableCell>

                        <CustomTableCell>
                          <span className={classes.page__yearly_earning}>
                            {addCommasToNumber(
                              row?.oneyearIncome ? row?.oneyearIncome : "N/A"
                            )}
                          </span>
                        </CustomTableCell>

                        <CustomTableCell
                          sx={{
                            borderBottomRightRadius: "12px",
                            borderTopRightRadius: "12px",
                          }}
                        >
                          <Stack spacing={1} direction="row">
                            <Tooltip
                              title="View Artist"
                              placement="top"
                              arrow
                              enterDelay={100}
                            >
                              <IconButton
                                style={{
                                  backgroundColor:
                                    "var(--base-action-icon-background)",
                                  height: "30px",
                                  width: "30px",
                                }}
                                onClick={() => {
                                  navigate(`/blig/view-artist/${row?._id}`);
                                  scrollTopObserver();
                                }}
                              >
                                <Box className={classes.page__icon}>
                                  <ReactSVG src={Eye} />
                                </Box>
                              </IconButton>
                            </Tooltip>

                            <Tooltip
                              title="Edit Artist"
                              placement="top"
                              arrow
                              enterDelay={100}
                            >
                              <IconButton
                                style={{
                                  backgroundColor:
                                    "var(--base-action-icon-background)",
                                  height: "30px",
                                  width: "30px",
                                }}
                                onClick={() => {
                                  dispatch(emptySingularArtist());
                                  navigate(`/blig/edit-artist/${row?._id}`);
                                  scrollTopObserver();
                                }}
                              >
                                <Box className={classes.page__icon}>
                                  <ReactSVG src={Pen} />
                                </Box>
                              </IconButton>
                            </Tooltip>

                            <Tooltip
                              title="Delete Artist"
                              placement="top"
                              arrow
                              enterDelay={100}
                            >
                              <IconButton
                                style={{
                                  backgroundColor:
                                    "var(--base-action-icon-background)",
                                  height: "30px",
                                  width: "30px",
                                }}
                                onClick={() => handleOpenDeleteDialog(row)}
                              >
                                <Box className={classes.page__icon}>
                                  <ReactSVG src={Delete} />
                                </Box>
                              </IconButton>
                            </Tooltip>
                          </Stack>
                        </CustomTableCell>
                      </CustomTableRow>
                    </React.Fragment>
                  ))}
            {artistApiStatus === "succeeded" &&
            artistList &&
            artistList.length === 0 ? (
              <NoArtistMessage />
            ) : null}
          </CustomTableBody>
        </CustomTable>
      </CustomTableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        sx={{ color: "#d6d6d6", width: "100%", height: "10%" }}
        count={totalArtist}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        classes={{
          actions: "custom-pagination-actions",
          select: "custom-pagination-select",
          displayedRows: "custom-select-style",
          selectLabel: "custom-select-style",
        }}
        SelectProps={{
          classes: {
            select: "custom-select",
          },
        }}
      />
    </React.Fragment>
  );
};

const SkeletonLoader = ({ rows }) => {
  return (
    <CustomTableContainer className={classes.page__skeletonLoader}>
      <CustomTable stickyHeader aria-label="sticky table">
        <CustomTableHead>
          <CustomTableRow>
            <CustomTableCellHeader />
            <CustomTableCellHeader>ARTIST NAME</CustomTableCellHeader>
            <CustomTableCellHeader>COUNTRY</CustomTableCellHeader>
            <CustomTableCellHeader>EMAIL</CustomTableCellHeader>
            <CustomTableCellHeader>Earnings(Yearly)</CustomTableCellHeader>
            <CustomTableCellHeader>ACTION</CustomTableCellHeader>
          </CustomTableRow>
        </CustomTableHead>
        <CustomTableBody>
          {Array.from({ length: rows }).map((_, index) => (
            <React.Fragment key={index}>
              <Box sx={{ m: "1rem" }}></Box>

              <CustomTableRow>
                <CustomTableCell
                  sx={{
                    borderTopLeftRadius: "12px",
                    borderBottomLeftRadius: "12px",
                  }}
                >
                  <Skeleton variant="circular" width={40} height={40} />
                </CustomTableCell>

                <CustomTableCell>
                  <Skeleton variant="text" fontSize="1rem" />
                </CustomTableCell>

                <CustomTableCell>
                  <Skeleton variant="text" fontSize="1rem" />
                </CustomTableCell>

                <CustomTableCell>
                  <Skeleton variant="text" fontSize="1rem" />
                </CustomTableCell>

                <CustomTableCell>
                  <Skeleton variant="text" fontSize="1rem" />
                </CustomTableCell>

                <CustomTableCell
                  sx={{
                    borderTopRightRadius: "12px",
                    borderBottomRightRadius: "12px",
                  }}
                >
                  <Skeleton variant="text" fontSize="1rem" />
                </CustomTableCell>
              </CustomTableRow>
            </React.Fragment>
          ))}
        </CustomTableBody>
      </CustomTable>
    </CustomTableContainer>
  );
};

const NoArtistMessage = () => (
  <React.Fragment>
    <Box sx={{ marginTop: "1rem" }} />
    <CustomTableRow>
      <CustomTableCell colSpan={7} sx={{ borderRadius: "12px" }}>
        <Typography className={classes.page__notFound}>No Artist</Typography>
      </CustomTableCell>
    </CustomTableRow>
  </React.Fragment>
);
