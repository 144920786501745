import React, { useEffect, useRef, useState } from "react";
import classess from "./style.module.scss";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { getItemToLocalStorage } from "../../../services/storage";
import axios from "axios";
import { config as URLconfig } from "../../../enviorment/enviorment";
import NewLoader from "../../lottie-loader/Loader.json";
import Lottie from "lottie-react";
import { ReactSVG } from "react-svg";
import SearchIcon from "../../../assets/searchIcon.svg";
import { useNavigate } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import { countries } from "country-data";
// import { truncateString } from "../../utils/helper";
import { LazyLoadImage } from "react-lazy-load-image-component";
import DefaultAvatar from "../../../assets/default-avatar.webp";
import CustomTwoTabs from "../../custom-two-tabs/custom-two-tabs";

const TabsSearch = () => {
  const storedToken = getItemToLocalStorage("accessToken");
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResultArtist, setSearchResultArtist] = useState([]);
  const [searchResultTrack, setSearchResultTrack] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [isSearch, setIsSearch] = useState(false);
  const searchListRef = useRef(null);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearch = async (query) => {
    if (!query.trim()) {
      setSearchResultArtist([]);
      setSearchResultTrack([]);
      setLoading(false);
      setIsSearch(false);
      return;
    }

    try {
      if (cancelTokenSource) {
        cancelTokenSource.cancel();
      }

      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);
      setLoading(true);

      if (query.length >= 3) {
        const response = await axios.get(
          `${URLconfig.BASE_URL}/artists/search-with-tracks/${query}`,
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
            cancelToken: newCancelTokenSource.token,
          }
        );
        if (response.status === 200) {
          const data = response.data;
          setSearchResultArtist(data?.data?.artists);
          setSearchResultTrack(data?.data?.tracks);
          // console.log(data?.data);
          setIsSearch(true);
        } else {
          console.error("Error fetching data");
        }
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.error("Error:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      handleSearch(searchQuery);
    }, 1000);

    return () => clearTimeout(delaySearch);
  }, [searchQuery]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchListRef.current &&
        !searchListRef.current.contains(event.target)
      ) {
        setIsSearch(false);
      }
    };

    document.body.addEventListener("click", handleClickOutside);
    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const handleAddArtist = () => {
    if (searchQuery.trim()) {
      navigate(`/blig/add-artist/${searchQuery}`);
    }
  };
  return (
    <Box className={classess.page}>
      <Box className={classess.page__topSec} ref={searchListRef}>
        <Box className={classess.page__topSec__heading}>Add Artist</Box>

        <Box
          sx={{
            width: "100%",
          }}
          className={classess.page__topSec__tabs}
        >
          <CustomTwoTabs
            text_0={"search artist"}
            text_1={"Search Song"}
            btn_0={activeTab == 1}
            btn_1={activeTab == 2}
            click_0={() => handleTabClick(1)}
            click_1={() => handleTabClick(2)}
            padding={"12px"}
          />
        </Box>

        <Box className={classess.page__topSec__search}>
          <input
            className={classess.page__topSec__search__input}
            placeholder={
              activeTab === 1 ? "Search by Artist" : "Search by Song"
            }
            type="text"
            value={searchQuery}
            onChange={handleInputChange}
          />
          <Box className={classess.page__topSec__search__input__icon}>
            <ReactSVG src={SearchIcon} />
          </Box>
          {loading && (
            <Box className={classess.page__topSec__search__input__loading}>
              <Box
                sx={{
                  width: "25px",
                  height: "25px",
                }}
              >
                <Lottie
                  animationData={NewLoader}
                  className={
                    classess.page__topSec__search__input__loading__loader
                  }
                />
              </Box>
            </Box>
          )}
        </Box>
        <Box>
          {/* // ? tab for artist search */}
          <Box>
            {activeTab === 1 && (
              <React.Fragment>
                {/* // ? show list */}
                {isSearch && searchResultArtist && (
                  <React.Fragment>
                    <Box className={classess.page__topSec__result}>
                      <Typography
                        className={classess.page__topSec__result__found}
                      >
                        {searchResultArtist?.length} Artist Found
                      </Typography>

                      {searchResultArtist?.map((item) => (
                        <React.Fragment>
                          <Box
                            className={classess.page__topSec__result__details}
                            key={item?._id}
                            onClick={() => {
                              navigate(`/blig/view-artist/${item?._id}`);
                            }}
                          >
                            <Box>
                              <LazyLoadImage
                                alt={item.name}
                                effect="blur"
                                src={item?.avatar}
                                className={
                                  classess.page__topSec__result__details__avatar
                                }
                              />
                            </Box>
                            <Box>
                              <Typography
                                className={
                                  classess.page__topSec__result__details__name
                                }
                              >
                                {item?.name}
                              </Typography>
                              <Typography
                                className={
                                  classess.page__topSec__result__details__country_flag
                                }
                              >
                                <ReactCountryFlag
                                  countryCode={
                                    item.country ? item.country : null
                                  }
                                  svg
                                />
                                {item.country
                                  ? countries[item.country.toUpperCase()]
                                      ?.name || "N/A"
                                  : "N/A"}
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            className={
                              classess.page__topSec__result__details__divider
                            }
                          />
                        </React.Fragment>
                      ))}
                    </Box>
                  </React.Fragment>
                )}

                {/* // ? show no result found */}
                {isSearch && searchResultArtist.length < 1 && (
                  <Box className={classess.page__topSec__result}>
                    <Typography
                      className={classess.page__topSec__result__no_result}
                    >
                      no results
                    </Typography>
                    <Typography
                      className={classess.page__topSec__result__array_length}
                    >
                      {searchResultArtist.length} Artist
                    </Typography>
                    <Box
                      className={
                        classess.page__topSec__result__details__divider
                      }
                      mt={1.5}
                    />
                  </Box>
                )}

                {/* // ? show add artist btn */}
                {isSearch && searchResultArtist && (
                  <Box className={classess.page__topSec__result__add_btn}>
                    <Box
                      className={classess.page__topSec__result__add_btn__btn}
                    >
                      <Button variant="contained" onClick={handleAddArtist}>
                        Add Artist
                      </Button>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "12px",
                          textAlign: "center",
                          mt: 0.5,
                        }}
                      >
                        Can’t find what you’re looking for?
                      </Typography>
                    </Box>
                  </Box>
                )}
              </React.Fragment>
            )}
          </Box>

          {/* // ? tab for track search */}
          <Box>
            {activeTab === 2 && (
              <React.Fragment>
                {/* // ? show list */}
                {isSearch && searchResultTrack && (
                  <Box
                    className={classess.page__topSec__result}
                    sx={{
                      padding: "20px",
                    }}
                  >
                    <Typography
                      className={classess.page__topSec__result__found}
                    >
                      {searchResultTrack?.length} Track Found
                    </Typography>
                    {searchResultTrack?.map((item) => (
                      <React.Fragment>
                        <Box
                          className={classess.page__topSec__result__details}
                          key={item?._id}
                          onClick={() => {
                            navigate(`/blig/track/${item?._id}`);
                          }}
                        >
                          <Box>
                            <LazyLoadImage
                              alt={item?.track_img}
                              effect="blur" // Add the blur effect
                              src={
                                item?.track_img
                                  ? item?.track_img
                                  : DefaultAvatar
                              }
                              className={
                                classess.page__topSec__result__details__avatar
                              }
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = DefaultAvatar;
                              }}
                            />
                          </Box>
                          <Box>
                            <Tooltip
                              title={item?.title}
                              placement="top"
                              arrow
                              enterDelay={100}
                            >
                              <Typography
                                className={
                                  classess.page__topSec__result__details__name
                                }
                              >
                                {/* {truncateString(item?.title)} */}
                                {item.title}
                              </Typography>
                            </Tooltip>
                            <Typography>{item?.country}</Typography>
                          </Box>
                        </Box>
                        <Box
                          className={
                            classess.page__topSec__result__details__divider
                          }
                        />
                      </React.Fragment>
                    ))}
                  </Box>
                )}

                {/* // ? show no result found */}
                {isSearch && searchResultTrack.length < 1 && (
                  <Box className={classess.page__topSec__result}>
                    <Typography
                      className={classess.page__topSec__result__no_result}
                    >
                      no results
                    </Typography>
                    <Typography
                      className={classess.page__topSec__result__array_length}
                    >
                      {searchResultTrack.length} Track
                    </Typography>
                    <Box
                      className={
                        classess.page__topSec__result__details__divider
                      }
                      mt={1.5}
                    />
                  </Box>
                )}
              </React.Fragment>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TabsSearch;
