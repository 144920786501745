// React and Redux Imports
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// MUI Imports
import {
  Box,
  Grid,
  Avatar,
  IconButton,
  Typography,
  Stack,
  Button,
  Tooltip,
} from "@mui/material";

// Custom Components and Styles Imports
import {
  CustomTable,
  CustomTableContainer,
  CustomTableBody,
  CustomTableCell,
  CustomTableHead,
  CustomTableRow,
  CustomTableCellHeader,
} from "../../custom-mui-style/custom-mui-styles";
import classess from "./style.module.scss";

// Assets and Utilities Imports
import { getItemToLocalStorage } from "../../services/storage";
import { config as URLconfig } from "../../enviorment/enviorment";
import EyeIcon from "../../assets/eye.svg";
import SignatureIcon from "../../assets/signatureIcon.svg";
import ClouddownIcon from "../../assets/clouddownIcon.svg";

// External Libraries and Icons Imports
import axios from "axios";
import Lottie from "lottie-react";
import { ReactSVG } from "react-svg";
import NewLoader from "../lottie-loader/Loader.json";
import FilterIcon from "../../assets/filterIcon.svg";
import CloudUpIcon from "../../assets/cloudUpIcon.svg";
import { useNavigate } from "react-router-dom";
const internationalNumberFormat = new Intl.NumberFormat("en-US");

const ArtistMyDeal = ({ setTotalDeals }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const storedToken = getItemToLocalStorage("accessToken");
  const [isLoading, setIsLoading] = useState(false);
  // const [value, setValue] = useState("Weekly");
  // const [isOpen, setIsOpen] = useState(false);
  const [activities, setActivities] = useState([]);

  const generateRandomDeals = () => {
    const statuses = ["Pending", "Active", "Closed"];
    const types = ["Buyout", "Royalty"];
    const names = ["Tracy Carter", "Jordan Smith", "Alex Johnson"];
    let deals = [];

    for (let i = 0; i < 15; i++) {
      deals.push({
        dealName: `Deal_${Math.random().toString(36).substring(7)}`,
        dealAmount: `$${(Math.random() * 1000000).toFixed(2)}`,
        status: statuses[Math.floor(Math.random() * statuses.length)],
        startDate: new Date(
          +new Date() - Math.floor(Math.random() * 10000000000)
        ).toLocaleDateString(),
        endDate: new Date(
          +new Date() + Math.floor(Math.random() * 10000000000)
        ).toLocaleDateString(),
        creationDate: new Date(
          +new Date() - Math.floor(Math.random() * 100000000)
        ).toLocaleDateString(),
        type: types[Math.floor(Math.random() * types.length)],
        anr: names[Math.floor(Math.random() * names.length)],
      });
    }

    return deals;
  };

  useEffect(() => {
    const fetchArtistDeals = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${URLconfig.BASE_URL}/contracts/artist-by`,
          {
            headers: { Authorization: `Bearer ${storedToken}` },
          }
        );
        // console.log(response);
        setActivities(response.data.data);
        setTotalDeals(response.data.data.length || 0);
        // console.log("Activity", response.data.data);
      } catch (error) {
        console.error("Error fetching contracts:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchArtistDeals();
  }, []);
  useEffect(() => {
    setIsLoading(true);
    try {
      // Simulate fetching data
      // const randomDeals = generateRandomDeals();
      // setActivities(randomDeals);
    } catch (error) {
      console.error("Error generating random deals:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);
  console.log("activities", activities);
  return (
    <Box className={classess.page}>
      <Box className={classess.page__topSec}>
        <Typography className={classess.page__topSec__heading}>
          My Deals
        </Typography>
        <Button
          className={classess.page__topSec__button}
          startIcon={<ReactSVG src={FilterIcon} />}
        >
          Filter
        </Button>
      </Box>
      <Box container spacing={2}>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <MyDealsTable
            isLoading={isLoading}
            activities={activities}
            navigate={navigate}
          />
        </Grid>
      </Box>
    </Box>
  );
};

export default ArtistMyDeal;

const MyDealsTable = ({ isLoading, activities, onClick, navigate }) => {
  return (
    <CustomTableContainer className={classess.page__table}>
      <CustomTable stickyHeader aria-label="sticky table">
        <CustomTableHead>
          <CustomTableCellHeader sx={{ border: "none !important" }}>
            DEAL NAME
          </CustomTableCellHeader>

          <CustomTableCellHeader sx={{ border: "none !important" }}>
            DEAL AMOUNT
          </CustomTableCellHeader>

          <CustomTableCellHeader sx={{ border: "none !important" }}>
            STATUS
          </CustomTableCellHeader>

          <CustomTableCellHeader sx={{ border: "none !important" }}>
            START DATE
          </CustomTableCellHeader>

          <CustomTableCellHeader sx={{ border: "none !important" }}>
            END DATE
          </CustomTableCellHeader>

          <CustomTableCellHeader sx={{ border: "none !important" }}>
            CREATION DATE
          </CustomTableCellHeader>

          <CustomTableCellHeader sx={{ border: "none !important" }}>
            Type
          </CustomTableCellHeader>

          <CustomTableCellHeader sx={{ border: "none !important" }}>
            A&R
          </CustomTableCellHeader>

          <CustomTableCellHeader sx={{ border: "none !important" }}>
            Action
          </CustomTableCellHeader>
        </CustomTableHead>

        <CustomTableBody>
          {activities.map((activity, index) => (
            <React.Fragment>
              <Box sx={{ mb: "1rem" }} />
              <CustomTableRow>
                <CustomTableCell
                  sx={{
                    // width: "50px !important",
                    borderBottomLeftRadius: "12px",
                    borderTopLeftRadius: "12px",
                  }}
                >
                  <Tooltip
                    title={"DEAL NAME"}
                    placement="top"
                    arrow
                    enterDelay={100}
                  >
                    <Typography className={classess.page__name}>
                      {activity.dealName}
                    </Typography>
                  </Tooltip>
                </CustomTableCell>

                <CustomTableCell>
                  <Tooltip
                    title={"DEAL AMOUNT"}
                    placement="top"
                    arrow
                    enterDelay={100}
                  >
                    <Typography className={classess.page__heading1}>
                      {internationalNumberFormat.format(
                        parseInt(activity.artist_advance) +
                          parseInt(activity.artist_marketing_budget) || 0
                      )}
                    </Typography>
                  </Tooltip>
                </CustomTableCell>

                <CustomTableCell>
                  <Typography className={classess.page__heading2}>
                    {activity.status}
                  </Typography>
                </CustomTableCell>

                <CustomTableCell>
                  <Typography className={classess.page__heading2}>
                    {activity.createdAt}
                  </Typography>
                </CustomTableCell>

                <CustomTableCell>
                  <Typography className={classess.page__heading2}>
                    {activity.endDate}
                  </Typography>
                </CustomTableCell>

                <CustomTableCell>
                  <Typography className={classess.page__heading2}>
                    {activity.creationDate}
                  </Typography>
                </CustomTableCell>

                <CustomTableCell>
                  <Typography className={classess.page__heading2}>
                    {activity.type}
                  </Typography>
                </CustomTableCell>

                <CustomTableCell>
                  <Typography className={classess.page__heading2}>
                    {activity.artist_representative_name}
                  </Typography>
                </CustomTableCell>

                <CustomTableCell
                  sx={{
                    borderBottomRightRadius: "12px",
                    borderTopRightRadius: "12px",
                  }}
                >
                  <Stack direction="row" spacing={1}>
                    <IconButton
                      className={classess.page__actionIcon}
                      onClick={() =>
                        navigate(
                          `/blig/artist-contract-details/${activity._id}`
                        )
                      }
                    >
                      <ReactSVG src={EyeIcon} />
                    </IconButton>
                    <IconButton className={classess.page__actionIcon}>
                      <ReactSVG src={SignatureIcon} />
                    </IconButton>
                    <IconButton className={classess.page__actionIcon}>
                      <ReactSVG src={ClouddownIcon} />
                    </IconButton>
                    <IconButton className={classess.page__actionIcon}>
                      <ReactSVG src={CloudUpIcon} />
                    </IconButton>
                  </Stack>
                </CustomTableCell>
              </CustomTableRow>
            </React.Fragment>
          ))}
        </CustomTableBody>
      </CustomTable>
    </CustomTableContainer>
  );
};
