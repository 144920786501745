// React and Redux Imports
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// MUI Imports
import {
  Box,
  Grid,
  Avatar,
  IconButton,
  Typography,
  Stack,
  Button,
  Tooltip,
  Container,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Input from "@mui/material/Input";

// Custom Components and Styles Imports
import {
  CustomTable,
  CustomTableContainer,
  CustomTableBody,
  CustomTableCell,
  CustomTableHead,
  CustomTableRow,
  CustomTableCellHeader,
} from "../../custom-mui-style/custom-mui-styles";
import { viewArtistUseStyles } from "../../custom-mui-style/custom-mui-styles";
import {
  CustomSelect,
  CustomTextField,
  CustomMenuItem,
} from "../../custom-mui-style/custom-mui-styles";
import { CustomAmountTextField } from "../../custom-mui-style/custom-mui-styles";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";

import classess from "./style.module.scss";

// Assets and Utilities Imports
import { getItemToLocalStorage } from "../../services/storage";
import { config as URLconfig } from "../../enviorment/enviorment";

// External Libraries and Icons Imports
import Lottie from "lottie-react";
import { ReactSVG } from "react-svg";
import NewLoader from "../lottie-loader/Loader.json";
import FilterIcon from "../../assets/filterIcon.svg";
import NoDataFound from "../../assets/NoDataFoundImg.svg";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useTheme } from "@mui/material/styles";

const internationalNumberFormat = new Intl.NumberFormat("en-US");

const ArtistsMyPayment = ({ setTotalDeals }) => {
  const user = useSelector((state) => state.auth.user);
  const storedToken = getItemToLocalStorage("accessToken");
  const [isLoading, setIsLoading] = useState(false);
  const [payments, setPayments] = useState([]);
  const styles = viewArtistUseStyles();
  const [enterAmount, setEnterAmount] = useState("");

  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const [paymentAgainst, setPaymentAgainst] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };

  const handlepayAgainst = (event) => {
    const {
      target: { value },
    } = event;
    setPaymentAgainst(typeof value === "string" ? value.split(",") : value);
  };

  const generateRandomPayments = () => {
    const payments = [];
    for (let i = 0; i < 100; i++) {
      payments.push({
        dealName: `Deal ${i + 1}`,
        artist_advance: Math.floor(Math.random() * 10000),
        artist_marketing_budget: Math.floor(Math.random() * 5000),
        status: ["Pending", "Approved", "Paid"][Math.floor(Math.random() * 3)],
        createdAt: new Date().toLocaleDateString(),
        endDate: new Date(
          new Date().getTime() + (i + 1) * 24 * 60 * 60 * 1000
        ).toLocaleDateString(),
      });
    }
    return payments;
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const names = [
    "Oliver Hansen",
    "Van Henry",
    "April Tucker",
    "Ralph Hubbard",
    "Omar Alexander",
    "Carlos Abbott",
    "Miriam Wagner",
    "Bradley Wilkerson",
    "Virginia Andrews",
    "Kelly Snyder",
  ];

  const payAgainst = ["Artist Advance", "Marketing Advance"];

  function getStyles(name, personName, theme, paymentAgainst) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  useEffect(() => {
    // Simulate fetching data
    const randomPayments = generateRandomPayments();
    setPayments(randomPayments);
  }, []);
  const [request, setRequest] = useState(false);

  const openRequest = () => {
    setRequest(true);
  };
  const closeRequest = () => {
    setRequest(false);
  };
  const isAmountValid = enterAmount.trim() !== "";

  return (
    <React.Fragment>
      <Grid item xs={12} sm={12} lg={12} xl={8}>
        <Box className={classess.page}>
          <Box className={classess.page__topSec}>
            <Typography className={classess.page__topSec__heading}>
              My Payments
            </Typography>
            <Button
              className={classess.page__topSec__button}
              startIcon={<ReactSVG src={FilterIcon} />}
              onClick={openRequest}
            >
              Request Payment
            </Button>
          </Box>
          {isLoading ? (
            <LoadingComponent />
          ) : payments.length === 0 ? (
            <NoPaymentComponent />
          ) : (
            <MyPayments isLoading={isLoading} payments={payments} />
          )}
        </Box>
      </Grid>
      {request && (
        <Grid item xs={12} sm={12} lg={12} xl={4}>
          <Box className={classess.page}>
            <Box className={classess.page__topSec}>
              <Typography className={classess.page__topSec__heading}>
                Request Payments
              </Typography>
              <Button
                className={classess.page__topSec__req_button}
                onClick={closeRequest}
              >
                close
              </Button>
            </Box>

            <Box className={classess.page__reqPaymentContainer}>
              <Box
                className={classess.page__reqPaymentContainer__fieldsContainer}
              >
                <FormControl fullWidth sx={{ mb: "20px" }}>
                  <label
                    htmlFor=""
                    className={
                      classess.page__reqPaymentContainer__fieldsContainer__topHeading
                    }
                  >
                    select contract
                  </label>

                  <CustomSelect
                    displayEmpty
                    value={personName}
                    onChange={handleChange}
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return (
                          <em>
                            Please select a contract to request payment against
                          </em>
                        );
                      }

                      return selected.join(", ");
                    }}
                    MenuProps={MenuProps}
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor:
                          "var(--base-input-focus-color)  !important",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor:
                          "var(--base-input-focus-color)  !important",
                      },
                    }}
                  >
                    {names.map((name) => (
                      <CustomMenuItem
                        key={name}
                        value={name}
                        style={getStyles(name, personName, theme)}
                      >
                        {name}
                      </CustomMenuItem>
                    ))}
                  </CustomSelect>
                </FormControl>

                {personName.length > 0 && (
                  <FormControl fullWidth sx={{ mb: "20px" }}>
                    <label
                      htmlFor=""
                      className={
                        classess.page__reqPaymentContainer__fieldsContainer__topHeading
                      }
                    >
                      PAY AGAINST
                    </label>

                    <CustomSelect
                      displayEmpty
                      value={paymentAgainst}
                      onChange={handlepayAgainst}
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em>Please select</em>;
                        }

                        return selected.join(", ");
                      }}
                      MenuProps={MenuProps}
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor:
                            "var(--base-input-focus-color)  !important",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor:
                            "var(--base-input-focus-color)  !important",
                        },
                      }}
                    >
                      {payAgainst.map((name) => (
                        <CustomMenuItem
                          key={name}
                          value={name}
                          style={getStyles(name, paymentAgainst, theme)}
                        >
                          {name}
                        </CustomMenuItem>
                      ))}
                    </CustomSelect>
                  </FormControl>
                )}

                {/* <FormControl fullWidth sx={{ mb: "20px" }}>
                <label
                  htmlFor=""
                  className={
                    classess.page__reqPaymentContainer__fieldsContainer__topHeading
                  }
                >
                  Enter Amount
                </label>

                <CustomTextField
                  value={enterAmount}
                  name="name"
                  type="text"
                  placeholder="$233.00"
                  onChange={(e) => setEnterAmount(e.target.value)}
                  sx={{ height: "86px !important" }}
                ></CustomTextField>
              </FormControl> */}
                {paymentAgainst.length > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      // flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      height: "86px",
                      border: "1px solid #dddddd",
                      borderRadius: "12px",
                      padding: "20px",
                      width: "100%",
                      // gap: "150px ",
                    }}
                  >
                    <Typography sx={{ color: "#ff3d81", fontSize: "18px" }}>
                      $
                    </Typography>
                    <FormControl>
                      <CustomAmountTextField
                        value={enterAmount}
                        name="name"
                        type="number"
                        placeholder="50000.00"
                        onChange={(e) => setEnterAmount(e.target.value)}
                      />
                    </FormControl>
                  </Box>
                )}
              </Box>

              <Box className={classess.page__reqPaymentContainer__BtnContainer}>
                <FormControl fullWidth>
                  <Button
                    className={
                      classess.page__reqPaymentContainer__BtnContainer__ReqPayBtn
                    }
                    disabled={isAmountValid ? false : true}
                    sx={{
                      backgroundColor: isAmountValid
                        ? ""
                        : "var(--base-deactive-button-background-color) !important",
                    }}
                  >
                    Request Payments
                  </Button>
                </FormControl>
              </Box>
            </Box>
          </Box>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default ArtistsMyPayment;

const MyPayments = ({ isLoading, payments }) => {
  return (
    <CustomTableContainer className={classess.page__table}>
      <CustomTable stickyHeader aria-label="sticky table">
        <CustomTableHead>
          <CustomTableCellHeader sx={{ border: "none !important" }}>
            AMOUNT REQUESTED
          </CustomTableCellHeader>

          <CustomTableCellHeader sx={{ border: "none !important" }}>
            CONTRACT
          </CustomTableCellHeader>

          <CustomTableCellHeader sx={{ border: "none !important" }}>
            STATUS
          </CustomTableCellHeader>

          <CustomTableCellHeader sx={{ border: "none !important" }}>
            PAID AGAINST
          </CustomTableCellHeader>

          <CustomTableCellHeader sx={{ border: "none !important" }}>
            REQUESTED ON
          </CustomTableCellHeader>
        </CustomTableHead>

        <CustomTableBody>
          {payments.map((payments, index) => (
            <React.Fragment>
              <Box sx={{ mb: "1rem" }} />
              <CustomTableRow>
                <CustomTableCell
                  sx={{
                    // width: "50px !important",
                    borderBottomLeftRadius: "12px",
                    borderTopLeftRadius: "12px",
                  }}
                >
                  <Tooltip
                    title={"DEAL NAME"}
                    placement="top"
                    arrow
                    enterDelay={100}
                  >
                    <Typography className={classess.page__name}>
                      {payments.dealName}
                    </Typography>
                  </Tooltip>
                </CustomTableCell>

                <CustomTableCell>
                  <Tooltip
                    title={"DEAL AMOUNT"}
                    placement="top"
                    arrow
                    enterDelay={100}
                  >
                    <Typography className={classess.page__heading1}>
                      {internationalNumberFormat.format(
                        parseInt(payments.artist_advance) +
                          parseInt(payments.artist_marketing_budget) || 0
                      )}
                    </Typography>
                  </Tooltip>
                </CustomTableCell>

                <CustomTableCell>
                  <Typography className={classess.page__heading2}>
                    {payments.status}
                  </Typography>
                </CustomTableCell>

                <CustomTableCell>
                  <Typography className={classess.page__heading2}>
                    {payments.createdAt}
                  </Typography>
                </CustomTableCell>

                <CustomTableCell
                  sx={{
                    borderBottomRightRadius: "12px",
                    borderTopRightRadius: "12px",
                  }}
                >
                  <Typography className={classess.page__heading2}>
                    {payments.endDate}
                  </Typography>
                </CustomTableCell>
              </CustomTableRow>
            </React.Fragment>
          ))}
        </CustomTableBody>
      </CustomTable>
    </CustomTableContainer>
  );
};

const LoadingComponent = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "50px",
          height: "50px",
        }}
      >
        <Lottie animationData={NewLoader} className={classess.page__loader} />
      </Box>
    </Box>
  );
};

const NoPaymentComponent = () => {
  return (
    <Box className={classess.page__noDataContainer}>
      <ReactSVG src={NoDataFound} />
      <Box>
        <Typography className={classess.page__noDataContainer__heading1}>
          No payments history
        </Typography>
        <Typography className={classess.page__noDataContainer__heading2}>
          Request payments against contracts.
        </Typography>
      </Box>
    </Box>
  );
};
