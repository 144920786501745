import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import classess from "./style.module.scss";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import CustomTwoTabs from "../../../components/custom-two-tabs/custom-two-tabs";

const TotalEarnings = ({ data }) => {
  const [month, setMonth] = useState(true);
  const [year, setYear] = useState(false);
  const [monthlyEstimate, setMonthlyEstimate] = useState([]);
  const [yearlyEstimate, setYearlyEstimate] = useState([]);
  const [chartDataforMonthly, setchartDataforMonthly] = useState([]);
  const [chartDataforYearly, setchartDataforYearly] = useState([]);

  useEffect(() => {
    if (data) {
      setMonthlyEstimate([
        ...data.monthly.filter((e) => e.amount !== 0).map(mapMonths),
      ]);
      setYearlyEstimate([
        ...data.yearly.filter((e) => e.amount !== 0).map(mapYears),
      ]);
    }
  }, [data]);
  useEffect(() => {
    const apexChartData = monthlyEstimate.map((dataPoint) => [
      dataPoint.Month,
      dataPoint.Amount,
    ]);
    const apexChartDatayearly = yearlyEstimate.map((dataPoint) => ({
      x: dataPoint.name,
      y: dataPoint.Yearly,
    }));
    setchartDataforMonthly(apexChartData);
    setchartDataforYearly(apexChartDatayearly);
  }, [monthlyEstimate]);
  const mapMonths = (item) => ({
    Month: item.date,
    Amount: Math.round(item.amount, 2),
  });

  const mapYears = (item) => ({
    name: item.year,
    Yearly: Math.round(item.amount, 2),
  });

  const handleMonth = () => {
    setMonth(true);
    setYear(false);
  };

  const handleYear = () => {
    setYear(true);
    setMonth(false);
  };

  const [yAxisMaximum] = useState(0);

  const monthlyoptions = {
    series: [
      {
        name: "Earning $",
        data: chartDataforMonthly,
      },
    ],
    option: {
      chart: {
        id: "chart1",
        height: 455,
        type: "line",
        foreColor: "#ccc",
        toolbar: {
          autoSelected: "pan",
          show: true,
        },
        zoom: {
          enabled: true,
        },
        animations: {
          enabled: true,
        },
      },
      colors: ["var(--base-graph-line-color)"],
      stroke: {
        width: 3,
        curve: "straight",
      },
      grid: {
        borderColor: "#555",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: true,
          },
        },
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          gradientToColors: ["var(--base-graph-line-color)"],
          type: "horizontal",
          opacityFrom: 1,
          opacityTo: 1,
        },
      },
      tooltip: {
        theme: "dark",
        x: {
          format: "MMM",
        },
      },
      xaxis: {
        type: "datetime",
        tickAmount: 10,
      },
      yaxis: {
        min: 0,

        // tickAmount: 4,
      },
    },
  };

  const yealyoptions = {
    series: [
      {
        name: "Earning $",
        data: chartDataforYearly,
      },
    ],
    option: {
      chart: {
        id: "chart2",
        height: 455,
        type: "line",
        foreColor: "#ccc",
        toolbar: {
          autoSelected: "pan",
          show: true,
        },
        zoom: {
          enabled: true,
        },
        animations: {
          enabled: true,
        },
      },
      colors: ["var(--base-graph-line-color)"],
      stroke: {
        width: 3,
        curve: "straight",
      },
      grid: {
        borderColor: "#555",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: true,
          },
        },
        xaxis: {
          lines: {
            show: true,
          },
          type: "datetime",
          tickAmount: 3,
          tickPlacement: "on",
          labels: {
            formatter: function (value, timestamp, index) {
              const date = new Date(value);
              return date.getFullYear().toString(); // Display only the year
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          gradientToColors: ["var(--base-graph-line-color)"],
          type: "horizontal",
          opacityFrom: 1,
          opacityTo: 1,
        },
      },
      tooltip: {
        theme: "dark",
        x: {
          format: "yyyy",
        },
      },
      // xaxis: {
      //   type: "datetime",
      //   min: new Date("01 Jan 2020").getTime(),
      //   max: new Date("01 Jan 2023").getTime(),
      //   // tickAmount: 3,
      // },
      yaxis: {
        // min: 0,
        tickAmount: yAxisMaximum,
      },
    },
  };
  // console.log("yAxisMaximum", yAxisMaximum);
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <>
      <Box
        component="div"
        variant="div"
        className={classess.page__banner}
        mt={2}
        p={3}
      >
        <Box
          variant="div"
          component="div"
          className={classess.page__banner__conatiner}
          sx={{ alignItems: "flex-start" }}
        >
          <Box
            variant="div"
            component="div"
            pb={1}
            className={classess.page__banner__conatiner__button_wrapper}
          >
            {/* <Button
              onClick={handleMonth}
              className={
                classess.page__banner__conatiner__button_wrapper__button
              }
            >
              Monthly
            </Button>
            <Button
              onClick={handleYear}
              className={
                classess.page__banner__conatiner__button_wrapper__button
              }
            >
              Yearly
            </Button> */}
            <CustomTwoTabs
              text_0={"Monthly"}
              text_1={"Yearly"}
              btn_0={activeTab === 1}
              btn_1={activeTab === 2}
              click_0={() => handleTabClick(1)}
              click_1={() => handleTabClick(2)}
              padding={"8px 20px"}
            />
          </Box>

          {activeTab === 1 && (
            <Grid sx={{ width: "100%" }}>
              <ReactApexChart
                options={monthlyoptions.option}
                series={monthlyoptions.series}
                height={500}
                type={"line"}
              />
            </Grid>
          )}

          {activeTab === 2 && (
            <Grid sx={{ width: "100%" }}>
              <ReactApexChart
                options={yealyoptions.option}
                series={yealyoptions.series}
                height={500}
                type={"line"}
              />
              {/* <ResponsiveContainer width="100%" height={300}>
                <AreaChart
                  width={500}
                  height={300}
                  data={yearlyEstimate}
                  syncId="Yearly Earnings"
                  margin={{
                    top: 10,
                    right: 5,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <defs>
                    <linearGradient id="Yearly" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#4FFCB7" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#4FFCB7" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <CartesianGrid strokeDasharray="0 1" />
                  <XAxis
                    dataKey="name"
                    tick={{ fill: "white", fontSize: 14 }}
                  />
                  <YAxis
                    tick={{ fill: "white", fontSize: 14 }}
                    label={{
                      fill: "white",
                      fontSize: 14,
                      value: "USD ($)",
                      angle: -90,
                      position: "insideLeft",
                    }}
                  />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="Yearly"
                    stroke="#82ca9d"
                    fillOpacity={1}
                    fill="url(#Yearly)"
                  />
                </AreaChart>
              </ResponsiveContainer> */}
            </Grid>
          )}
        </Box>
      </Box>
    </>
  );
};

export default TotalEarnings;
