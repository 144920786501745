import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

// MUI Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Custom Hooks and Utility Functions
import useGetSimilarArtist from "../../../hooks/useGetSimilarArtist";
import { config as URLconfig } from "../../../enviorment/enviorment";

// Redux Actions
import {
  setTotalTracks,
  getArtistById,
  getReports,
  setNewMusicTracks,
  setSelectedTrackCount,
  setSelectedTracks,
  setTracks,
} from "../../../redux/slice/artist";

// Custom MUI Styles
import { viewArtistUseStyles } from "../../../custom-mui-style/custom-mui-styles";

// Components
import ArtistStatsComponent from "../../../components/artist-stats-component/artist-stats-component";
import ArtistTopTracks from "../../../components/artist-top-tracks/artist-top-tracks";
import SimilarArtist from "../../../components/similar-artist/similar-artist";
import RecommendCollaborations from "../../../components/recommend-collaborations/recommend-collaborations";
import RevenueGraph from "../../graph/revenuegraph/RevenueGraph";
import SocialMediaGraph from "../../graph/socialMediaGraph/SocialMediaGraph";
import TourRecGrapth from "../../graph/tourRecommendations/TourRecGrapth";
import PlatformComparison from "../../graph/PlatformComparison/PlatformComparison";

const ViewArtist = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const dispatchRef = useRef(dispatch);
  const artist = useSelector((state) => state.artist.artist);
  const similarArtist = useSelector(
    (state) => state.similar_artist.similarArtist
  );

  // State Hooks
  const [tracks, settracks] = useState([]);
  const [toptrackfunding, settoptrackfunding] = useState([]);
  const [loader, setLoader] = useState(true);
  const [sortedTopTract, setSortedTopTract] = useState([]);

  // Refs and Hooks
  const similarArtistHook = useGetSimilarArtist();
  const internationalNumberFormat = new Intl.NumberFormat("en-US");
  const styles = viewArtistUseStyles();

  useEffect(() => {
    if (id) {
      dispatch(getArtistById({ id }));
    }
  }, [id, dispatch]);

  const mapTracks = (artistTrack) => ({
    id: artistTrack._id,
    name: artistTrack.title,
    image: artistTrack.track_img,
    stream_income_share: artistTrack.stream_income_share,
    spotify_streams_total: artistTrack.spotify_streams_total,
    youtube_video_views_total: artistTrack.youtube_video_views_total,
    tiktok_views_total: artistTrack.tiktok_views_total,
    deezer_reach_total: artistTrack.deezer_reach_total,
    isrc: artistTrack.isrc,
    release_date: artistTrack.release_date,
    track_type: artistTrack.track_type,
    last_streams_growth: artistTrack.last_streams_growth,
    historic: artistTrack.historic,
  });

  async function calcalute_tracks_estimate(tracks) {
    axios
      .post(
        `${URLconfig.BASE_URL}/artist-funding/${artist?.spotify_id}/track`,
        tracks
      )
      .then((res) => {
        settoptrackfunding(res.data.data.funding);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getSimilarArtistForCurrentArtist = (id) => {
    similarArtistHook.similarArtists(id);
  };

  useEffect(() => {
    if (artist?.spotify_id) {
      let isApiSubscribed = true;
      const fetchTracks = async (spotify_id) => {
        try {
          const res = await axios.get(
            `${URLconfig.BASE_URL}/artist-tracks/${spotify_id}`
          );
          const artistTracks = res.data;

          let selected_tracks = artistTracks?.data
            .filter((track) => track.is_selected === 1)
            .map((el) => el._id);

          let new_music_selected_tracks = artistTracks?.data
            .filter((track) => track.is_selected === 2)
            .map((el) => el._id);

          dispatchRef.current(setSelectedTrackCount(selected_tracks.length));
          dispatchRef.current(setSelectedTracks(selected_tracks));
          dispatchRef.current(setNewMusicTracks(new_music_selected_tracks));
          dispatchRef.current(setTracks([...artistTracks.data.map(mapTracks)]));
          dispatchRef.current(setTotalTracks(artistTracks.data.length));
        } catch (error) {
          console.log("Error Of GetTracks " + error);
        }
      };

      const fetchTopTracks = async () => {
        try {
          const res = await axios(
            `${URLconfig.BASE_URL}/artist-tracks/top/tracks/${artist.spotify_id}`
          );
          if (isApiSubscribed) {
            const topTracks = res.data.data.top10tracks;
            settracks([...topTracks.map(mapTracks)]);
            calcalute_tracks_estimate(topTracks.map(mapTracks));
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoader(false);
        }
      };

      dispatchRef.current(getReports(artist.spotify_id));
      fetchTracks(artist.spotify_id);
      fetchTopTracks();
      getSimilarArtistForCurrentArtist(artist.spotify_id);

      return () => {
        isApiSubscribed = false;
      };
    }
  }, [artist, dispatchRef]);

  useEffect(() => {
    const filteredData = tracks.map((item) => {
      const fundingItem = toptrackfunding.find(
        (funding) => funding.TrackID === item.id
      );
      if (fundingItem) {
        return {
          ...item,
          funding: fundingItem.funding,
        };
      } else {
        return item;
      }
    });
    setSortedTopTract(filteredData.sort((a, b) => b.funding - a.funding));
  }, [tracks, toptrackfunding]);

  return (
    <Container maxWidth="xxl" className={styles.root}>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}
        sm={12}
        lg={12}
        xl={12}
        md={12}
      >
        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
          <ArtistStatsComponent />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <ArtistTopTracks
            loader={loader}
            tracks={tracks}
            artist={artist}
            toptrackfunding={toptrackfunding}
            sortedTopTract={sortedTopTract}
            internationalNumberFormat={internationalNumberFormat}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
          <SimilarArtist similarArtist={similarArtist} loader={loader} />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={6.5}>
          <PlatformComparison />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={5.5}>
          <TourRecGrapth />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {artist && (
            <RecommendCollaborations
              artist_collaborations={artist?.collaborations}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default ViewArtist;
