import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import PhoneInput from "react-phone-number-input";
import { scrollTopObserver } from "../../../utils/helper";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  getArtistById,
  updateArtist,
  updateArtistWithProfile,
} from "../../../redux/slice/artist";
import { getCountries } from "../../../redux/slice/country";
import { getItemToLocalStorage } from "../../../services/storage";
import { config as URLconfig } from "../../../enviorment/enviorment";
import {
  CustomSelect,
  CustomTextField,
  CustomMenuItem,
} from "../../../custom-mui-style/custom-mui-styles";
import classes from "./style.module.scss";
import editIcon from "../../../assets/icons/edit.svg";

const EditArtist = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const artist = useSelector((state) => state.artist.artist);
  const allCountries = useSelector((state) => state?.country?.countries);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [cityState, setCityState] = useState("");
  const [country, setCountry] = useState("");
  const [avatar, setAvatar] = useState(null);
  const [image, setImage] = useState(null);
  const [isProfileChanged, setIsProfileChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const storedToken = getItemToLocalStorage("accessToken");
  const [isFocused, setIsFocused] = useState(false);

  const handleOpenSelect = () => setIsOpen(true);
  const handleCloseSelect = () => setIsOpen(false);

  const handleProfileChange = (event) => {
    setAvatar(event.target.files[0]);
    setImage(URL.createObjectURL(event.target.files[0]));
    setIsProfileChanged(true);
    event.target.files = null;
  };

  const handleOnSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    const { city, state } = handleSeperateCityState(cityState);

    try {
      if (!isProfileChanged) {
        const payload = {
          name,
          telephone,
          email,
          city,
          state,
          country,
          spotify_id: artist?.spotify_id,
          avatar,
        };
        await updateSpotlightArtistInfo(payload, artist?._id);
        const response = dispatch(
          updateArtist({
            id: artist?._id,
            data: payload,
          })
        );

        response.then(() => {
          setIsLoading(false);
          // navigate("/blig/home");
          navigate(-1);
        });
      } else {
        let payload = new FormData();

        payload.append("file", avatar);
        payload.append("name", name);
        payload.append("email", email);
        payload.append("telephone", telephone);
        payload.append("city", city);
        payload.append("state", state);
        payload.append("country", country);
        payload.append("spotify_id", artist?.spotify_id);

        const respImage = dispatch(
          updateArtistWithProfile({
            id: artist?._id,
            data: payload,
          })
        );

        respImage.then(() => {
          setIsLoading(false);
          // navigate("/blig/home");
          navigate(-1);
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const initUIData = () => {
    dispatch(
      getArtistById({
        id,
      })
    );
  };

  const updateSpotlightArtistInfo = (artist, _id) => {
    const payload = {
      spotify_id: artist?.spotify_id,
      name: artist?.name,
      email: artist?.email,
      image: artist?.avatar,
      artist_id: _id,
      country: artist?.country,
    };
    axios
      .post(`${URLconfig.BASE_URL}/spotlight/update`, payload, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      })
      .then((res) => {
        // toast.success("Spotlight Added");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const handleSeperateCityState = () => {
    const splitted = cityState.split(",");
    let city, state;
    if (splitted[0]) {
      city = splitted[0].trim();
    }
    if (splitted[1]) {
      state = splitted[1].trim();
    }
    return { city, state };
  };

  const handleCombineCityState = (artistCity, artistState) => {
    if (artistCity && artistState) {
      return `${artistCity}, ${artistState}`;
    } else if (artistCity && !artistState) {
      return artistCity;
    } else if (!artistCity && artistState) {
      return artistState;
    } else {
      return "";
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  useEffect(() => {
    dispatch(getCountries());
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      initUIData();
    }
  }, [id]);

  useEffect(() => {
    if (artist) {
      setName(artist?.name);
      setEmail(
        artist?.email ||
          `${artist?.name.replace(/\s/g, "").toLowerCase()}@blacklionapp.xyz`
      );
      setTelephone(artist?.telephone);
      setCityState(handleCombineCityState(artist?.city, artist?.state));
      setCountry(artist?.country);
      setAvatar(artist?.avatar);
      setImage(artist?.avatar);
    }
  }, [artist]);

  return (
    <Container maxWidth="xxl">
      <Grid container spacing={2}>
        <Grid item sm={12} lg={9} xl={9}>
          <Box varient="div" component="div" className={classes.page}>
            {/* Page Title */}
            <Typography className={classes.page__pageTitle}>
              Edit Artist Details
            </Typography>

            <Box
              varient="div"
              component="div"
              sx={{ padding: "20px" }}
              className={classes.page__fieldsContainer}
            >
              <Box
                varient="div"
                component="div"
                className={classes.page__fieldsContainer__image_container}
                sx={{ flexDirection: { xs: "column", sm: "row" } }}
              >
                <Box
                  varient="div"
                  component="div"
                  className={
                    classes.page__fieldsContainer__image_container__avatar_container
                  }
                >
                  <Avatar
                    src={image}
                    alt={name}
                    className={
                      classes.page__fieldsContainer__image_container__avatar_container__avatar
                    }
                  />
                  <div
                    className={
                      classes.page__fieldsContainer__image_container__avatar_container__icon
                    }
                  >
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                    >
                      <input
                        hidden
                        accept="image/*"
                        type="file"
                        onChange={handleProfileChange}
                      />
                      <img src={editIcon} alt="edit button" />
                    </IconButton>
                  </div>
                </Box>
                <span className={classes.page__fieldsContainer__title}>
                  {artist?.name}
                </span>
              </Box>

              <form
                action=""
                className={classes.page__fieldsContainer__form}
                autoComplete="off"
                onSubmit={handleOnSubmit}
              >
                <Stack
                  direction="row"
                  width={"100%"}
                  sx={{
                    gap: { xs: 3, sm: 8, lg: 3 },
                    flexWrap: { xs: "wrap", sm: "nowrap" },
                  }}
                >
                  <Box
                    varient="div"
                    component="div"
                    className={classes.page__fieldsContainer__form__formfield}
                    sx={{ width: "100%" }}
                  >
                    <label
                      className={
                        classes.page__fieldsContainer__form__formfield__label
                      }
                    >
                      Artist Name *
                    </label>
                    <CustomTextField
                      value={name || ""}
                      name="name"
                      placeholder="Enter Artist Name"
                      onInput={(event) => setName(event.target.value)}
                      type="text"
                      minLength={3}
                      maxLength={25}
                      required
                    />
                  </Box>
                  <Box
                    varient="div"
                    component="div"
                    className={classes.page__fieldsContainer__form__formfield}
                    sx={{ width: "100%" }}
                  >
                    <label
                      className={
                        classes.page__fieldsContainer__form__formfield__label
                      }
                    >
                      Phone
                    </label>

                    <PhoneInput
                      international
                      className={
                        classes.page__fieldsContainer__form__formfield__phoneinput
                      }
                      defaultCountry={country}
                      placeholder="Enter phone number"
                      value={telephone}
                      name="telephone"
                      onChange={setTelephone}
                      limitMaxLength={16}
                      autoComplete="off"
                      countryCallingCodeEditable={false}
                      style={{
                        border: isFocused
                          ? "1px solid var(--base-input-focus-color)"
                          : "1px solid #5A7380",
                        transition: "all 0.3s ease-in-out",
                      }}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                  </Box>
                </Stack>

                <Box
                  varient="div"
                  component="div"
                  className={classes.page__fieldsContainer__form__formfield}
                  sx={{ width: "100%" }}
                >
                  <label
                    className={
                      classes.page__fieldsContainer__form__formfield__label
                    }
                  >
                    E-mail Address
                  </label>
                  <CustomTextField
                    type="email"
                    value={email}
                    name="email"
                    maxLength={80}
                    placeholder="Enter Email Address"
                    onInput={(event) => setEmail(event.target.value)}
                    required
                  />
                </Box>

                <Stack
                  direction="row"
                  width={"100%"}
                  sx={{
                    gap: { xs: 3, sm: 8, lg: 3 },
                    flexWrap: { xs: "wrap", sm: "nowrap" },
                  }}
                >
                  <Box
                    varient="div"
                    component="div"
                    className={classes.page__fieldsContainer__form__formfield}
                    sx={{ width: "100%" }}
                  >
                    <label
                      className={
                        classes.page__fieldsContainer__form__formfield__label
                      }
                    >
                      Country
                    </label>
                    <CustomSelect
                      value={country}
                      onChange={(event) => setCountry(event.target.value)}
                      required
                      displayEmpty
                      onOpen={handleOpenSelect}
                      onClose={handleCloseSelect}
                      IconComponent={() =>
                        isOpen ? (
                          <IoIosArrowUp
                            className={
                              classes.page__fieldsContainer__form__formfield__select_icon
                            }
                          />
                        ) : (
                          <IoIosArrowDown
                            className={
                              classes.page__fieldsContainer__form__formfield__select_icon
                            }
                          />
                        )
                      }
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 300,
                          },
                        },
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <CustomMenuItem value="">Select Country</CustomMenuItem>
                      {allCountries && allCountries.length > 0
                        ? allCountries.map((item) => (
                            <CustomMenuItem key={item.value} value={item.value}>
                              {item.label}
                            </CustomMenuItem>
                          ))
                        : null}
                    </CustomSelect>
                  </Box>

                  <Box
                    varient="div"
                    component="div"
                    className={classes.page__fieldsContainer__form__formfield}
                    sx={{ width: "100%" }}
                  >
                    <label
                      className={
                        classes.page__fieldsContainer__form__formfield__label
                      }
                    >
                      <span ml={1}>Spotify ID *</span>
                    </label>
                    <CustomTextField
                      value={artist?.spotify_id || "N/A"}
                      name="spotify_id"
                      placeholder="Enter Spotify Id"
                      readOnly
                    />
                  </Box>
                </Stack>

                <Box
                  varient="div"
                  component="div"
                  className={classes.page__fieldsContainer__form__action}
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "100%",
                      lg: "50%",
                    },
                    marginLeft: "auto",
                  }}
                >
                  <Button
                    variant="contained"
                    className={
                      classes.page__fieldsContainer__form__action__cancel_btn
                    }
                    // sx={{
                    //   width: {
                    //     xs: "100%",
                    //     sm: "100%",
                    //     lg: "20%",
                    //   },
                    // }}
                    onClick={() => {
                      // navigate(`/blig/view-artist/${id}`);
                      navigate(-1);
                      scrollTopObserver();
                    }}
                    disabled={isLoading}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    type="submit"
                    className={
                      classes.page__fieldsContainer__form__action__submit_btn
                    }
                    loadingPosition="start"
                    variant="contained"
                    loading={isLoading}
                    // sx={{ width: { xs: "100%", sm: "100%", lg: "20%" } }}
                  >
                    Update Artist
                  </LoadingButton>
                </Box>
              </form>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default EditArtist;
