// React and Redux Imports
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// MUI Imports
import {
  Box,
  Grid,
  Avatar,
  IconButton,
  Typography,
  Stack,
  Button,
} from "@mui/material";

// Custom Components and Styles Imports
import {
  CustomTable,
  CustomTableContainer,
  CustomTableBody,
  CustomTableCell,
  CustomTableHead,
  CustomTableRow,
  CustomTableCellHeader,
} from "../../custom-mui-style/custom-mui-styles";
import { CustomAuthTextField } from "../../custom-mui-style/custom-mui-styles";

import { FormControl } from "@mui/material";

import EyeIcon from "../../assets/eye.svg";
import MessageIcon from "../../assets/message.svg";

import classess from "./style.module.scss";

// Assets and Utilities Imports
import { getItemToLocalStorage } from "../../services/storage";

// External Libraries and Icons Imports
import Lottie from "lottie-react";
import { ReactSVG } from "react-svg";
import NewLoader from "../lottie-loader/Loader.json";
import FilterIcon from "../../assets/filterIcon.svg";
import NoDataFound from "../../assets/NoTeamFound.svg";

const internationalNumberFormat = new Intl.NumberFormat("en-US");

const ArtistTeam = () => {
  const user = useSelector((state) => state.auth.user);
  const storedToken = getItemToLocalStorage("accessToken");
  const [isLoading, setIsLoading] = useState(false);

  const generateRandomData = () => {
    const data = [];
    for (let i = 0; i < 25; i++) {
      data.push({
        id: i,
        name: `Member ${i}`,
        role: `Role ${i}`,
        email: `member${i}@example.com`,
      });
    }
    return data;
  };
  const [team, setTeam] = useState(generateRandomData());

  const [teamInput, setTeamInput] = useState("");
  const [inviteTeam, setInviteTeam] = useState(false);

  const openInviteTeam = () => {
    setInviteTeam(true);
  };
  const closeInviteTeam = () => {
    setInviteTeam(false);
  };
  const isAmountValid = teamInput.trim() !== "";

  return (
    <React.Fragment>
      <Grid item xs={12} sm={12} lg={12} xl={8}>
        <Box className={classess.page}>
          <Box className={classess.page__topSec}>
            <Typography className={classess.page__topSec__heading}>
              My Team
            </Typography>
            <Button
              className={classess.page__topSec__button}
              startIcon={<ReactSVG src={FilterIcon} />}
              onClick={openInviteTeam}
            >
              Invite team member
            </Button>
          </Box>
          {isLoading ? (
            <LoadingComponent />
          ) : team.length === 0 ? (
            <NoTeamMemberCompopnent />
          ) : (
            <MyTeam teamMember={team} />
          )}
        </Box>
      </Grid>

      {inviteTeam && (
        <Grid item xs={12} sm={12} lg={12} xl={4}>
          <Box className={classess.invite}>
            <Box className={classess.invite__topSec}>
              <Typography className={classess.invite__topSec__heading}>
                Invite team members
              </Typography>
              <Button
                className={classess.invite__topSec__req_button}
                onClick={closeInviteTeam}
              >
                close
              </Button>
            </Box>

            <Box className={classess.invite__reqPaymentContainer}>
              <Box
                className={
                  classess.invite__reqPaymentContainer__fieldsContainer
                }
              >
                <FormControl fullWidth sx={{ mb: "20px" }}>
                  <label
                    htmlFor=""
                    className={
                      classess.invite__reqPaymentContainer__fieldsContainer__topHeading
                    }
                  >
                    select contract
                  </label>
                  <CustomAuthTextField
                    value={teamInput}
                    name="name"
                    type="text"
                    placeholder="samantha.hayes@gmail.com,"
                    onChange={(e) => setTeamInput(e.target.value)}
                  />
                  <label
                    htmlFor=""
                    className={
                      classess.invite__reqPaymentContainer__fieldsContainer__buttonMsg
                    }
                  >
                    Multiple addresses can be added by separating them with a
                    comma.
                  </label>
                </FormControl>
              </Box>

              <Box
                className={classess.invite__reqPaymentContainer__BtnContainer}
              >
                <FormControl fullWidth>
                  <Button
                    className={
                      classess.invite__reqPaymentContainer__BtnContainer__ReqPayBtn
                    }
                    disabled={isAmountValid ? false : true}
                    sx={{
                      backgroundColor: isAmountValid
                        ? ""
                        : "var(--base-deactive-button-background-color) !important",
                    }}
                  >
                    Send invite(s)
                  </Button>
                </FormControl>
              </Box>
            </Box>
          </Box>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default ArtistTeam;

const MyTeam = ({ teamMember }) => {
  return (
    <CustomTableContainer className={classess.page__table}>
      <CustomTable stickyHeader aria-label="sticky table">
        <CustomTableHead>
          <CustomTableCellHeader
            sx={{ border: "none !important" }}
          ></CustomTableCellHeader>

          <CustomTableCellHeader sx={{ border: "none !important" }}>
            name
          </CustomTableCellHeader>

          <CustomTableCellHeader sx={{ border: "none !important" }}>
            ROLE
          </CustomTableCellHeader>

          <CustomTableCellHeader sx={{ border: "none !important" }}>
            email
          </CustomTableCellHeader>

          <CustomTableCellHeader sx={{ border: "none !important" }}>
            ACTIONS
          </CustomTableCellHeader>
        </CustomTableHead>

        <CustomTableBody>
          {teamMember.map((member) => (
            <React.Fragment>
              <Box sx={{ marginBottom: "1rem" }} />
              <CustomTableRow>
                <CustomTableCell
                  sx={{
                    // width: "50px !important",
                    borderBottomLeftRadius: "12px",
                    borderTopLeftRadius: "12px",
                  }}
                >
                  <Avatar sx={{ height: "50px", width: "50px" }}></Avatar>
                </CustomTableCell>

                <CustomTableCell>
                  <Typography className={classess.page__heading1}>
                    Samantha Hayes
                  </Typography>
                </CustomTableCell>

                <CustomTableCell>
                  <Typography className={classess.page__heading2}>
                    Manager
                  </Typography>
                </CustomTableCell>

                <CustomTableCell>
                  <Typography className={classess.page__heading2}>
                    samantha.hayes@gmail.com
                  </Typography>
                </CustomTableCell>

                <CustomTableCell
                  sx={{
                    borderBottomRightRadius: "12px",
                    borderTopRightRadius: "12px",
                  }}
                >
                  <Stack direction="row" spacing={1}>
                    <IconButton className={classess.page__actionIcon}>
                      <ReactSVG src={EyeIcon} />
                    </IconButton>
                    <IconButton className={classess.page__actionIcon}>
                      <ReactSVG src={MessageIcon} />
                    </IconButton>
                  </Stack>
                </CustomTableCell>
              </CustomTableRow>
            </React.Fragment>
          ))}
        </CustomTableBody>
      </CustomTable>
    </CustomTableContainer>
  );
};

const LoadingComponent = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "50px",
          height: "50px",
        }}
      >
        <Lottie animationData={NewLoader} className={classess.page__loader} />
      </Box>
    </Box>
  );
};

const NoTeamMemberCompopnent = () => {
  return (
    <Box className={classess.page__noDataContainer}>
      <ReactSVG src={NoDataFound} />
      <Box>
        <Typography className={classess.page__noDataContainer__heading1}>
          No team members added.
        </Typography>
        <Typography className={classess.page__noDataContainer__heading2}>
          Teams help you manage and maintain your profile.
        </Typography>
      </Box>
    </Box>
  );
};
